<template>
  <v-container fluid>
    <v-row justify="center">
      <v-toolbar elevation="1">
        <v-toolbar-title>
          Адміністрування сайтом
        </v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <div>
              <div class="mb-2">Основні налаштування</div>
              <v-divider class="mb-2"></v-divider>
              <v-row class="">
                <v-col cols="12" sm="12" md="6">
                  <v-checkbox hide-details label="Включити сайт" :color="current_color"
                              v-model="settings.site_enable"
                              @change="changeSiteEnable"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field hide-details label="Ідентифікатор сайту" color="grey" filled
                                v-model="settings.site_slug" :disabled="!settings.site_enable"
                                :class="{'req-star': !settings.site_slug}"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" offset-md="6" md="6" class="d-flex">
                  <div style="height: 54px">
                    <span class="success--text">Адреса сайту</span>
                    <div style="font-size: 1.3rem">{{ `${getBaseUrl()}/site/${settings.site_slug}/home` }}</div>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" offset-md="6" md="6" class="d-flex">
                  <div style="width: 54px; height: 54px;" class="mr-2"
                       :class="settings.site_color || 'secondary'"></div>
                  <v-select label="Основний колір" hide-details filled
                            :disabled="!settings.site_enable"
                            :items="color_select"
                            v-model="settings.site_color"
                            @change="changeColor"
                            dense
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar :color="data.item.text">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-subtitle v-html="data.item.text"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </div>
            <div class="mt-6">
              <div class="mb-2">Відображення сторінок</div>
              <v-divider class="mb-3"></v-divider>
              <v-row class="">
                <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                  <v-checkbox hide-details label="Документи" :color="current_color" class="mt-0"
                              v-model="settings.show_documents"
                              :disabled="!settings.site_enable"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                  <v-checkbox hide-details label="Боржники" :color="current_color" class="mt-0"
                              v-model="settings.show_debt"
                              :disabled="!settings.site_enable"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                  <v-checkbox hide-details label="Управління" :color="current_color" class="mt-0"
                              v-model="settings.show_management"
                              :disabled="!settings.site_enable"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                  <v-checkbox hide-details label="Контакти" :color="current_color" class="mt-0"
                              v-model="settings.show_contacts"
                              :disabled="!settings.site_enable"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                  <v-checkbox hide-details label="Галерея" :color="current_color" class="mt-0"
                              v-model="settings.show_gallery"
                              :disabled="!settings.site_enable"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1 my-1">
                  <v-checkbox hide-details label="Особистий кабінет" :color="current_color" class="mt-0"
                              v-model="settings.show_personal_cabinet"
                              :disabled="!settings.site_enable"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
            <div class="mt-6">
              <v-card-text class="px-0">
                <v-tabs class="custom-tabs" color="success">
                  <v-tab class="text-left justify-start">
                    Головна сторінка
                  </v-tab>
                  <v-tab class="text-left justify-start" v-if="settings.show_documents">
                    Сторінка документи
                  </v-tab>
                  <v-tab class="text-left justify-start" v-if="settings.show_debt">
                    Сторінка боржники
                  </v-tab>
                  <v-tab class="text-left justify-start" v-if="settings.show_management">
                    Сторінка управління
                  </v-tab>
                  <v-tab class="text-left justify-start" v-if="settings.show_contacts">
                    Сторінка контакти
                  </v-tab>

                  <v-tab-item>
                    <v-dialog v-model="dialog" fullscreen v-if="currentComponent">
                      <v-card flat elevation="0" style="position: relative" class="grey">
                        <v-btn depressed small
                               icon @click="closeModal"
                               dark
                               large
                               style="position: absolute; top: 5px; right: 5px; z-index: 9999">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <component :is="currentComponent" :is_modal="true"></component>
                      </v-card>
                    </v-dialog>
                    <v-row class="mt-4">
                      <v-col cols="12" md="12">
                        <v-textarea label="Підзаголовок"
                                    hide-details filled
                                    v-model="settings.main_page.header_text"
                                    rows="2"
                                    color="grey"
                                    v-if="settings.main_page"
                                    :disabled="!settings.site_enable"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox hide-details label="Білий текст кнопок" :color="current_color" class="mt-0"
                                    v-model="settings.main_page.white_button_text"
                                    v-if="settings.main_page"
                                    :disabled="!settings.site_enable"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-btn depressed @click="changeComponent('NewsView')" block :disabled="!settings.site_enable">
                          Новини
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-btn depressed @click="changeComponent('NotificationView')" block
                               :disabled="!settings.site_enable">
                          Оголошення
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-btn depressed @click="changeComponent('GalleryView')" block
                               :disabled="!settings.site_enable">
                          Галерея
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item v-if="settings.show_documents">
                    <v-row class="mt-4">
                      <v-col cols="12" md="12">
                        <v-textarea label="Заголовок"
                                    hide-details filled
                                    v-model="settings.documents_page.title"
                                    rows="2"
                                    color="grey"
                                    v-if="settings.documents_page"
                                    :disabled="!settings.site_enable"
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-textarea label="Підзаголовок"
                                    hide-details filled
                                    v-model="settings.documents_page.text"
                                    rows="2"
                                    color="grey"
                                    v-if="settings.documents_page"
                                    :disabled="!settings.site_enable"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox hide-details label="Показувати кнопки" :color="current_color" class="mt-0"
                                    v-model="settings.documents_page.show_buttons"
                                    v-if="settings.documents_page"
                                    :disabled="!settings.site_enable"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox hide-details label="Білий текст кнопок" :color="current_color" class="mt-0"
                                    v-model="settings.documents_page.white_button_text"
                                    v-if="settings.documents_page"
                                    :disabled="!settings.site_enable"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item v-if="settings.show_debt">
                    <v-row class="mt-4">
                      <v-col cols="12" md="12">
                        <v-textarea label="Заголовок"
                                    hide-details filled
                                    v-model="settings.debt_page.title"
                                    rows="2"
                                    color="grey"
                                    v-if="settings.debt_page"
                                    :disabled="!settings.site_enable"
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-textarea label="Підзаголовок"
                                    hide-details filled
                                    v-model="settings.debt_page.text"
                                    rows="2"
                                    color="grey"
                                    v-if="settings.debt_page"
                                    :disabled="!settings.site_enable"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox hide-details label="Показувати кнопки" :color="current_color" class="mt-0"
                                    v-model="settings.debt_page.show_buttons"
                                    v-if="settings.debt_page"
                                    :disabled="!settings.site_enable"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox hide-details label="Білий текст кнопок" :color="current_color" class="mt-0"
                                    v-model="settings.debt_page.white_button_text"
                                    v-if="settings.debt_page"
                                    :disabled="!settings.site_enable"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item v-if="settings.show_management">
                    <v-row class="mt-4">
                      <v-col cols="12" md="12">
                        <v-textarea label="Заголовок"
                                    hide-details filled
                                    v-model="settings.management_page.title"
                                    rows="2"
                                    color="grey"
                                    v-if="settings.management_page"
                                    :disabled="!settings.site_enable"
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-textarea label="Підзаголовок"
                                    hide-details filled
                                    v-model="settings.management_page.text"
                                    rows="2"
                                    color="grey"
                                    v-if="settings.management_page"
                                    :disabled="!settings.site_enable"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox hide-details label="Показувати кнопки" :color="current_color" class="mt-0"
                                    v-model="settings.management_page.show_buttons"
                                    v-if="settings.management_page"
                                    :disabled="!settings.site_enable"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox hide-details label="Білий текст кнопок" :color="current_color" class="mt-0"
                                    v-model="settings.management_page.white_button_text"
                                    v-if="settings.management_page"
                                    :disabled="!settings.site_enable"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item v-if="settings.show_contacts">
                    <v-row class="mt-4">
                      <v-col cols="12" md="12">
                        <v-textarea label="Заголовок"
                                    hide-details filled
                                    v-model="settings.management_contacts.title"
                                    rows="2"
                                    color="grey"
                                    v-if="settings.management_contacts"
                                    :disabled="!settings.site_enable"
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-textarea label="Підзаголовок"
                                    hide-details filled
                                    v-model="settings.management_contacts.text"
                                    rows="2"
                                    color="grey"
                                    v-if="settings.management_contacts"
                                    :disabled="!settings.site_enable"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox hide-details label="Показувати кнопки" :color="current_color" class="mt-0"
                                    v-model="settings.management_contacts.show_buttons"
                                    v-if="settings.management_contacts"
                                    :disabled="!settings.site_enable"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox hide-details label="Білий текст кнопок" :color="current_color" class="mt-0"
                                    v-model="settings.management_contacts.white_button_text"
                                    v-if="settings.management_contacts"
                                    :disabled="!settings.site_enable"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </div>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn depressed
                   color="success"
                   class="mr-2"
                   @click.stop="saveSettings"
            >Зберегти налаштування</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import organizationAPI from "@/utils/axios/organization"
import colors from 'vuetify/lib/util/colors'
import {mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "SiteAdmin",
  components: {
    NewsView: () => import("@/components/NewsView"),
    NotificationView: () => import("@/components/NotificationView"),
    GalleryView: () => import("@/components/GalleryView"),
  },
  computed: {
    ...mapGetters({
      organization: 'getCurrentOrganization'
    }),
    current_color() {
      return this.settings.site_color || 'secondary'
    }
  },
  data() {
    return {
      color_select: [],
      settings: {},
      currentComponent: null,
      publicPath: process.env.BASE_URL,
    }
  },
  methods: {
    getBaseUrl() {
      return window.location.origin
    },
    changeColor(payload) {
      if (!payload) {
        this.settings.site_color_hash = null
      }

      this.settings.site_color_hash = (this.color_select.find(item => item.value === payload) || {}).text || '#f18a0d'
    },
    changeSiteEnable() {
      if (this.settings.site_enable) {
        if (!this.organization.slug) {
          organizationAPI.create_first_site_id()
              .then(response => response.data)
              .then(data => {
                this.settings.site_slug = data
              })
              .catch(err => {
                const error = err.response.data.detail;
                this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
              })
        }
      }
    },
    saveSettings() {
      if (this.settings.site_enable && !this.settings.site_slug) {
        this.$store.commit(ALERT_SHOW, {text: 'Ідентифікатор сайту має бути заповнений', color: 'error'})
        return
      }

      const payload = Object.assign(
          {},
          this.settings,
          {
            update_slug: this.organization.slug !== this.settings.site_slug,
            report_name: 'site_admin'
          }
      )
      organizationAPI.save_site_admin_settings(JSON.stringify(payload))
        .then(response => response.data)
        .then(() => {
          this.$store.commit(ALERT_SHOW, {text: 'Налаштування успішно збережені', color: 'success'})
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
        })
    },
    closeModal() {
      this.dialog = false
      this.currentComponent = null
    },
    changeComponent(payload) {
      this.currentComponent = payload
      this.dialog = true
    },
    camelCaseToSnakeCase(str) {
      if (!str) return str
      return str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
    },
    getColors() {
      const colors_select = []

      Object.keys(colors).forEach(color => {
        let color_name = this.camelCaseToSnakeCase(color)
        const value = colors[color]
        colors_select.push(
            {
              text: value.base,
              value: color_name
            }
        )

        Object.keys(value).forEach(subcolor => {
          let subcolor_name = `${color} ${subcolor.substring(0, subcolor.length - 1)}-${subcolor.slice(-1)}`
          subcolor_name = this.camelCaseToSnakeCase(subcolor_name)
          const subcolor_value = value[subcolor]

          if (subcolor !== 'base') {
            colors_select.push(
                {
                  text: subcolor_value,
                  value: subcolor_name
                }
            )
          }
        })
      })

      return colors_select
    }
  },
  created() {
    this.color_select = this.getColors()
    organizationAPI.get_site_admin_settings()
        .then(response => response.data)
        .then(data => {
          this.settings = Object.assign({}, data)
          if (!this.settings.site_slug) {
            this.settings.site_slug = this.organization.slug || null
          }
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
        })
  }
}
</script>

<style scoped>

</style>