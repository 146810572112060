<template>
    <div class="pay-table"
         :class="{
        'sm-table': $vuetify.breakpoint.sm,
        'xs-table': $vuetify.breakpoint.xs,
    }"
    >
        <div class="form-table-custom with-padding mt-2">
            <table style="width: 100%" v-if="$vuetify.breakpoint.mdAndUp">
                <thead>
                <tr>
                    <th style="width: 50px">№</th>
                    <th style="width: 100px">Місяць</th>
                    <th style="width: 100px">Дата</th>
                    <th style="width: 110px">Вид</th>
                    <th style="width: 200px">Послуга</th>
                    <th style="width: 90px">Попередній</th>
                    <th style="width: 90px">Поточний</th>
                    <th style="width: 100px">Об'єм</th>
                    <th style="width: calc(100% - 820px);">Коментар</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(item, index) in items">
                    <tr :key="`item-${item.row_num}-${index}`">
                        <td style="width: 50px; text-align: center">
                            {{ item.row_num }}
                        </td>
                        <td style="width: 100px; text-align: left; padding-left: 6px !important;"
                            :class="{'font-weight-bold': item.month === current_month}"
                        >
                            {{ item.month | formatDate('MMMM. YY') }}
                        </td>
                        <td style="width: 100px;"
                            :class="{'font-weight-bold': item.month === current_month}"
                        >
                            {{ item.date | formatDate }}
                        </td>
                        <td style="width: 110px; text-align: left; padding-left: 10px !important;"
                            :class="{'font-weight-bold': item.month === current_month}"
                        >
                            {{ item.type }}
                        </td>
                        <td style="width: 200px;">
                            <div style="display: flex; align-items: center; justify-content: flex-start; text-align: left">
                                <v-icon style="width: 28px" :color="getAccountIcon(item.service_icon, 'color')"
                                        size="24" class="ml-2 mr-3">
                                    {{ getAccountIcon(item.service_icon, 'icon') }}
                                </v-icon>
                                <div>
                                    {{ item.service_name }}
                                </div>
                            </div>
                        </td>
                        <td style="width: 90px; font-weight: bold">
                            {{ item.prev }}
                        </td>
                        <td style="width: 90px; font-weight: bold">
                            {{ item.next }}
                        </td>
                        <td
                                style="width: 100px; font-weight: 500;"
                                :class="[
                                            {'error--text': item.result < 0},
                                            {'success--text': item.result > 0}
                                        ]"
                        >
                            {{ item.result }}
                        </td>
                        <td style="width: calc(100% - 840px); padding-left: 8px !important; text-align: left" v-html="item.comment">
                        </td>
                    </tr>
                </template>
                <tr v-if="!items.length">
                    <td :colspan="9"
                        class="success--text"
                        style="text-align: center; padding: 34px 4px !important; font-size: .94rem; font-weight: 500;"
                    >
                        Архів об'ємів порожній. Після розрахунку нарахувань - вони відображатимуться автоматично
                    </td>
                </tr>
                </tbody>
            </table>
            <template v-else>
                <div
                        :class="{
              'sm-table': $vuetify.breakpoint.sm,
              'xs-table': $vuetify.breakpoint.xs,
            }"
                >
                    <div class="table-archive-row"
                         v-for="(item, index) in items"
                         :key="`item-${item.row_num}-${index}`"
                    >
                        <div class="table-col">
                            <div class="col-header">№</div>
                            <div class="col-value">
                                {{ item.row_num }}
                            </div>
                        </div>
                        <div class="table-col">
                            <div class="col-header">Місяць</div>
                            <div class="col-value">
                                {{ item.month | formatDate('MMMM. YY') }}
                            </div>
                        </div>
                        <div class="table-col">
                            <div class="col-header">Дата</div>
                            <div class="col-value">
                                {{ item.date | formatDate }}
                            </div>
                        </div>
                        <div class="table-col">
                            <div class="col-header">Вид</div>
                            <div class="col-value">
                                {{ item.type }}
                            </div>
                        </div>
                        <div class="table-col">
                            <div class="col-header">Послуга</div>
                            <div class="col-value">
                                <div style="display: flex; align-items: center;">
                                    <v-icon style="width: 28px" :color="getAccountIcon(item.service_icon, 'color')"
                                            size="24" class="ml-0 mr-3">
                                        {{ getAccountIcon(item.service_icon, 'icon') }}
                                    </v-icon>
                                    <div>
                                        {{ item.service_name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-col">
                            <div class="col-header">Попередній</div>
                            <div class="col-value">
                                {{ item.prev }}
                            </div>
                        </div>
                        <div class="table-col">
                            <div class="col-header">Поточний</div>
                            <div class="col-value">
                                {{ item.prev }}
                            </div>
                        </div>
                        <div class="table-col">
                            <div class="col-header">Об'єм</div>
                            <div class="col-value"
                                 :class="[
                              {'error--text': item.result < 0},
                              {'success--text': item.result > 0}
                          ]"
                            >
                                {{ item.result | formatNumber }}
                            </div>
                        </div>
                        <div class="table-col">
                            <div class="col-header">Коментар</div>
                            <div class="col-value" v-html="item.comment">
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {getAccountIcon} from "@/utils/icons";
import {formatDate} from "@/filters";
import flatServiceAPI from "@/utils/axios/flat_service"
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_WITH_BALANCE, GET_ARCHIVE} from "@/store/actions/flat_service";

export default {
    name: "VolumeArchive",
    props: {
        modal: {
            type: Boolean,
            default: false
        },
        date_start: {
            type: String,
            default: '2020-01-01'
        },
        service_id: {
            type: Number,
            default: null
        },
        person_hash: {
            type: String,
            default: ''
        },
        updateVolumeArchive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            current_month: 'getCurrentMonth'
        }),
        currentSelect() {
            return this.selected_row
        }
    },
    data() {
        return {
            modal_watch: null,
            update_watch: null,
            items: [],
            modal_item: {}
        }
    },
    methods: {
        ...mapActions({
            fetchAccountArchive: GET_ARCHIVE,
            fetchFlatServices: GET_ALL_WITH_BALANCE,
        }),
        getAccountIcon,
        formatDate,
        fetchArchive() {
            const payload = {person_hash: this.person_hash, date_start: this.date_start, service_id: this.service_id}
            flatServiceAPI.get_volume_archive(payload)
                .then(response => response.data)
                .then(data => {
                    this.items = data.map((item, idx) => {
                        return Object.assign(item, {row_num: idx + 1, error: false})
                    })
                    if (this.updateOneTimeArchive) {
                        this.$emit('afterOneTimeArchiveUpdate')
                    }
                })
        },
        modal_watcher() {
            this.watcher = this.$watch(
                'modal',
                {
                    immediate: true,
                    handler(payload) {
                        if (payload) {
                            this.fetchArchive()
                        } else {
                            this.items = []
                        }
                    }
                }
            )
        },
        update_watcher() {
            this.watcher = this.$watch(
                'updateVolumeArchive',
                {
                    immediate: true,
                    handler(payload) {
                        if (payload) {
                            this.fetchArchive()
                            this.fetchAccountArchive(this.$route.params.person_hash)
                            this.fetchFlatServices(this.$route.params.person_hash)

                            this.$emit('afterVolumeArchiveUpdate')
                        }
                    }
                }
            )
        },
    },
    created() {
        this.modal_watcher()
        this.update_watcher()
    },
    beforeDestroy() {
        if (this.modal_watch) {
            this.modal_watch()
        }
        if (this.update_watch) {
            this.update_watch()
        }
    }
}
</script>

<style scoped lang="scss">
.form-table-custom th, .form-table-custom td {
  font-size: .75rem !important;
}
</style>