import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/recalculation';

export default {
    get_all_by_flat(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params:
                {person_hash: payload.person_hash, date_start: payload.date_start}
        })
    },
    get_document_by_id(id) {
        return axios.post(`${RESOURCE_NAME}/get-document-by-id`, {document_id: id})
    },
    fill_table(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-table`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "recalculation_id": payload.recalculation_id || payload })
    },
}
