<template>
  <div class="pay-table"
       :class="{
        'sm-table': $vuetify.breakpoint.sm,
        'xs-table': $vuetify.breakpoint.xs,
    }"
  >
    <div class="form-table-custom with-padding mt-2">
      <table style="width: 100%" v-if="$vuetify.breakpoint.mdAndUp">
        <thead>
        <tr>
          <th style="width: 60px;"></th>
          <th style="width: 50px">№</th>
          <th style="width: 100px">Місяць</th>
          <th style="width: 100px">Дата</th>
          <th style="width: 160px" v-if="legacy">Договір</th>
          <th style="width: 180px">Банк</th>
          <th style="width: 130px">Платник</th>
          <th style="width: 100px">Оплачено</th>
          <th style="width: calc(100% - 720px);">Послуга</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(item, index) in items">
          <template v-if="!item.services.length && !(item.service_id === all_services)">
            <tr :key="`item-${item.row_num}-${index}`">
              <td style="width: 60px;">
                <v-checkbox
                    class="mt-1 pt-0 ml-3"
                    color="success"
                    hide-details :ripple="false"
                    :value="selected_row === item.row_num"
                    :disabled="(item.month !== (legacy ? current_month_legacy : current_month)) || !isPaysUser"
                    :indeterminate="(item.month !== (legacy ? current_month_legacy : current_month)) || !isPaysUser"
                    :indeterminate-icon="'mdi-lock-outline'"
                    @change="changeSelect(item.row_num)"
                />
              </td>
              <td style="width: 50px;">
                {{ item.row_num }}
              </td>
              <td style="width: 100px;">
                {{ item.month | formatDate('MMMM. YY') }}
              </td>
              <td style="width: 160px;" v-if="legacy">
                {{ item.contract_name }}
              </td>
              <td style="width: 100px;">
                {{ item.pay_date | formatDate }}
              </td>
              <td style="width: 180px; text-align: left; padding-left: 10px !important;">
                {{ item.bank_name }}
              </td>
              <td style="width: 130px;">
                {{ item.payer }}
              </td>
              <td
                  style="width: 100px; font-weight: 500;"
                  :class="[
                              {'error--text': item.sum < 0},
                              {'success--text': item.sum > 0}
                          ]"
              >
                {{ item.sum | formatNumber }}
              </td>
              <td :style="legacy ? 'width: calc(100% - 880px);' : 'width: calc(100% - 720px);'" style="align-items: center">
                <div style="display: flex; align-items: center">
                  <v-icon style="width: 28px" :color="getAccountIcon(item.icon, 'color')" size="24" class="ml-2 mr-3">
                    {{ getAccountIcon(item.icon, 'icon') }}
                  </v-icon>
                  <div>
                    {{ item.service_name }}
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr :key="`item-${item.row_num}-${index}`">
              <td style="width: 60px;">
                <v-checkbox
                    class="mt-2 pt-0 ml-3"
                    color="success"
                    hide-details :ripple="false"
                    :disabled="(item.month !== (legacy ? current_month_legacy : current_month)) || !isPaysUser"
                    :value="selected_row === item.row_num"
                    :indeterminate="(item.month !== (legacy ? current_month_legacy : current_month)) || !isPaysUser"
                    :indeterminate-icon="'mdi-lock-outline'"
                    @change="changeSelect(item.row_num)"
                />
              </td>
              <td style="width: 50px;">
                {{ item.row_num }}
              </td>
              <td style="width: 100px;">
                {{ item.month | formatDate('MMMM. YY') }}
              </td>
              <td style="width: 160px;" v-if="legacy">
                {{ item.contract_name }}
              </td>
              <td style="width: 100px;">
                {{ item.pay_date | formatDate }}
              </td>
              <td style="width: 180px; text-align: left; padding-left: 10px !important;">
                {{ item.bank_name }}
              </td>
              <td style="width: 130px;">
                {{ item.payer }}
              </td>
              <td
                  style="width: 100px; font-weight: 500;"
                  :class="[
                              {'error--text': item.sum < 0},
                              {'success--text': item.sum > 0}
                          ]"
              >
                {{ item.sum | formatNumber }}
              </td>
              <td style="width: calc(100% - 720px); min-width: 350px;">
                <div style=" display: flex; align-items: center">
                  <v-btn icon small class="ml-2 mr-3"
                         @click="expandRow(item.row_num)">
                    <v-icon>
                      {{
                        item.row_num === expand_row_num ? 'mdi-arrow-up-bold-circle-outline' : 'mdi-arrow-down-bold-circle-outline'
                      }}
                    </v-icon>
                  </v-btn>
                  <div>
                    {{ item.service_name }}
                  </div>
                </div>
              </td>
            </tr>
            <tr v-for="(subitem, subindex) in item.services"
                :class="`subrow-${item.row_num} expandable`"
                :key="`subitem-${item.row_num}-${subindex}`"
                class="hide-row"
            >
              <td :colspan="legacy ? 7 : 6"></td>
              <td style="width: 100px;">
                {{ subitem.sum | formatNumber }}
              </td>
              <td style="width: calc(100% - 720px);">
                <div style="display: flex; align-items: center">
                  <v-icon style="width: 28px" :color="getAccountIcon(subitem.icon, 'color')" size="24" class="ml-2 mr-3">
                    {{ getAccountIcon(subitem.icon, 'icon') }}
                  </v-icon>
                  <div>
                    {{ subitem.service_name }}
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
        <tr v-if="!items.length">
          <td :colspan="legacy ? 9 : 8"
              class="success--text"
              style="text-align: center; padding: 34px 4px !important; font-size: .94rem; font-weight: 500;"
          >
            Архів оплат порожній. Додайте оплати по даному абоненту і вони відображатимуться автоматично
          </td>
        </tr>
        </tbody>
      </table>
      <template v-else>
        <div
            :class="{
              'sm-table': $vuetify.breakpoint.sm,
              'xs-table': $vuetify.breakpoint.xs,
            }"
        >
          <div class="table-archive-row"
               v-for="(item, index) in items"
               :key="`item-${item.row_num}-${index}`"
          >
            <div class="table-col">
              <div class="col-header"></div>
              <div class="col-value">
                <v-checkbox
                    class="mt-0 pt-0 ml-0 mb-0"
                    color="success"
                    hide-details :ripple="false"
                    :value="selected_row === item.row_num"
                    :disabled="(item.month !== (legacy ? current_month_legacy : current_month)) || !isPaysUser"
                    :indeterminate="(item.month !== (legacy ? current_month_legacy : current_month)) || !isPaysUser"
                    :indeterminate-icon="'mdi-lock-outline'"
                    @change="changeSelect(item.row_num)"
                    style="height: 26px !important;"
                />
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">№</div>
              <div class="col-value">
                {{ item.row_num }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Місяць</div>
              <div class="col-value">
                {{ item.month | formatDate('MMMM. YY') }}
              </div>
            </div>
            <div class="table-col" v-if="legacy">
              <div class="col-header">Договір</div>
              <div class="col-value">
                {{ item.contract_name }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Дата</div>
              <div class="col-value">
                {{ item.pay_date | formatDate }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Банк</div>
              <div class="col-value">
                {{ item.bank_name }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Платник</div>
              <div class="col-value">
                {{ item.payer }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Сума</div>
              <div class="col-value"
                   :class="[
                              {'error--text': item.sum < 0},
                              {'success--text': item.sum > 0}
                          ]"
              >
                {{ item.sum | formatNumber }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Послуга</div>
              <div class="col-value">
                <div style="display: flex; align-items: center">
                  <v-icon style="width: 28px" :color="getAccountIcon(item.icon, 'color')" size="24" class="ml-0 mr-3">
                    {{ getAccountIcon(item.icon, 'icon') }}
                  </v-icon>
                  <div>
                    {{ item.service_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {getAccountIcon} from "@/utils/icons";
import {formatDate} from "@/filters";
import paymentAPI from "@/utils/axios/payments"
import {mapActions, mapGetters} from "vuex";
import {
  GET_ALL_WITH_BALANCE,
  GET_ALL_WITH_BALANCE_LEGACY,
  GET_ARCHIVE,
  GET_ARCHIVE_LEGACY
} from "@/store/actions/flat_service";

export default {
  name: "PayArchive",
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    date_start: {
      type: String,
      default: '2020-01-01'
    },
    person_hash: {
      type: String,
      default: ''
    },
    updatePayArchive: {
      type: Boolean,
      default: false
    },
    legacy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      current_month: 'getCurrentMonth',
      current_month_legacy: 'getCurrentMonthLegacy',
      settings: 'getGeneralSettings',
      isPaysUser: 'isPaysUser'
    }),
    all_services() {
      return (this.settings.all_services || {}).value || 0
    },
    currentSelect() {
      return this.selected_row
    }
  },
  data() {
    return {
      selected_row: 0,
      expand_row_num: 0,
      modal_watch: null,
      update_watch: null,
      items: [],
      modal_item: {}
    }
  },
  methods: {
    ...mapActions({
      fetchAccountArchive: GET_ARCHIVE,
      fetchAccountArchiveLegacy: GET_ARCHIVE_LEGACY,
      fetchFlatServices: GET_ALL_WITH_BALANCE,
      fetchFlatServicesLegacy: GET_ALL_WITH_BALANCE_LEGACY
    }),
    getAccountIcon,
    formatDate,
    expandRow(row_num) {
      this.expand_row_num = this.expand_row_num === row_num ? 0 : row_num
      const rows = document.getElementsByClassName('expandable')
      Array.from(rows).forEach(item => {
        if (!item.classList.contains(`subrow-${row_num}`)) {
          item.classList.remove('hide-row')
          item.classList.add('hide-row')
        } else {
         if (this.expand_row_num === 0) {
           item.classList.add('hide-row')
         } else {
           item.classList.remove('hide-row')
         }
        }
      })
    },
    fetchArchive() {
      const payload = {person_hash: this.person_hash, date_start: this.date_start}
      paymentAPI.get_by_flat_archive(payload)
          .then(response => response.data)
          .then(data => {
            const rows = data.rows
            this.items = rows.map((item, idx) => {
              return Object.assign(item, {row_num: idx + 1, error: false})
            })
            if (this.updatePayArchive) {
              this.$emit('afterPayArchiveUpdate')
            }
          })
    },
    fetchArchiveLegacy() {
      const payload = {person_hash: this.person_hash, date_start: this.date_start}
      paymentAPI.get_by_flat_archive_legacy(payload)
          .then(response => response.data)
          .then(data => {
            const rows = data.rows
            this.items = rows.map((item, idx) => {
              return Object.assign(item, {row_num: idx + 1, error: false})
            })
            if (this.updatePayArchive) {
              this.$emit('afterPayArchiveUpdate')
            }
          })
    },
    modal_watcher() {
      this.watcher = this.$watch(
          'modal',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                if (this.legacy) {
                  this.fetchArchiveLegacy()
                } else {
                  this.fetchArchive()
                }
              } else {
                this.items = []
                this.modal_item = {}
                this.selected_row = 0
              }
            }
          }
      )
    },
    update_watcher() {
      this.watcher = this.$watch(
          'updatePayArchive',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.selected_row = 0
                this.modal_item = {}
                if (this.legacy) {
                  this.fetchArchiveLegacy()
                  this.fetchAccountArchiveLegacy(this.$route.params.person_hash)
                  this.fetchFlatServicesLegacy(this.$route.params.person_hash)
                } else {
                  this.fetchArchive()
                  this.fetchAccountArchive(this.$route.params.person_hash)
                  this.fetchFlatServices(this.$route.params.person_hash)
                }
              }
            }
          }
      )
    },
    buildPayloadForModal() {
      const row = this.items.find(item => item.row_num === this.selected_row)
      if (row) {
        this.modal_item = {
          date: row.pay_date,
          sum: row.sum,
          bank: row.bank,
          comment: '',
          person_id: row.person_id,
          address: row.address,
          payer: row.payer,
          id: row.header_id,
          row_id: row.id,
          service_id: row.service_id,
          flat_id: row.flat_id,
          balance: row.balance,
          icon: row.icon,
          services: [...row.services],
        }
        if (this.legacy) {
          this.modal_item.contract_id = row.contract_id
          this.modal_item.contract_name = row.contract_name
        }
      } else {
        this.modal_item = {}
      }
    },
    changeSelect(row_num) {
      if (this.selected_row === row_num) {
        this.selected_row = 0
        this.modal_item = {}
      } else {
        this.selected_row = row_num
        this.buildPayloadForModal()
      }
      this.$emit('selectRow', this.modal_item)
    },
  },
  created() {
    this.modal_watcher()
    this.update_watcher()
  },
  beforeDestroy() {
    if (this.modal_watch) {
      this.modal_watch()
    }
    if (this.update_watch) {
      this.update_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
  .hide-row {
    display: none;
  }
</style>