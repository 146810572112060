<template>
  <div class="pay-table"
       :class="{
        'sm-table': $vuetify.breakpoint.sm,
        'xs-table': $vuetify.breakpoint.xs,
    }"
  >
    <div class="form-table-custom with-padding mt-2">
      <table style="width: 100%;">
        <thead>
          <tr>
            <th style="width: 40px; max-width: 40px"></th>
            <th style="width: 40px">№</th>
            <th style="width: 120px">Місяць</th>
            <th style="width: 120px">Дата</th>
            <th style="width: 200px" v-if="is_legacy">Договір</th>
            <th style="width: 120px">Сума</th>
            <th style="width: 120px" v-if="is_legacy">ПДВ</th>
            <th style="width: 200px">Послуга</th>
            <th style="width: 40px; max-width: 40px">Авто.</th>
            <th>Коментар</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="`item-${item.row_num}-${index}`">
            <td style="width: 50px;">
              <v-checkbox
                  class="mt-1 pt-0 ml-3"
                  color="success"
                  hide-details :ripple="false"
                  :value="selected_row === item.row_num"
                  :disabled="item.month !== (is_legacy ? current_month_legacy : current_month) || item.id === 0"
                  :indeterminate="item.month !== (is_legacy ? current_month_legacy : current_month) || item.id === 0 || item.r_type === 1"
                  :indeterminate-icon="'mdi-lock-outline'"
                  @change="changeSelect(item.row_num)"
              />
            </td>
            <td style="width: 40px; max-width: 40px">{{ item.row_num }}</td>
            <td style="width: 120px; font-weight: 500;">{{ item.month | formatDate('MMMM. YY') }}</td>
            <td style="width: 120px">{{ item.date | formatDate }}</td>
            <td style="width: 200px" v-if="is_legacy">{{ item.contract_name }}</td>
            <td style="width: 120px; font-weight: 500;">
              <div :class="[
                              {'error--text': item.sum < 0},
                              {'success--text': item.sum > 0}
                          ]">
                {{ item.r_type === 0 ? formatNumber(item.sum) :
                  item.r_type === 1 ? `${formatNumber(item.sum)} / ${item.tariff}` : `${formatNumber(item.sum)}` }}
              </div>
              <div v-if="item.r_type === 3" :class="[
                              {'error--text': item.sum_to_swap < 0},
                              {'success--text': item.sum_to_swap > 0}
                          ]">
                {{ formatNumber(item.sum_to_swap) }}
              </div>
            </td>
            <td style="width: 120px" v-if="is_legacy">{{ item.tax | formatNumber }}</td>
            <td style="width: 200px">
              <div style="display: flex; align-items: center;">
                <v-icon style="width: 28px" :color="getAccountIcon(item.icon, 'color')" size="24" class="mr-3 ml-1">
                  {{ getAccountIcon(item.icon, 'icon') }}
                </v-icon>
                <div style="line-height: 15px; text-align: left">
                  {{ item.service_name }}
                </div>
              </div>
              <div style="display: flex; align-items: center; margin-top: 2px; margin-bottom: 4px;" v-if="item.r_type === 3">
                <v-icon style="width: 28px" :color="getAccountIcon(item.service_to_swap_icon, 'color')" size="24" class="mr-3 ml-1">
                  {{ getAccountIcon(item.service_to_swap_icon, 'icon') }}
                </v-icon>
                <div style="line-height: 15px; text-align: left">
                  {{ item.service_to_swap_name }}
                </div>
              </div>
            </td>
            <td style="width: 40px; text-align: left; margin-left: 4px; max-width: 40px">
              <v-simple-checkbox :value="item.semi_auto" aria-readonly="true" color="success" :ripple="false" class="ml-2"/>
            </td>
            <td style="font-style: oblique; text-align: left; margin-left: 4px;">
              {{ item.comment }}
            </td>
          </tr>
          <tr v-if="!items.length">
            <td :colspan="is_legacy ? 10 : 8"
                class="success--text"
                style="text-align: center; padding: 34px 4px !important; font-size: .94rem; font-weight: 500;"
            >
              Архів перерахунків порожній. Після створення - дані відображатимуться автоматично
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {getAccountIcon} from "@/utils/icons";
import {formatDate, formatNumber} from "@/filters";
import recalculationAPI from "@/utils/axios/recalculation"
import {mapActions, mapGetters} from "vuex";
import {
  GET_ALL_WITH_BALANCE,
  GET_ALL_WITH_BALANCE_LEGACY,
  GET_ARCHIVE,
  GET_ARCHIVE_LEGACY
} from "@/store/actions/flat_service";

export default {
  name: "RecalculationArchive",
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    date_start: {
      type: String,
      default: '2020-01-01'
    },
    person_hash: {
      type: String,
      default: ''
    },
    updateRecalculationArchive: {
      type: Boolean,
      default: false
    },
    is_legacy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      current_month: 'getCurrentMonth',
      current_month_legacy: 'getCurrentMonthLegacy'
    }),
    currentSelect() {
      return this.selected_row
    }
  },
  data() {
    return {
      selected_row: 0,
      expand_row_num: 0,
      modal_watch: null,
      update_watch: null,
      items: [],
      modal_item: {}
    }
  },
  methods: {
    ...mapActions({
      fetchAccountArchive: GET_ARCHIVE,
      fetchAccountArchiveLegacy: GET_ARCHIVE_LEGACY,
      fetchFlatServices: GET_ALL_WITH_BALANCE,
      fetchFlatServicesLegacy: GET_ALL_WITH_BALANCE_LEGACY,
    }),
    getAccountIcon,
    formatDate,
    formatNumber,
    fetchArchive() {
      const payload = { person_hash: this.person_hash, date_start: this.date_start }
      recalculationAPI.get_all_by_flat(payload)
        .then(response => response.data)
        .then(data => {
          this.items = data.map((item, idx) => {
            return Object.assign(item, {row_num: idx + 1, error: false})
          })
          if (this.updateRecalculationArchive) {
            this.$emit('afterRecalculationArchiveUpdate')
          }
        })
    },
    modal_watcher() {
      this.watcher = this.$watch(
          'modal',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.fetchArchive()
              } else {
                this.items = []
                this.modal_item = {}
                this.selected_row = 0
              }
            }
          }
      )
    },
    update_watcher() {
      this.watcher = this.$watch(
          'updateRecalculationArchive',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.selected_row = 0
                this.modal_item = {}

                this.fetchArchive()
                if (this.is_legacy) {
                  this.fetchAccountArchiveLegacy(this.$route.params.person_hash)
                  this.fetchFlatServicesLegacy(this.$route.params.person_hash)
                } else {
                  this.fetchAccountArchive(this.$route.params.person_hash)
                  this.fetchFlatServices(this.$route.params.person_hash)
                }

                this.$emit('afterRecalculationArchiveUpdate')
              }
            }
          }
      )
    },
    buildPayloadForModal() {
      const row = this.items.find(item => item.row_num === this.selected_row)
      if (row) {
        this.modal_item = {
          flat_id: row.flat_id,
          month: row.month,
          date: row.date,
          sum: row.sum,
          sum_to_close: row.sum,
          sum_to_swap: row.sum_to_swap,
          service_id: row.service_id,
          service_id_to_close: row.service_id,
          service_id_to_swap: row.service_id_to_swap,
          comment: row.comment,
          id: row.id,
          r_type: row.r_type,
          close_id: row.id,
          swap_id: row.swap_id,
          contract_id: row.contract_id,
          tax_in_sum: row.tax_in_sum,
          tax_type: row.tax_type,
          tax_enable: row.tax_enable,
          is_legacy: row.is_legacy,
          tax: row.tax,
          semi_auto: row.semi_auto || false,
          end_all_by_period: row.end_all_by_period || false,
          month_start: row.month_start,
          month_end: row.month_end,
          person_hash: this.person_hash,
        }
      } else {
        this.modal_item = {}
      }
    },
    changeSelect(row_num) {
      if (this.selected_row === row_num) {
        this.selected_row = 0
        this.modal_item = {}
      } else {
        this.selected_row = row_num
        this.buildPayloadForModal()
      }
      this.$emit('selectRow', this.modal_item)
    },
  },
  created() {
    this.modal_watcher()
    this.update_watcher()
  },
  beforeDestroy() {
    if (this.modal_watch) {
      this.modal_watch()
    }
    if (this.update_watch) {
      this.update_watch()
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: rgba(0, 0, 0, .12);
$expand-border-color: rgba(0, 0, 0, .05);
$expand-background-col: #f0f0f0;

.expand-icon:focus:after {
  opacity: 0 !important;
}

.select-checkbox {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.sm-table {

}
.xs-table {

}

</style>