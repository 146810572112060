<template>
  <div class="pay-table"
       :class="{
        'sm-table': $vuetify.breakpoint.sm,
        'xs-table': $vuetify.breakpoint.xs,
    }"
  >
    <div class="form-table-custom with-padding mt-2">
      <table style="width: 100%;" v-if="$vuetify.breakpoint.mdAndUp">
        <thead>
          <tr>
            <th rowspan="2" style="width: 50px"></th>
            <th rowspan="2" style="width: 120px">Місяць</th>
            <th rowspan="2" style="width: 150px">Власник/Посвідчення</th>
            <th rowspan="2" style="width: 150px">Категорія</th>
            <th rowspan="1" colspan="2" style="width: 180px">Період</th>
            <th rowspan="2" style="width: 80px">Об’єм/<br>Площа</th>
            <th rowspan="2" style="width: 80px">Тариф</th>
            <th rowspan="2" style="width: 80px">П-ків</th>
            <th rowspan="2" style="width: 90px">Сума</th>
            <th rowspan="2" style="width: 50px">Вручну</th>
          </tr>
          <tr>
            <th>
              з
            </th>
            <th>
              по
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="`item-${item.row_num}-${index}`">
            <td style="width: 50px;">
              <v-checkbox
                  class="mt-1 pt-0 ml-3"
                  color="success"
                  hide-details :ripple="false"
                  :value="selected_row === item.row_num"
                  :disabled="item.month !== current_month || !item.manual"
                  :indeterminate="item.month !== current_month || !item.manual"
                  :indeterminate-icon="'mdi-lock-outline'"
                  @change="changeSelect(item.row_num)"
              />
            </td>
            <td style="width: 150px; font-weight: 500;">
              <div style="display: flex; align-items: center;">
                <v-icon style="width: 28px" :color="getAccountIcon(item.icon, 'color')" size="24" class="mr-3 ml-1">
                  {{ getAccountIcon(item.icon, 'icon') }}
                </v-icon>
                <div style="line-height: 15px; text-align: left">
                  {{ item.month | formatDate('MMMM. YY') }}
                </div>
              </div>
            </td>
            <td style="width: 200px">
              {{ item.privilege_owner_name }}
            </td>
            <td style="width: 150px">{{ item.privilege_category_name }}</td>
            <td style="width: 90px">{{ item.date_start | formatDate }}</td>
            <td style="width: 90px">{{ item.date_end | formatDate }}</td>
            <td style="width: 80px">{{ item.volume | formatNumber('0.0000') }}</td>
            <td style="width: 80px">{{ item.tariff | formatNumber('0.0000') }}</td>
            <td style="width: 80px">{{ item.persons }}</td>
            <td style="width: 90px; font-weight: 500"
                :class="[
                              {'error--text': item.sum < 0},
                              {'success--text': item.sum > 0}
                          ]"
            >
              {{ item.sum | formatNumber }}
            </td>
            <td style="width: 50px">
              <v-simple-checkbox disabled v-model="item.manual" :ripple="false" class="mt-1 ml-3 pt-0"/>
            </td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="11"
                class="success--text"
                style="text-align: center; padding: 34px 4px !important; font-size: .94rem; font-weight: 500;"
            >
              Архів пільг порожній. Після створення - дані відображатимуться автоматично
            </td>
          </tr>
        </tbody>
      </table>
      <template v-else>
        <div
            :class="{
              'sm-table': $vuetify.breakpoint.sm,
              'xs-table': $vuetify.breakpoint.xs,
            }"
        >
          <div class="table-archive-row"
               v-for="(item, index) in items"
               :key="`item-${item.row_num}-${index}`"
          >
            <div class="table-col">
              <div class="col-header"></div>
              <div class="col-value">
                <v-checkbox
                    class="mt-0 pt-0 ml-0 mb-0"
                    color="success"
                    hide-details :ripple="false"
                    :value="selected_row === item.row_num"
                    :disabled="item.month !== current_month || !item.manual"
                    :indeterminate="item.month !== current_month || !item.manual"
                    :indeterminate-icon="'mdi-lock-outline'"
                    @change="changeSelect(item.row_num)"
                    style="height: 26px !important;"
                />
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Місяць</div>
              <div class="col-value">
                <div style="display: flex; align-items: center;">
                  <v-icon style="width: 28px" :color="getAccountIcon(item.icon, 'color')" size="24" class="mr-3 ml-1">
                    {{ getAccountIcon(item.icon, 'icon') }}
                  </v-icon>
                  <div style="line-height: 15px; text-align: left">
                    {{ item.month | formatDate('MMMM. YY') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Власник</div>
              <div class="col-value">
                {{ item.privilege_owner_name }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Категорія</div>
              <div class="col-value">
                {{ item.privilege_category_name }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Період з</div>
              <div class="col-value">
                {{ item.date_start | formatDate }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Період по</div>
              <div class="col-value">
                {{ item.date_end | formatDate }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Об’єм</div>
              <div class="col-value">
                {{ item.volume | formatNumber('0.0000') }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Тариф</div>
              <div class="col-value">
                {{ item.tariff | formatNumber('0.0000') }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Пільговиків</div>
              <div class="col-value">
                {{ item.persons }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header"></div>
              <div class="col-value"
                   :class="[
                              {'error--text': item.sum < 0},
                              {'success--text': item.sum > 0}
                          ]"
              >
                {{ item.sum | formatNumber }}
              </div>
            </div>
            <div class="table-col">
              <div class="col-header">Вручну</div>
              <div class="col-value">
                <v-simple-checkbox disabled v-model="item.manual"
                                   style="height: 26px !important;"
                                   :ripple="false" class="mt-0 pt-0 ml-0 mb-0"/>
              </div>
            </div>
            <div class="table-col">
              <div class="col-header"></div>
              <div class="col-value">
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {getAccountIcon} from "@/utils/icons";
import {formatDate} from "@/filters";
import privilegeRecalculationAPI from "@/utils/axios/privilege_recalculation"
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_WITH_BALANCE, GET_ARCHIVE} from "@/store/actions/flat_service";

export default {
  name: "PrivilegeRecalculationArchive",
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    date_start: {
      type: String,
      default: '2020-01-01'
    },
    person_hash: {
      type: String,
      default: ''
    },
    updatePrivilegeRecalculationArchive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      current_month: 'getCurrentMonth'
    }),
    currentSelect() {
      return this.selected_row
    }
  },
  data() {
    return {
      selected_row: 0,
      expand_row_num: 0,
      modal_watch: null,
      update_watch: null,
      items: [],
      modal_item: {}
    }
  },
  methods: {
    ...mapActions({
      fetchAccountArchive: GET_ARCHIVE,
      fetchFlatServices: GET_ALL_WITH_BALANCE,
    }),
    getAccountIcon,
    formatDate,
    fetchArchive() {
      const payload = { person_hash: this.person_hash, date_start: this.date_start }
      privilegeRecalculationAPI.get_all_by_flat(payload)
        .then(response => response.data)
        .then(data => {
          this.items = data.map((item, idx) => {
            return Object.assign(item, {row_num: idx + 1, error: false})
          })
          if (this.updatePrivilegeRecalculationArchive) {
            this.$emit('afterPrivilegeRecalculationArchiveUpdate')
          }
        })
    },
    modal_watcher() {
      this.watcher = this.$watch(
          'modal',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.fetchArchive()
              } else {
                this.items = []
                this.modal_item = {}
                this.selected_row = 0
              }
            }
          }
      )
    },
    update_watcher() {
      this.watcher = this.$watch(
          'updatePrivilegeRecalculationArchive',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.selected_row = 0
                this.modal_item = {}

                this.fetchArchive()
                this.fetchAccountArchive(this.$route.params.person_hash)
                this.fetchFlatServices(this.$route.params.person_hash)

                this.$emit('afterPrivilegeRecalculationArchiveUpdate')
              }
            }
          }
      )
    },
    buildPayloadForModal() {
      const row = this.items.find(item => item.row_num === this.selected_row)
      if (row) {
        this.modal_item = {
          flat_id: row.flat_id,
          month: row.month,
          date_start: row.date_start,
          date_end: row.date_end,
          privilege_id: row.privilege_id,
          volume: row.volume,
          tariff: row.tariff,
          persons: row.persons,
          sum: row.sum,
          service_id: row.service_id,
          id: row.id
        }
      } else {
        this.modal_item = {}
      }
    },
    changeSelect(row_num) {
      if (this.selected_row === row_num) {
        this.selected_row = 0
        this.modal_item = {}
      } else {
        this.selected_row = row_num
        this.buildPayloadForModal()
      }
      this.$emit('selectRow', this.modal_item)
    },
  },
  created() {
    this.modal_watcher()
    this.update_watcher()
  },
  beforeDestroy() {
    if (this.modal_watch) {
      this.modal_watch()
    }
    if (this.update_watch) {
      this.update_watch()
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: rgba(0, 0, 0, .12);
$expand-border-color: rgba(0, 0, 0, .05);
$expand-background-col: #f0f0f0;

.expand-icon:focus:after {
  opacity: 0 !important;
}

.select-checkbox {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.form-table-custom th, .form-table-custom td {
  width: auto !important;
}

.sm-table {

}
.xs-table {

}

</style>