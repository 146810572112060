import { render, staticRenderFns } from "./PayArchive.vue?vue&type=template&id=147f7c26&scoped=true&"
import script from "./PayArchive.vue?vue&type=script&lang=js&"
export * from "./PayArchive.vue?vue&type=script&lang=js&"
import style0 from "./PayArchive.vue?vue&type=style&index=0&id=147f7c26&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147f7c26",
  null
  
)

export default component.exports