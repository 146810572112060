import { render, staticRenderFns } from "./RecalculationArchive.vue?vue&type=template&id=609fed97&scoped=true&"
import script from "./RecalculationArchive.vue?vue&type=script&lang=js&"
export * from "./RecalculationArchive.vue?vue&type=script&lang=js&"
import style0 from "./RecalculationArchive.vue?vue&type=style&index=0&id=609fed97&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "609fed97",
  null
  
)

export default component.exports