<template>
  <v-container fluid class="d-flex flex-column" style="height: 100%" v-resize="onResizeNew">
    <div id="receipt-for-print" class="personal-cabinet-receipt page page-a4 hide-before-print-cab" style="height: auto !important; min-height: 215mm; float: none !important;">
      <div style="font-weight: bold; text-align: center;" class="mt-2 mb-3">
        Рахунок за {{ defaultServiceName.toLowerCase() || 'надані послуги' }} по {{ organization.full_name }} за {{ current_month | formatDate('MMMM YYYY') }}
      </div>
      <div class="pcr-section my-2">
        Дані платника:
      </div>
      <div class="pcr-row">
        <div class="pcr-left-col">Адреса</div>
        <div class="pcr-right-col">
          {{ current_flat.full_address }}
        </div>
      </div>
      <div class="pcr-row">
        <div class="pcr-left-col">Особовий рахунок</div>
        <div class="pcr-right-col">
          {{ current_flat.person_id }}
        </div>
      </div>
      <div class="pcr-row">
        <div class="pcr-left-col border-bottom">Прізвище, ім’я та по батькові</div>
        <div class="pcr-right-col border-bottom">
          {{ current_flat.flat_owner ? current_flat.flat_owner.full_name : '' }}
        </div>
      </div>
      <div class="pcr-section mt-4 mb-2">
        Дані одержувача:
      </div>
      <div class="pcr-row">
        <div class="pcr-left-col">Реквізити</div>
        <div class="pcr-right-col">
          {{ this.print_data ? this.print_data.organization_details_without_account : '' }}
        </div>
      </div>
      <div class="pcr-row">
        <div class="pcr-left-col border-bottom">Рахунок</div>
        <div class="pcr-right-col border-bottom">
          {{ this.print_data ? this.print_data.bank_account_data : '' }}
        </div>
      </div>
      <div class="pcr-section mt-4 mb-2">
      </div>
      <div class="pcr-row">
        <div class="pcr-left-col border-bottom" style="font-size: 1.2rem;">Сума</div>
        <div class="pcr-right-col border-bottom" style="font-size: 1.2rem; font-weight: bold">
          {{ ((account_summary.end_balance || 0) > 0 ? (account_summary.end_balance || 0) : 0)  | formatToFixed | formatNumber }} грн.
        </div>
      </div>
    </div>
    <v-dialog persistent v-model="updateExists" max-width="480" class="non-printable">
      <v-card class="pa-3" rounded>
        <v-card-text
            class="align-center text-center pt-4 pb-6"
            style="font-size: 1.46rem;"
        >
          <v-icon size="110"
                  color="teal">
            mdi-rocket-launch-outline
          </v-icon>
        </v-card-text>
        <v-card-text
            class="py-4"
            style="font-size: 1.4rem; font-weight: bold; text-align: center; color: #2d2d2d">
          Доступне оновлення програми
        </v-card-text>
        <v-card-text style="text-align: center; font-size: .9rem">
          Щоб використовувати нові можливості та мати доступ до нових функції - натисніть на Оновити. <br>
          В наступних оновленнях буде реалізована технологія web-push, тому команда оновити може тимчасово не працювати). Для примусового оновлення слід виконати комбінацію клавіш: <strong>CTRL+SHIFT+R</strong>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn
              color="grey lighten-3"
              @click.stop="refreshApp"
              style="margin: 0 auto;"
              width="320"
              rounded
          >
            Оновити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
        app
        :expand-on-hover="$vuetify.breakpoint.smAndUp"
        :mini-variant="$vuetify.breakpoint.smAndUp"
        :temporary="$vuetify.breakpoint.xsOnly"
        v-model="drawer"
        class="non-printable"
    >
      <v-row>
        <v-col cols="12">
          <v-list nav dense v-if="general_settings">
            <template
                v-for="(item, idx) in nav_items"
            >
              <v-list-item color="success darken-2"
                           active-class="nav-active-cls"
                           :key="`nav-p-${idx}`"
                           :class="{'active-list-item': step === item.step}"
                           @click.stop="stepperClick(item.step)"
                           v-if="getItemShow(item.step)"
              >
                <v-list-item-action>
                  <v-badge
                      :content="vote_stats.votes"
                      offset-y="6"
                      offset-x="5"
                      color="teal"
                      class="custom-badge"
                      overlap
                      v-if="vote_stats.votes && item.badge"
                  >
                    <v-icon color="success" size="23">{{ item.icon }}</v-icon>
                  </v-badge>
                  <v-icon v-else color="success" size="23">{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: .72rem !important; text-transform: uppercase; letter-spacing: 1px; font-weight: 500">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item color="success darken-2"
                         active-class="nav-active-cls"
                         tag="a"
                         target="_blank"
                         :href="getPrivacyPath()"
            >
              <v-list-item-action style="position: relative">
                <v-icon color="success" size="23">mdi-shield-lock-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: .72rem !important; text-transform: uppercase; letter-spacing: 1px; font-weight: 500">
                  Політика конф.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item color="success darken-2"
                         active-class="nav-active-cls"
                         tag="a"
                         href="https://t.me/Wetoo_bot"
            >
              <v-list-item-action style="position: relative">
                <div style="position: absolute; left: 59%; top: 50%; transform: translate(-50%, -50%); height: 27px">
                  <svg style="height: 27px; width: 27px; fill: rgb(10,137,202);"
                       xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#44444" viewBox="0 0 20 20">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                  </svg>
                </div>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: .72rem !important; text-transform: uppercase; letter-spacing: 1px; font-weight: 500">
                  Telegram bot
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div style="position: absolute; bottom: 1px; left: 0; width: 100%">
            <v-list-item color="grey darken-2" @click="logout">
              <v-list-item-action>
                <v-icon color="secondary darken-1" size="23">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: .72rem !important; text-transform: uppercase; letter-spacing: 1px; font-weight: 500">
                  Вийти
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-app-bar app dense elevation="1" color="grey lighten-5" class="non-printable">
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="$vuetify.breakpoint.xsOnly"></v-app-bar-nav-icon>
      <img alt="#" :src="`${publicPath}img/icons/favicon-32x32.png`" class="mx-2"/>
      <v-toolbar-title>
        <a href="https://wetoo-online.vinksoft.com.ua"
           style="text-decoration: none; color: #454545; font-weight: 500;"
           target="_blank"
        >WeToo</a>. Персональний кабінет
      </v-toolbar-title>
    </v-app-bar>

    <v-row style="flex: 0; height: 50px" v-if="step === 12" class="non-printable">
      <v-col cols="12" md="12" class="pt-0 pb-0">
        <v-card rounded style="border: none" elevation="2" :class="$vuetify.breakpoint.xsOnly ? 'mb-3 pa-3' : ''">
          <v-toolbar tile elevation="0" outlined dense style="border: none" class="toolbar-custom">
            <v-toolbar-items class="shrink" style="width: 100%">
              <v-row class="pa-0 ma-0">
                <v-btn text tile min-width="100px" height="100%" small @click="step=0">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                  Розрахунки
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 1">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                  Склад сім’ї
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 2">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                  Архів
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 3">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                  Лічильники
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 4">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                  Обговорення
                </v-btn>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 5">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-archive-outline</v-icon>
                  Звернення
                </v-btn>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 6">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-archive-outline</v-icon>
                  Планування
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 7">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                  Новини
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 8">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-text-box-outline</v-icon>
                  Документи
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 9">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                  Статистика
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 10">
                  <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                  Фінанси
                </v-btn>
                <v-btn text tile min-width="100px" height="100%" small @click="step = 11">
                  <v-icon size="18" left color="purple" class="mr-2">mdi-mdi-face-man-profile</v-icon>
                  Профіль
                </v-btn>
                <v-divider vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn icon @click="logout">
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="flex-wrap non-printable" style="flex: 0; padding: 8px" v-if="!without_menu.includes(step)" >
      <v-col cols="12" class="pa-1">
        <v-sheet
            class="mx-auto transparent"
            max-width="100%"
        >
          <v-slide-group
              v-model="slider"
              center-active
              class="slider"
          >
            <v-slide-item class="px-1">
              <v-menu
                  :close-on-content-click="true"
                  :nudge-width="200"
                  offset-x
                  bottom
                  nudge-bottom="54"
                  max-width="400"
                  class="elevation-0"
                  style="box-shadow: none !important;"
              >
                <template v-slot:activator="{ on, attrs, value }">
                  <v-card class="d-flex flex-nowrap top-card ma-2" v-on="on" v-bind="attrs" style="flex: 1">
                    <div class="top-card-icon"
                         style="background-color: rgba(10,122,235,.02) !important; border-right: 2px solid rgba(10,122,235,.03);"
                    >
                      <v-icon size="40" color="primary">
                        mdi-map-marker-radius-outline
                      </v-icon>
                    </div>
                    <div class="top-card-content">
                      <div class="top-card-subtitle">Адреса</div>
                      <div class="top-card-title">{{ current_flat.full_address }}</div>
                    </div>

                    <div class="top-card-action" style="align-self: center">
                      <v-icon size="26" class="mx-2">
                        {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                      </v-icon>
                    </div>
                  </v-card>
                </template>
                <v-list two-line nav dense>
                  <v-subheader inset class="ml-0" style="font-size: 0.85rem">Доступні квартири</v-subheader>
                  <v-divider/>
                  <v-list-item
                      link v-for="(item, index) in flats" :key="`org-${index}`"
                      @click="changeFlat(item.id)"
                      :disabled="item.person_hash === current_flat.person_hash"
                      :class="item.person_hash === current_flat.person_hash ? 'organization-disabled' : ''"
                      class="text-wrap"
                  >
                    <v-list-item-avatar color="grey" size="34">
                      <v-icon color="white" size="20">mdi-subtitles-outline</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="text-uppercase l-title" style="white-space: normal !important;">{{ item.full_address }}</v-list-item-title>
                      <v-list-item-subtitle>{{ `Особовий рахунок: ${item.person_id}` }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-slide-item>
            <v-slide-item class="px-1">
              <v-card class="d-flex flex-nowrap top-card ma-2" style="flex: 1">
                <div class="top-card-icon"
                     style="background-color: rgba(241,138,13,.03) !important; border-right: 2px solid rgba(241,138,13,.03);">
                  <v-icon size="40" color="secondary">
                    mdi-account-box-outline
                  </v-icon>
                </div>
                <div class="top-card-content">
                  <div class="top-card-subtitle">Власник</div>
                  <div class="top-card-title">{{ current_flat.flat_owner ? current_flat.flat_owner.full_name : '' }}</div>
                </div>

              </v-card>
            </v-slide-item>
            <v-slide-item class="px-1">
                <v-card class="d-flex flex-nowrap top-card ma-2"
                        :style="$vuetify.breakpoint.smAndUp ? '' : 'flex: 1;'"
                        min-width="300"
                        :max-width="$vuetify.breakpoint.smAndUp ? 300 : 9999">
                  <div class="top-card-icon"
                       style="background-color: rgba(211,47,47,.02) !important; border-right: 2px solid rgba(211,47,47,.03);">
                    <v-icon size="40" color="error lighten-1">
                      mdi-calendar-today
                    </v-icon>
                  </div>
                  <div class="top-card-content">
                    <div class="top-card-subtitle">Поточний місяць</div>
                    <div class="top-card-title">{{ current_month | formatDate('MMMM YYYY') }}</div>
                  </div>

                </v-card>
            </v-slide-item>
            <v-slide-item class="px-1">
              <v-card class="d-flex flex-nowrap top-card ma-2" min-width="300"
                :style="$vuetify.breakpoint.smAndUp ? '' : 'flex: 1;'"
                :max-width="$vuetify.breakpoint.smAndUp ? 300 : 9999">
          <div class="top-card-icon"
               style="background-color: rgba(76,175,80,.02); border-right: 2px solid rgba(76,175,80,.03);">
            <v-icon size="40" color="success">
              mdi-numeric-2-box-outline
            </v-icon>
          </div>
          <div class="top-card-content">
            <div class="top-card-subtitle">Особовий рахунок</div>
            <div class="top-card-title">{{ current_flat.person_id }}</div>
          </div>

        </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row style="flex: 1" class="non-printable">
      <v-col cols="12" class="pt-0">
        <v-card class="fill-height pt-0" :flat="without_menu.includes(step)" :class="{'grey lighten-5 mt-3': without_menu.includes(step)}">
          <v-window v-model="step" style="height: 100%">
            <v-window-item style="height: 100%">
              <v-card-text style="height: 100%; display: flex; flex-direction: column">
                <v-row style="flex: 0">
                  <v-col cols="12" md="6" class="pt-1 pb-2">
                    <div class="mb-2 subtitle-1 top-row-title">Нормативні параметри</div>
                    <div class="norm-top-row" v-for="(ind_item, idx) in indicators_actual" :key="`ind-${idx}`">
                      <div class="norm-left">
                        {{ ind_item.indicator.name }}
                      </div>
                      <div class="norm-center">
                        {{ ind_item.indicator.unit_short_name }}
                      </div>
                      <div class="norm-right">
                        {{ ind_item.value_dec | formatToFixed }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-1 pb-2">
                    <div class="mb-2 subtitle-1 top-row-title">Стан розрахунку</div>
                    <div class="balance-wrapper">
                      <div :class="$vuetify.breakpoint.smAndDown ? 'balance-top-row balance-top-row-sm' : 'balance-top-row'"
                      >
                        <div class="balance-left">
                          Сальдо на {{ current_month | formatDate  }}
                        </div>
                        <div class="balance-right">
                          {{ (account_summary.start_balance || 0) | formatToFixed | formatNumber }}
                        </div>
                      </div>
                      <div  :class="$vuetify.breakpoint.smAndDown ? 'balance-top-row balance-top-row-sm' : 'balance-top-row'">
                        <div class="balance-left">
                          Нараховано
                        </div>
                        <div class="balance-right">
                          {{ (account_summary.charge || 0) | formatToFixed | formatNumber }}
                        </div>
                      </div>
                      <div  :class="$vuetify.breakpoint.smAndDown ? 'balance-top-row balance-top-row-sm' : 'balance-top-row'">
                        <div class="balance-left">
                          Перерахунок
                        </div>
                        <div class="balance-right">
                          {{ (account_summary.recalculation || 0) | formatToFixed | formatNumber }}
                        </div>
                      </div>
                      <div  :class="$vuetify.breakpoint.smAndDown ? 'balance-top-row balance-top-row-sm' : 'balance-top-row'">
                        <div class="balance-left">
                          Оплачено
                        </div>
                        <div class="balance-right">
                          {{ (account_summary.pay || 0) | formatToFixed | formatNumber }}
                        </div>
                      </div>
                      <div  :class="$vuetify.breakpoint.smAndDown ? 'balance-top-row balance-top-row-sm' : 'balance-top-row'">
                        <div class="balance-left">
                          Монетизація
                        </div>
                        <div class="balance-right">
                          0.00
                        </div>
                      </div>
                      <div  :class="$vuetify.breakpoint.smAndDown ? 'balance-top-row balance-top-row-sm' : 'balance-top-row'">
                        <div class="balance-left">
                          Сальдо на {{ endOfMonth(current_month) | formatDate }}
                        </div>
                        <div class="balance-right">
                          {{ (account_summary.end_balance || 0) | formatToFixed | formatNumber }}
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-1 pb-2">
                    <v-row>
                      <v-col cols="12" md="9" class="pt-1 pb-2">
                        <div class="mb-2 subtitle-1 top-row-title">Реквізити для оплати</div>
                        <div style="background: whitesmoke; padding: 10px;">
                          {{ this.print_data ? this.print_data.organization_details_with_account_without_contacts : '' }}
                        </div>
                      </v-col>
                      <v-col cols="12" md="3" class="pt-1 pb-2">
                        <div class="mb-2 subtitle-1 top-row-title" style="height: 28px"></div>
                        <v-menu offset-y close-on-content-click close-on-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="success"
                                :dark="!!pay_buttons.length"
                                :disabled="!pay_buttons.length"
                                depressed
                                block
                                v-bind="attrs"
                                v-on="on"
                                style="height: 30px; font-size: .85rem; min-height: 30px"
                            >
                              Оплатити
                            </v-btn>
                          </template>
                          <v-list nav v-if="pay_buttons.length">
                            <template v-for="pay_button in pay_buttons">
                              <v-list-item :key="pay_button.id"
                                           :href="pay_button.redirect"
                                           target="_blank"
                                           class="mt-2"
                                           tag="a">
                                <template v-if="pay_button.icon === 'google_pay'">
                                  <v-list-item-icon>
                                    <img :src="`${publicPath}img/icons/google_pay.svg`" alt="google_pay" height="46" width="46">
                                  </v-list-item-icon>
                                </template>
                                <template v-else-if="pay_button.icon === 'ipay'">
                                  <v-list-item-icon>
                                    <img :src="`${publicPath}img/icons/ipay_logo.svg`" alt="google_pay" height="46" width="46">
                                  </v-list-item-icon>
                                </template>
                                <template v-else-if="pay_button.icon === 'mono'">
                                  <v-list-item-icon>
                                    <img :src="`${publicPath}img/icons/mono_logo.svg`" alt="google_pay" height="46" width="46">
                                  </v-list-item-icon>
                                </template>
                                <template v-else-if="pay_button.icon === 'privat24'">
                                  <v-list-item-icon>
                                    <img :src="`${publicPath}img/icons/privat24_logo.svg`" alt="google_pay" height="46" width="46">
                                  </v-list-item-icon>
                                </template>
                                <template v-else-if="pay_button.icon === 'easy_pay'">
                                  <v-list-item-icon>
                                    <img :src="`${publicPath}img/icons/easy_pay_logo.svg`" alt="google_pay" height="46" width="46">
                                  </v-list-item-icon>
                                </template>
                                <template v-else-if="pay_button.icon === 'visa'">
                                  <v-list-item-icon>
                                    <img :src="`${publicPath}img/icons/visa_logo.svg`" alt="google_pay" height="46" width="46">
                                  </v-list-item-icon>
                                </template>
                                <template v-else-if="pay_button.icon === 'mastercard'">
                                  <v-list-item-icon>
                                    <img :src="`${publicPath}img/icons/mastercard_logo.svg`" alt="google_pay" height="46" width="46">
                                  </v-list-item-icon>
                                </template>
                                <template v-else-if="pay_button.icon === 'apple_pay'">
                                  <v-list-item-icon>
                                    <img :src="`${publicPath}img/icons/Apple_Pay_logo.svg`" alt="google_pay" height="46" width="46">
                                  </v-list-item-icon>
                                </template>
                                <template v-else>
                                  <v-list-item-icon>
                                    <v-icon size="36" :color="pay_button.button_color">
                                      {{ pay_button.icon }}
                                    </v-icon>
                                  </v-list-item-icon>
                                </template>
                                <v-list-item-title class="font-weight-medium">
                                  {{ pay_button.name }}
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider :key="`${pay_button.id}-1`"/>
                            </template>
                          </v-list>
                        </v-menu>
                        <v-btn
                            class="mt-1"
                            color="secondary"
                            depressed
                            block
                            style="height: 30px; font-size: .85rem;"
                            @click.stop="printReceipt"
                        >
                          Рахунок
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-1 pb-2">
                    <div class="mb-2 subtitle-1 top-row-title">Контактні дані</div>
                    <div class="balance-wrapper">
                      <div class="balance-top-row" v-for="(contact, idx) in print_data.contacts"
                           :key="`contact-d-${idx}`">
                        <div class="balance-left">
                          {{ contact.name }}
                        </div>
                        <div class="balance-right">
                          <template v-if="contact.contact_type">
                            <template v-if="contact.contact_type === 'email'">
                              <a :href="`mailto:${contact.value_}`" class="contact-href">
                                {{ contact.value_ }}
                              </a>
                            </template>
                            <template v-if="contact.contact_type === 'phone'">
                              <template v-if="contact.value_">
                                <a :href="`tel:${contact.value_}`" class="contact-href">
                                  {{ contact.value }}
                                </a>
                              </template>
                              <template v-else>
                                {{ contact.value }}
                              </template>
                            </template>
                          </template>
                          <template v-else>
                            {{ contact.value }}
                          </template>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row style="flex: 1">
                  <v-col cols="12" md="12">
                    <HWP_Accounts
                        :nonClickable="true"
                        :readOnly="true"
                        :textCard="true"
                        :height="'100%'"
                        :person_hash="current_flat.person_hash"
                        :bigger="true"
                        :title="'Детальний стан розрахунку'"
                        v-if="current_flat"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item style="height: 100%">
              <v-card-text style="height: 100%; display: flex; flex-direction: column" v-show="show_family">
                <v-row style="flex: 1">
                  <v-col cols="12">
                    <HWP_Family
                        :height="'100%'"
                        :text-card="true"
                        :person_hash="current_flat.person_hash"
                        :bigger="true"
                        :title="'Детальний стан розрахунку'"
                        :personal="true"
                        :read-only="true"
                        :non-clickable="true"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item style="height: 100%">
              <v-card-text style="height: 100%; display: flex; flex-direction: column">
                <v-row style="flex: 1">
                  <v-col cols="12">
                    <HWP_Archive
                        :height="'100%'"
                        :text-card="true"
                        :personal="true"
                        :person_hash="current_flat.person_hash"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item style="height: 100%">
              <v-card-text>
                <v-row :style="$vuetify.breakpoint.mdAndUp
              ? 'display: flex; flex-direction: column; flex-wrap: nowrap; height: 75vh'
              : 'display: flex; flex-direction: column; flex-wrap: nowrap; height: 100%'"
                >
                  <v-col cols="12" class="pb-1" style="flex: 0;">
                    <v-alert type="success" text class="mb-2">
                      Для подання показника - слід відмітити необхідний лічильник у таблиці "Прилади обліку", та
                      виконати команду "Подати показ" у таблиці "Архів показів"
                    </v-alert>
                    <FlatCounter
                        :selected="selected"
                        :height="'180px'"
                        :text-card="true"
                        :personal="true"
                        :person_hash="current_flat.person_hash"
                        :read-only="true"
                        :disabled="presentation"
                    />
                  </v-col>
                  <v-col cols="12" style="flex: 1">
                    <HWP_Pokaz
                        :counter_id="selected.length ? selected[0].id : -1"
                        :selected="selected"
                        :height="'100%'"
                        :text-card="true"
                        :personal="true"
                        :person_hash="current_flat.person_hash"
                        :button="true"
                        :disabled="presentation"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item style="height: 100%">
              <Voting :person_hash="current_flat.person_hash" :personal="true" :disabled="presentation"/>
            </v-window-item>
            <v-window-item style="height: 100%">
              <Appeal :person_hash="current_flat.person_hash" :personal="true" :disabled="presentation"/>
            </v-window-item>
            <v-window-item style="height: 100%">
              <Estimate :person_hash="current_flat.person_hash" :personal="true" :disabled="presentation"/>
            </v-window-item>
            <v-window-item style="height: 100%">
              <v-card-text style="height: 100%; display: flex; flex-direction: column">
                <v-row style="flex: 0">
                  <v-card-text class="text-uppercase pl-2 py-2" style="font-size: 1.2rem;">
                    Оголошення
                  </v-card-text>
                  <v-card-text class="px-1">
                    <v-row v-if="notifications.length">
                      <v-col cols="12" sm="6" md="4"
                             v-for="(notify, n) in notifications" :key="`notification-${n}`"
                      >
                        <v-card
                            outlined
                            height="100%"
                            :style="getBorderStyle(notify.status)"
                        >
                          <v-card-text class="pt-1">
                            <div class="text-overline mb-2 d-flex"
                                 :class="getStatusType(notify.status, 'color')"
                                 style="font-size: .68rem !important;"
                            >
                              <div>{{ getStatusType(notify.status) }}</div>
                              <v-spacer></v-spacer>
                              <div class="grey--text text--darken-1">
                                {{ notify.create_date | formatDate }}
                              </div>
                            </div>
                            <div class="font-weight-bold mb-2 text-wrap" style="color: #2d2d2d; font-size: 1rem">
                              {{ notify.title }}
                            </div>
                            <div>
                              {{ notify.body }}
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col cols="12">
                        <v-card
                            outlined
                            height="115"
                            style="border-left-color: #40a144; border-left-width: 5px; display: flex; align-items: center; justify-content: center"
                        >
                          <v-card-text class="pt-1">
                            <div class="pa-3 title">
                              На даний момент оголошень немає...
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-row>
                <v-row>
                  <v-dialog fullscreen v-model="preview_dialog" overlay-color="grey darken-2">
                    <div class="site-section-wrapper" style="min-height: 90vh">
                      <div class="site-section" style="min-height: 90vh">
                        <v-card class="px-8 pt-12 pb-10" style="position: relative; min-height: 90vh">
                          <div>
                            <v-btn depressed small style="position: absolute; top: 3px; right: 5px" @click="closeNewsDialog">Закрити</v-btn>
                          </div>
                          <div
                              style="font-size: .9rem; color: #4a4a4a; padding-left: 2px; padding-bottom: 4px; text-transform: uppercase; display: flex">
                            <div>Новини</div>
                            <v-spacer></v-spacer>
                            <div>{{ news_object.create_date | formatDate }}</div>
                          </div>
                          <v-divider></v-divider>
                          <div style="font-size: 1.8rem; text-transform: uppercase; font-weight: 500; padding-bottom: 18px; padding-top: 12px">
                            {{ news_object.title }}
                          </div>
                          <div>
                            <v-img
                                :src="getFile(news_object)"
                                contain
                                height="200px"
                            />
                          </div>
                          <div class="py-6" v-html="compiledMarkdown">
                          </div>
                        </v-card>
                      </div>
                    </div>
                  </v-dialog>
                  <v-card-text class="px-1 py-0">
                    <v-card-text class="text-uppercase pl-1 py-2" style="font-size: 1.2rem;">
                      Новини
                    </v-card-text>
                    <v-row v-if="news.length">
                      <v-col cols="12" md="4" sm="12"
                             v-for="(item, i) in news" :key="`news-${i}`"
                             class="news-card"
                      >
                        <v-card class="d-flex align-center justify-center flex-column"
                        >
                          <div class="pa-3 mr-1">
                            <v-img
                                :src="getFile(item)"
                                aspect-ratio="1"
                                contain
                                :max-height="$vuetify.breakpoint.mdAndUp ? '160' : '180'"
                            />
                          </div>
                          <div class="pa-3 pb-2" style="position:relative;">
                            <div class="font-weight-bold mb-3 text-wrap" style="color: #2d2d2d; font-size: 1rem">
                              {{ item.title.slice(0, 80) }}
                            </div>
                            <div style="height: 103px; padding-bottom: 6px; overflow: hidden; position: relative;">
                              {{ item.body.slice(0, 400) }}
                              <div class="bottom-text-block"></div>
                            </div>
                            <div class="mt-2 pt-3" >
                              <v-btn block depressed text small @click="openNewsDialog(item)">Читати далі</v-btn>
                            </div>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col cols="12">
                        <v-card
                            outlined
                            height="217"
                            style="border-left-color: #40a144; border-left-width: 5px; display: flex; align-items: center; justify-content: center"
                        >
                          <v-card-text class="pt-1">
                            <div class="pa-3 title">
                              На даний момент новин немає...
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item style="height: 100%">
              <OrganizationDocumentsNew :personal="true"/>
            </v-window-item>
            <v-window-item style="height: 100%">
              <Dashboard :person_hash="current_flat.person_hash" :personal="true"/>
            </v-window-item>
            <v-window-item style="height: 100%">
              <FinancialStats :person_hash="current_flat.person_hash" :personal="true"/>
            </v-window-item>
            <v-window-item style="height: 100%;">
              <UserProfile :personal_cab="true"/>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
    <GeneralUserMessage v-if="!updateExists" class="non-printable"/>
  </v-container>
</template>

<script>
import HWP_Accounts from "@/components/HouseWorkPlace/HWP_Accounts";
import HWP_Family from "@/components/HouseWorkPlace/HWP_Family";
import HWP_Archive from "@/components/HouseWorkPlace/HWP_Archive";
import FlatCounter from "@/components/FlatCounter";
import HWP_Pokaz from "@/components/HouseWorkPlace/HWP_Pokaz";
import UserProfile from "@/components/Profile"

import {mapActions, mapGetters} from "vuex";
import {AUTH_LOGOUT, AUTO_LOGIN} from "@/store/actions/auth";
import {endOfMonth, ext_obj} from "@/utils/icons"
import {GET_ORGANIZATION_PRINT_DATA, SET_CURRENT_FLAT} from "@/store/actions/organization";
import {FETCH_FAMILY_BY_FLAT, GET_RELATION_TYPE_SELECT} from "@/store/actions/family";
import {FETCH_FLAT_INDICATORS} from "@/store/actions/flat_indicators";
import cabinetAPI from "@/utils/axios/personalCabinet/cabinet"
import paySystemAPI from '@/utils/axios/pay_system'
import {getStatusType} from "@/utils/icons"
import {BASE_URL_AXIOS} from "@/utils/axios";
import marked from 'marked'
import {onBeforeUnload} from "@/utils/icons"
import OrganizationDocumentsNew from "@/components/OrganizationDocumentsNew";
import {VOTE_GET_STATS} from "@/store/actions/vote";
import update from "@/mixins/update";
import {USER_RANDOM_FLAT} from "@/store/actions/user";
import GeneralUserMessage from "@/components/modal/GeneralUserMessage.vue";
import pushNotification from '@/utils/axios/puhs_notification';
import {SERVER_PUBLIC_KEY, subscribeToPush, requestPermission} from "@/utils/icons"

marked.setOptions({
  silent: true,
  pedantic: false,
  gfm: true,
  breaks: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: false,
  mangle: true
})

export default {
  name: "PersonalWorkPlace",
  components: {
    GeneralUserMessage,
    OrganizationDocumentsNew,
    HWP_Accounts,
    HWP_Family,
    HWP_Archive,
    FlatCounter,
    HWP_Pokaz,
    Voting: () => import("@/components/VotingNew"),
    UserProfile,
    Dashboard: () => import("@/components/Dashboard"),
    FinancialStats: () => import("@/components/financial_stats"),
    Appeal: () => import("@/components/Appeal"),
    Estimate: () => import("@/components/Estimate")
  },
  computed: {
    ...mapGetters({
      current_flat: 'getCurrentUserFlat',
      indicators: 'getFLatIndicators',
      current_month: 'getCurrentMonth',
      account_summary: 'getFlatSummary',
      flats: 'getUserFlats',
      general_settings: 'getGeneralSettings',
      organization: 'getCurrentOrganization',
      vote_stats: 'getVoteStats',
      socket_data: 'get_socket',
      services: 'getServicesSelectWithoutAll'
    }),
    defaultServiceName() {
      const d = this.general_settings?.default_service?.value || null
      if (d) {
        return ((this.services || []).find(i => i.value === d) || {}).text || ''
      } else {
        return ''
      }
    },
    indicators_actual() {
        return this.indicators.filter(item => item.actual)
    },
    compiledMarkdown() {
      if (!this.news_object.body) return ''
      return marked(this.news_object.body);
    },
    show_family() {
      return this.general_settings?.personal_cabinet_show_family?.value || false
    },
    show_counter() {
      return this.general_settings?.personal_cabinet_show_counters?.value || false
    },
    show_voting() {
      return this.general_settings?.personal_cabinet_show_voting?.value || false
    },
    show_news() {
      return this.general_settings?.personal_cabinet_show_news?.value || false
    },
    show_documents() {
      return this.general_settings?.personal_cabinet_show_documents?.value || false
    },
    show_stats() {
      return this.general_settings?.personal_cabinet_show_stats?.value || false
    },
    show_financial_stats() {
      return this.general_settings?.personal_cabinet_show_financial_stats?.value || false
    },
    show_appeal() {
      return this.general_settings?.personal_cabinet_show_appeal?.value || false
    },
    show_estimate() {
      return this.general_settings?.personal_cabinet_show_estimate?.value || false
    },
  },
  data() {
    return {
        presentation: false,
      slider: null,
      drawer: true,
      preview_dialog: false,
      news_object: {},
      step: 0,
      err: '',
      selected: [],
      notifications: [],
      documents: {},
      without_menu: [4, 5, 6, 7, 8, 9, 10, 11],
      news: [],
      print_data: {},
      flat_loading: false,
      doc_group: null,
      nav_items: [
        { icon: 'mdi-currency-usd', title: 'Стан розрахунків', step: 0, show: true },
        { icon: 'mdi-account-group-outline', title: 'Склад сім’ї', step: 1, show: this.show_family },
        { icon: 'mdi-calendar-month-outline', title: 'Архів', step: 2, show: true },
        { icon: 'mdi-speedometer-medium', title: 'Лічильники', step: 3, show: this.show_counter },
        { icon: 'mdi-comment-text-multiple-outline', title: 'Обговорення', step: 4, show: this.show_voting, badge: true },
        { icon: 'mdi-archive-outline', title: 'Звернення', step: 5, show: this.show_appeal },
        { icon: 'mdi-office-building-cog-outline', title: 'Планування', step: 6, show: this.show_estimate },
        { icon: 'mdi-newspaper-variant-outline', title: 'Новини', step: 7, show: this.show_news },
        { icon: 'mdi-text-box-outline', title: 'Документи', step: 8, show: this.show_documents },
        { icon: 'mdi-monitor-dashboard', title: 'Статистика', step: 9, show: this.show_stats },
        { icon: 'mdi-finance', title: 'Фінанси', step: 10, show: this.show_financial_stats },
        { icon: 'mdi-face-man-profile', title: 'Профіль', step: 11, show: true },
      ],
      publicPath: process.env.BASE_URL,
      pay_buttons: []
    }
  },
  mixins: [update],
  methods: {
    checkPhoneNumber(phone) {
      let recipient = phone;
      recipient = recipient.replace(/[-?*()[\].,/$%^&+]/g, '');
      recipient = '+380' + recipient.replace(/^380?|^80|^\+380|^0/, '');
      if (recipient.length !== 13) {
        return null;
      } else {
        return recipient;
      }
    },
    printReceipt() {
      // // let a = window.open('', '');
      // // a.document.write('<html>');
      // // a.document.write('<body >');
      // // a.document.write(divContents);
      // // a.document.write('</body></html>');
      // // a.document.close();
      window.print();
    },
    buildPayButtons(payload) {
      const pay_buttons = []
      const regex = /\{\{.*?}}/g
      const global_replace = {
        personal_id: this.current_flat.person_id,
        person_id: this.current_flat.person_id,
        person_hash: this.current_flat.person_hash,
        organization_code: this.organization.code,
        organization_id: this.organization.id
      }

      payload.forEach(item => {
        const found = item.url_template.match(regex) || []
        const json_data = item.data || {}
        let url = item.url_template
        const for_replace = Object.assign({}, global_replace, json_data)

        found.forEach(group => {
          const group_el = group.replace('{{', '').replace('}}', '')
          url = url.replace(group, for_replace[group_el])
        })
        pay_buttons.push(
            {
              name: item.button_name,
              icon: item.icon || 'mdi-contactless-payment-circle-outline',
              redirect: url,
              button_color: json_data.button_color || 'primary'
            }
        )
      })
      this.pay_buttons = pay_buttons
    },
    onResizeNew() {
      this.$store.dispatch("SET_SCREEN_DATA", {width: window.innerWidth, height: window.innerHeight})
    },
    getPrivacyPath() {
      return `${window.location.origin}/privacy-policy`
    },
    getItemShow(step) {
      const items = {
        0: true,
        1: this.show_family,
        2: true,
        3: this.show_counter,
        4: this.show_voting,
        5: this.show_appeal,
        6: this.show_estimate,
        7: this.show_news,
        8: this.show_documents,
        9: this.show_stats,
        10: this.show_financial_stats,
        11: true
      }

      return items[step] || false
    },
    openNewsDialog(payload) {
      this.news_object = payload
      this.preview_dialog = true
    },
    closeNewsDialog() {
      this.news_object = {}
      this.preview_dialog = false
    },
    getStatusType,
    getFile(payload) {
      if (!payload.filepath) return `${this.publicPath}img/icons/no_image.png`
      return `${BASE_URL_AXIOS}/${payload.filepath}`
    },
    getFileIcon(ext) {
      const file_ext = (ext_obj.find(item_ext => item_ext.ext === ext)) || {ext: 'txt', mime: ''}
      return file_ext.icon || 'mdi-document'
    },
    getBorderStyle(payload) {
      // return `border-bottom-color: var(--v-grey-lighten3); border-bottom-width: 2px;`
      return `border-left-color: ${this.getStatusType(payload, 'border')}; border-left-width: 3px; border-bottom-width: 2px`
    },
    ...mapActions({
      fetchIndicatorsActual: FETCH_FLAT_INDICATORS,
      setCurrentFlat: SET_CURRENT_FLAT,
      fetchFamily: FETCH_FAMILY_BY_FLAT,
      fetchRelationTypes: GET_RELATION_TYPE_SELECT,
      fetchVoteStats: VOTE_GET_STATS
    }),
    stepperClick(step) {
      this.step = step
      // this.drawer = false
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT)
          .then(() => {
            this.$router.push({name: 'Login'})
          })
    },
    changeFlat(flat_id) {
      this.flat_loading = true
      this.setCurrentFlat(flat_id)
          .then(response => {
            if (response) {
              this.$store.dispatch(AUTO_LOGIN)
                  .then(() => {
                    this.organization_loading = false
                    this.fetchIndicatorsActual(this.current_flat.person_hash)
                    this.$store.dispatch(GET_ORGANIZATION_PRINT_DATA)
                        .then(
                            data => this.print_data = data
                        )

                    paySystemAPI.get_all_enable()
                        .then(response => response.data)
                        .then(data => {
                          this.buildPayButtons(data)
                        })

                    this.fetchVoteStats()

                    cabinetAPI.get_notification()
                        .then(response => response.data)
                        .then(data => {
                          this.notifications = data

                          cabinetAPI.get_news()
                            .then(response => response.data)
                            .then(data => this.news = data)
                        })

                    this.afterServiceWorkerRegistered()
                  })
            }
          })
          .catch(() => {
            this.flat_loading = false
          })
    },
    endOfMonth,
    afterServiceWorkerRegistered() {
      navigator.serviceWorker.getRegistration()
          .then(registration => {
            requestPermission()
                .then(permission => {
                  if (permission === 'granted') {
                    subscribeToPush(registration, SERVER_PUBLIC_KEY, pushNotification);
                  } else {
                    console.log('permission not granted')
                  }
                })
          })


    },
  },
  created() {
      this.onResizeNew()
      window.removeEventListener('beforeunload', onBeforeUnload)
      if (this.$route.name === 'RepresentPersonalWorkplace') {
          this.presentation = true
          this.$store.dispatch(USER_RANDOM_FLAT)
              .then(() => {
                  this.fetchIndicatorsActual(this.current_flat.person_hash)
                  this.$store.dispatch(GET_ORGANIZATION_PRINT_DATA)
                      .then(
                          data => this.print_data = data
                      )
                  paySystemAPI.get_all_enable()
                      .then(response => response.data)
                      .then(data => {
                          this.buildPayButtons(data)
                      })

                  this.fetchVoteStats()

                  cabinetAPI.get_notification()
                      .then(response => response.data)
                      .then(data => {
                          this.notifications = data

                          cabinetAPI.get_news()
                              .then(response => response.data)
                              .then(data => {
                                  this.news = data
                              })
                      })
              })
      } else {
          this.presentation = false
          this.$store.dispatch(AUTO_LOGIN)
              .then(() => {
                  this.$ws.start()
                  this.fetchIndicatorsActual(this.current_flat.person_hash)

                  this.$store.dispatch(GET_ORGANIZATION_PRINT_DATA)
                      .then(
                          data => {
                            this.print_data = data

                            if (this.print_data.contacts) {
                              this.print_data.contacts.forEach(contact => {
                                contact.value_ = contact.value
                                if (contact.contact_type === 'phone') {
                                  contact.value_ = this.checkPhoneNumber(contact.value)
                                }
                              })
                            }
                          }
                      )

                  paySystemAPI.get_all_enable()
                      .then(response => response.data)
                      .then(data => {
                          this.buildPayButtons(data)
                      })

                  this.fetchVoteStats()

                  cabinetAPI.get_notification()
                      .then(response => response.data)
                      .then(data => {
                          this.notifications = data

                          cabinetAPI.get_news()
                              .then(response => response.data)
                              .then(data => {
                                  this.news = data
                              })

                          // cabinetAPI.get_documents()
                          //     .then(response => response.data)
                          //     .then(data => {
                          //       this.documents = data
                          //     })
                      })

                  this.afterServiceWorkerRegistered()
              })
              .catch(() => {
                  this.err = 'err'
              })
      }
  },
  watch: {
    socket_data: {
      immediate: true,
      handler(payload) {
        if (payload) {
          if (payload.data.ws_type === 'vote_crud'
              && payload.data.organization_id === this.organization.id) {
            this.$store.dispatch(VOTE_GET_STATS)
          }
        }
      }
    }
  },
  beforeDestroy() {
    window.addEventListener('beforeunload', onBeforeUnload)
    this.$ws.close()
  }
}
</script>

<style scoped lang="scss">
.hide-before-print-cab {
  display: none;
}
.bottom-text-block {
  height: 22px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ffffffbd;
}
.active-list-item {
  background: lightgrey !important;
}
.organization-disabled {
  background: #efefef !important;

  .l-title {
    color: #2d2d2d;
  }
}
.toolbar-custom {
  &:deep(.v-toolbar__content) {
    padding-left: 0 !important;
  }
}
.top-card {
  .top-card-icon {
    flex: 0 1 70px;
    padding: 16px;
  }

  .top-card-content {
    flex: 1;
    padding: 15px;

    .top-card-subtitle {
      font-size: 0.88rem;
      font-weight: 500;
      color: #656565;
    }

    .top-card-title {
      font-size: 1.04rem;
      color: #3e3e3e;
    }
  }

}
.top-row-title {
  color: #449c48;
  font-weight: 500;
}
.norm-top-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .norm-left {
    flex: 1 30%;
    margin-right: 10px;
    padding: 7px;
    background: #eeeeee;
    color: #606060;
    font-weight: 500;
  }

  .norm-center {
    flex: 0 0 100px;
    margin-right: 10px;
    padding: 7px;
    background-color: #f5f5f5;
    text-align: center;
  }

  .norm-right {
    flex: 1;
    padding: 7px;
    background-color: #f5f5f5;
  }
}
.balance-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .balance-top-row-sm {
    margin-right: 0 !important;
  }

  .balance-top-row {
    flex: 48%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-right: 10px;

    .balance-left {
      flex: 1 1 26%;
      margin-right: 10px;
      padding: 5px;
      background: #eeeeee;
      color: #606060;
      font-weight: 500;
    }

    .balance-right {
      flex: 1;
      padding: 7px;
      min-width: 100px;
      background-color: #f5f5f5;
    }
  }
}
.contact-href {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

@media only screen and (max-width: 1300px) and (min-width: 900px)  {
  .news-card {
    flex: 1 1 50% !important;
    max-width: 50% !important;
  }
}

.personal-cabinet-receipt {
  .pcr-section {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .border-bottom {
    border-bottom: 1px dashed grey !important;
  }

  .pcr-row {
    display: flex;
    font-size: 13px;
    line-height: 18px;

    .pcr-left-col {
      flex: 0 0 200px;
      border: 1px dashed grey;
      border-right: none;
      padding: 10px;
      font-weight: bold;
      border-bottom: none;
    }
    .pcr-right-col {
      flex: 1;
      border: 1px dashed grey;
      padding: 10px;
      border-bottom: none;
    }
  }
}
</style>
