<template>
  <v-card
      :tile="textCard"
      elevation="1"
      :height="height"
      :style="textCard ? '' : 'border-left: 3px solid #5bb55f'"
      min-height="280">
    <MainModal
        :main="{ component: 'Family', title: 'Склад сім’ї' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_family)"
        :nonClickable="nonClickable"
        :modal="show_family_dialog"
        @updateItemModal="familyUpdateItemModal"
    />
    <v-card-text class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Перелік даних по мешканцях
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <YouTubeButton
            button-class="grey lighten-4 mr-1"
            icon-color="error lighten-1"
            :icon-size="26"
            href="https://youtu.be/ol3DjIVW7AQ"
            v-if="!readOnly"
        />
        <v-tooltip bottom v-if="!readOnly && !personal">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="ml-2 mr-2 grey lighten-4" v-on="on" v-bind="attrs" @click.stop="">
              <v-icon color="grey darken-2" size="22">mdi-comment-question-outline</v-icon>
            </v-btn>
          </template>
          <span>
            Для зміни власника слід відкрити <br> необхідного мешканця (при відсутності - створити) <br> та вказати відношення ВЛАСНИК
          </span>
        </v-tooltip>
        <v-tooltip bottom color="success" v-if="!readOnly && !personal">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openFamilyCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити мешканця/власника</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-text>
    <v-data-table
        :headers="familyHeaders"
        :items="family"
        hide-default-footer
        :class="bigger ? 'custom-table-bigger' : ''"
        class="custom-table custom-table-1"
        @click:row="onFamilyItemClick"
    >
      <template v-slot:item.icon="{ item }">
        <v-icon :color="item.flat_owner ? 'secondary' : 'success'" size="26" class="mr-2">
          {{ item.flat_owner ? 'mdi-account-alert' : 'mdi-account-multiple' }}
        </v-icon>
      </template>
      <template v-slot:item.full_name="{ item }">
        <span :class="item.flat_owner ? 'font-weight-medium' : ''">
          {{ item.full_name }}
        </span>
      </template>
      <template v-slot:item.date_start="{ item }">
        {{ item.date_start | formatDate }}
      </template>
        <template v-slot:item.date_end="{ item }">
            {{ item.date_end | formatDate }}
        </template>
      <template v-slot:item.relation_name="{ item }">
        <v-chip
            class="mr-2"
            :color="item.flat_owner ? 'secondary' : ''"
            small
        >
          {{ item.relation_name }}
        </v-chip>
      </template>
      <template v-slot:item.registration_type="{ item }">
        <span>{{ registrationType[item.registration_type] }}</span>
      </template>
      <template v-slot:item.created_by_person="{ item }">
        <v-simple-checkbox :value="item.created_by_person" disabled/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {FETCH_FAMILY_BY_FLAT, GET_RELATION_TYPE_SELECT} from "@/store/actions/family";
import {registrationType} from "@/utils/icons";

export default {
  name: "HWP_Family",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    nonClickable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Поточний стан розрахунку по абоненту'
    },
    person_hash: {
      type: String,
      default: ''
    },
    bigger: {
      type: Boolean,
      default: false
    },
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    },
    not_living: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MainModal,
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  computed: {
    ...mapGetters({
      family: 'getFamily'
    }),
    current_flat_hash() {
      let person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
      if (person_hash) {
        return person_hash
      } else {
        return this.person_hash
      }
    }
  },
  data() {
    return {
      registrationType,
      familyHeaders: [
        { text: '', value: 'icon', width: 60 },
        { text: 'П.І.Б', value: 'full_name' },
        { text: 'Відношення', value: 'relation_name' },
        { text: 'Статус', value: 'registration_type' },
        { text: 'Дата з', value: 'date_start' },
        { text: 'Дата по', value: 'date_end' },
        { text: 'Телефон', value: 'phone_number.contact_value' },
        { text: 'Email', value: 'email.contact_value' },
        { text: 'Ств.мешк.', value: 'created_by_person' }
      ],
      selected_family: {},
      show_family_dialog: false,
    }
  },
  methods: {
    ...mapActions({
      fetchFamily: FETCH_FAMILY_BY_FLAT,
      fetchRelationTypes: GET_RELATION_TYPE_SELECT
    }),
    familyUpdateItemModal() {
      this.show_family_dialog = false
      this.selected_family = {}
    },
    openFamilyCreateDialog() {
      this.selected_family = {
        flat_hash: this.current_flat_hash,
        personal: this.personal,
        not_living: this.not_living
      }
      this.show_family_dialog = true
    },
    onFamilyItemClick(payload) {
      if (this.readOnly || this.nonClickable || this.personal) {
        this.selected_family = {}
        this.show_family_dialog = false
        return
      }

      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.flat_hash = this.current_flat_hash
      local_payload.phone_number = local_payload.phone_number ? local_payload.phone_number.contact_value : null
      local_payload.phone_number_id = local_payload.phone_number ? local_payload.phone_number.id : null
      local_payload.email = local_payload.email ? local_payload.email.contact_value : null
      local_payload.email_id = local_payload.email? local_payload.email.id : null
      local_payload.personal = this.personal
      local_payload.not_living = this.not_living

      this.selected_family = local_payload
      this.show_family_dialog = true
    },
  },
  created() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetchFamily(person_hash)
        .then(res => {
          if (res) {
            this.fetchRelationTypes()
          }
        })
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetchFamily(payload)
              .then(res => {
                if (res) {
                  this.fetchRelationTypes()
                }
              })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.custom-table-bigger {
  :deep(.v-data-table__wrapper) {
    tbody {
      tr {
        td {
          height: 40px !important;
        }
      }
    }
    thead {
      tr {
        th {
          height: 46px !important;
        }
      }
    }
  }
}
</style>