import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/personal-cabinet';

export default {
    get_notification() {
        return axios.get(`${RESOURCE_NAME}/notification`)
    },
    get_news() {
        return axios.get(`${RESOURCE_NAME}/news`)
    },
    get_documents() {
        return axios.get(`${RESOURCE_NAME}/documents`)
    }
};