<template>
  <v-card elevation="1" class="fill-height pa-10" min-height="280">
    <v-row>
      <div class="px-3 d-flex align-center">
        <MainModal
            :main="{ component: 'DocumentGroupType', title: 'Групи документів' }"
            :button="{ icon: false, buttonColor: 'secondary',buttonClass: 'mr-2', buttonIcon: 'mdi-folder-plus-outline', buttonOutlined: true, buttonDepressed: true }"
            :item=editObject
            :modal="showGroupModal"
            @updateItemModal="afterModalClose"
            v-if="!personal && !site"
        />
        <MainModal
            :main="{ component: 'OrganizationDocuments', title: 'Документи' }"
            :button="{ icon: false, buttonColor: 'success',buttonClass: 'mr-4', buttonIcon: 'mdi-file-plus-outline', buttonOutlined: true, buttonDepressed: true }"
            :item=editObject
            :modal="showDocumentModal"
            @updateItemModal="afterModalClose"
            v-if="!personal && !site"
        />
        <template v-for="(item, idx) in story">
          <v-btn :key="item.id" color="grey lighten-3" depressed @click="onStoryClick(item)">
            {{ item.text }}
          </v-btn>
          <div :key="`divider-${item.id}`" v-if="idx + 1 < story.length"
               class="px-4 grey--text" style="font-size: 22px; font-weight: bold"
          >
            >
          </div>
        </template>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12" :md="site ? 4 : 3" sm="6" :xl="site ? 3 : 2"
             v-for="(doc, idx) in documents"
             :key="`doc-${idx}`"
      >
        <v-card max-width="300" min-height="180" @click.stop="onItemClick(doc)"
                style="display: flex; position: relative; flex-direction: column"
                class="document-card"
                elevation="3">

            <v-menu bottom left v-if="!personal && !site">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="dotted-button grey lighten-4">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense nav>
                <v-list-item @click="onItemEdit(doc)">
                  <v-list-item-title>Редагувати</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Перенести</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

          <v-menu bottom left v-if="!doc.is_group && doc.files.length" nudge-width="220">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="grey lighten-4" :class="personal || site ? 'dotted-button' : 'download-button'">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>

            <v-list dense nav>
              <v-list-item
                  v-for="(file, fidx) in doc.files" :key="`file-${doc.id}-${fidx}`"
                  tag="a" :href="getFile(file)" :download="`${file.file_name_origin}`" target="_blank"
                  rel="noopener noreferrer"
              >
                <v-list-item-action class="my-1 mr-3">
                  <v-icon class="mr-1" size="18">
                    {{ getFileIcon(file.file_ext) }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>{{ file.file_name_origin }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

            <v-card-text
                class="d-flex align-center justify-center pb-1"
                style="flex: 1">
              <template v-if="doc.is_group">
                <v-badge
                    color="success"
                    :content="doc.items || 0"
                    offset-y="26"
                    v-if="doc.items"
                >
                  <v-icon size="100" color="secondary">mdi-folder</v-icon>
                </v-badge>
                <v-icon v-else size="100" color="secondary">mdi-folder</v-icon>
              </template>
              <v-icon v-else size="70" color="success" class="mb-3">mdi-file-document-outline</v-icon>
            </v-card-text>
          <v-card-title class="d-flex align-center justify-center pt-3" style="font-size: 1.3rem" v-if="doc.is_group">
            {{ doc.text }}
          </v-card-title>
          <v-card-title
              v-else
              class="d-flex align-center justify-center pt-3 pb-0 grey lighten-4"
              style="font-size: .78rem; line-height: .96rem; height: 40px; overflow: hidden">
            {{ doc.document_name || doc.text }}
          </v-card-title>
          <v-card-title v-if="!doc.is_group" style="height: 6px" class="pt-0 grey lighten-4"></v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import organizationDocumentsAPI from "@/utils/axios/organization_douments"
import siteAPI from "@/utils/axios/personalSite/site"

import {BASE_URL_AXIOS} from "@/utils/axios";
import {ext_obj} from "@/utils/icons";

export default {
  name: "OrganizationDocumentsNew",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    site: {
      type: Boolean,
      default: false
    },
  },
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  data() {
    return {
      current_story_id: null,
      documents: [],
      story: [],
      editObject: {},
      showGroupModal: false,
      showDocumentModal: false
    }
  },
  methods: {
    copyObject(obj) {
      return obj.map(i => {
        return {...i, delete: false}
      })
    },
    onItemEdit(payload) {
      if (payload.is_group) {
        const parent_id = payload.parent_id || this.current_story_id
        const obj = {
          id: payload.group_id,
          name: payload.text,
          parent_id: parent_id === 0 ? null : parent_id
        }
        this.editObject = Object.assign({}, obj)
        this.showGroupModal = true
      } else {
        const obj = {
          id: payload.document_id,
          document_type_id: payload.document_type_id,
          document_group_type_id: payload.group_id,
          document_date: payload.document_date,
          document_number: payload.document_number,
          comment: payload.document_comment,
          document_name: payload.document_name,
          files: this.copyObject(payload.files)
        }
        this.editObject = Object.assign({}, obj)
        this.showDocumentModal = true
      }
    },
    getFile(payload) {
      if (!payload.file_path) return `${this.publicPath}img/icons/no_image.png`
      return `${BASE_URL_AXIOS}/${payload.file_path}`
    },
    getFileIcon(ext) {
      const file_ext = (ext_obj.find(item_ext => item_ext.ext === ext)) || {ext: 'txt', mime: ''}
      return file_ext.icon || 'mdi-document'
    },
    afterModalClose() {
      this.editObject = Object.assign({},
          {document_group_type_id: this.current_story_id, parent_id: this.current_story_id})
      this.showDocumentModal = false
      this.showGroupModal = false
      this.fetch(this.current_story_id)
    },
    onItemClick(doc) {
      if (!doc.is_group) return

      this.story.push(
          {
            text: doc.text,
            id: doc.group_id,
          }
      )
      this.current_story_id = doc.group_id
      this.editObject = Object.assign({},
          {document_group_type_id: this.current_story_id, parent_id: this.current_story_id})
      this.fetch(doc.group_id)
    },
    onStoryClick(payload) {
      if (payload.id === this.current_story_id) return

      const idx = this.story.indexOf(payload)
      this.story.splice(idx + 1)

      this.current_story_id = payload.id
      this.editObject = Object.assign({},
          {document_group_type_id: this.current_story_id, parent_id: this.current_story_id})
      this.fetch(payload.id)
    },
    fetch(item_id=null) {
      if (this.site) {
        this.fetchDataBySlug(item_id)
      } else {
        this.fetchData(item_id)
      }
    },
    fetchData(item_id=null) {
      const parent_id = item_id || null
      organizationDocumentsAPI.get_documents({parent_id: parent_id})
        .then(response => response.data)
        .then(data => {
         this.documents = data.map(item => {
           return Object.assign({}, item, {show_files: false})
         })

          if (!this.story.length) {
            this.story.push(
                {
                  text: 'Усі документи',
                  id: 0,
                }
            )

          }
        })
    },
    fetchDataBySlug(item_id=null) {
      const parent_id = item_id || null

      const organization_slug = this.$route.params.organization || ''
      if (organization_slug) {
        siteAPI.get_documents_new({parent_id: parent_id, organization_slug: organization_slug})
            .then(response => response.data)
            .then(data => {
              this.documents = data.map(item => {
                return Object.assign({}, item, {show_files: false})
              })

              if (!this.story.length) {
                this.story.push(
                    {
                      text: 'Усі документи',
                      id: 0,
                    }
                )

              }
            })
      }
    },
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped lang="scss">
  .dotted-button {
    position: absolute;
    right: 12px;
    top: 4px;
  }
  .download-button {
    position: absolute;
    right: 12px;
    top: 50px;
  }
</style>