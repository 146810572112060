<template>
  <v-card
      min-height="280"
      :height="height"
      :style="textCard ? '' : 'border-left: 3px solid #5bb55f'"
      id="card-table"
      :tile="textCard"
  >
    <MainModal
        :main="{ component: 'FlatService', title: 'Послуга' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_flat_service)"
        :modal="show_flat_service_dialog"
        :nonClickable="nonClickable"
        @updateItemModal="flatServiceUpdateItemModal"
    />
    <v-card-text class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <YouTubeButton
            button-class="grey lighten-4 mr-1"
            icon-color="error lighten-1"
            :icon-size="26"
            v-if="!readOnly"
            href="https://youtu.be/iYg4Qil6Fbw"
        />
        <v-tooltip bottom color="success" v-if="!readOnly">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openFlatServiceCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити нову послугу</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-text>
    <v-data-table
        :headers="short_form ? accountingHeadersShort : accountingHeaders"
        :items="getFlatServices"
        hide-default-footer
        class="custom-table mb-4"
        :class="bigger ? 'custom-table-bigger' : ''"
        id="account-table"
        @click:row="onFlatServiceItemClick"
        v-if="$vuetify.breakpoint.mdAndUp"
    >
      <template v-slot:item.icon="{ item }">
        <div class="d-flex flex-nowrap no-padding">
          <v-icon :color="getIcon(item.icon, 'color')" size="26" class="mr-2">
            {{ getIcon(item.icon, 'icon') }}
          </v-icon>
          <v-icon size="20" v-if="!item.id">
            mdi-circle-medium
          </v-icon>
        </div>
      </template>
      <template v-slot:item.service_name="{ item }">
        <span
            :class="[
                      {'error--text': !item.id},
                      {'secondary--text': item.by_season}]"
        >
          {{ item.service_name }}
        </span>
      </template>
      <template v-slot:item.volume="{ item }">
        <span v-if="(item.volume - item.volume_normative) === item.volume">
          {{ item.volume }}
        </span>
        <span v-else-if="(item.volume - item.volume_normative) === 0">
          {{ item.volume }}
        </span>
        <template v-else>
          <span>
            {{ item.volume }}
          </span>
          <span class="error--text">
            {{ ` (${(item.volume - item.volume_normative).toFixed(0)} / ${item.volume_normative})` }}
          </span>
        </template>
      </template>
      <template v-slot:item.tariff="{ item }">
        <span class="font-weight-medium">{{ item.tariff | formatNumber('0.0000') }}</span>
      </template>
      <template v-slot:item.start_balance="{ item }">
        <span
            class="font-weight-medium"
            :class="[
                {'error--text': item.start_balance > 0},
                {'success--text': item.start_balance < 0}
            ]"
        >{{ item.start_balance | formatToFixed(2) }}</span>
      </template>
      <template v-slot:item.end_balance="{ item }">
        <span
            class="font-weight-medium"
            :class="[
                {'error--text': item.end_balance > 0},
                {'success--text': item.end_balance < 0}
            ]"
        >
          {{ item.end_balance | formatNumber }}
        </span>
      </template>
      <template v-slot:item.charge="{ item }">
        {{ item.charge | formatToFixed(2) }}
      </template>
      <template v-slot:item.privilege="{ item }">
        {{ item.privilege | formatToFixed(2) | formatNumber }}
      </template>
      <template v-slot:item.recalculation="{ item }">
        {{ item.recalculation | formatToFixed(2) }}
      </template>
      <template v-slot:item.pay="{ item }">
        {{ item.pay | formatToFixed(2) }}
      </template>
      <template v-slot:item.subs="{ item }">
        {{ item.subs | formatToFixed(2) }}
      </template>
      <template v-slot:item.monetary="{ item }">
        {{ item.monetary | formatToFixed(2) }}
      </template>

      <template v-slot:body.append="props">
        <template v-if="props.isMobile">
          <tr :class="{ 'v-data-table__mobile-table-row': props.isMobile }">
            <td
                class="text-right font-weight-bold text-uppercase"
                :class="{ 'v-data-table__mobile-row': props.isMobile }"
            >
              <div class="v-data-table__mobile-row__header">
                ВСЬОГО:
              </div>
              <div class="v-data-table__mobile-row__cell">

              </div>
            </td>
            <td
                v-for="(col, i) in props.headers.slice(4)"
                :key="i"
                class="font-weight-bold"
                :class="{ 'v-data-table__mobile-row': props.isMobile }"
            >
              <div class="v-data-table__mobile-row__header">
                {{ col.text }}
              </div>
              <div class="v-data-table__mobile-row__cell">
                {{ getTotal(col.value) }}
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr :class="{ 'v-data-table__mobile-table-row': props.isMobile }" v-if="getFlatServices.length">
            <td
                colspan="4"
                class="text-right font-weight-medium text-uppercase"
                :class="{ 'v-data-table__mobile-row': props.isMobile }"
            >
              Всього:
            </td>
            <td
                v-for="(col, i) in props.headers.slice(4)"
                :key="i"
                class="font-weight-bold text-right"
                :class="[
                {'error--text': getTotal(col.value) > 0 && (col.value === 'start_balance' || col.value === 'end_balance')},
                {'success--text': getTotal(col.value) < 0 && (col.value === 'start_balance' || col.value === 'end_balance')},
                { 'v-data-table__mobile-row': props.isMobile }
            ]"
            >
              {{ getTotal(col.value) }}
            </td>
          </tr>
        </template>
      </template>
    </v-data-table>
    <template v-if="$vuetify.breakpoint.smAndDown">
      <div class="mobile-table">
        <div class="mobile-table-body">
          <div v-for="(item, idx) in getFlatServices"
               :key="`mb-row-${idx}`"
               class="mobile-table-row"
               :style="idx === 0 ? 'padding-top: 0px !important;' : ''"
          >
            <div class="mobile-table-title-row elevation-2">
              <v-icon :color="getIcon(item.icon, 'color')" size="26" class="mr-2">
                {{ getIcon(item.icon, 'icon') }}
              </v-icon>
              <span :class="[
                      {'error--text': !item.id},
                      {'secondary--text': item.by_season}]">
                {{ item.service_name }}
              </span>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                {{ volume_column_name }}
              </div>
              <div class="mobile-right-row-side">
                <span v-if="(item.volume - item.volume_normative) === item.volume">
                  {{ item.volume }}
                </span>
                <span v-else-if="(item.volume - item.volume_normative) === 0">
                  {{ item.volume }}
                </span>
                <template v-else>
                  {{ (item.volume - item.volume_normative) }}
                  <span>{{ item.volume }}</span>
                  <span class="error--text">
                    {{ ` (${(item.volume - item.volume_normative).toFixed(0)})` }}
                  </span>
                </template>
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Тариф
              </div>
              <div class="mobile-right-row-side">
                <span class="font-weight-medium">{{ item.tariff | formatNumber('0.0000') }}</span>
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Сальдо початкове
              </div>
              <div class="mobile-right-row-side">
                <span
                    class="font-weight-medium"
                    :class="[
                      {'error--text': item.start_balance > 0},
                      {'success--text': item.start_balance < 0}]"
                >
                  {{ item.start_balance | formatToFixed(2) }}
                </span>
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Нараховано
              </div>
              <div class="mobile-right-row-side">
                {{ item.charge | formatToFixed(2) }}
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Перерахунок
              </div>
              <div class="mobile-right-row-side">
                {{ item.recalculation | formatToFixed(2) }}
              </div>
            </div>

            <div class="mobile-table-col" v-if="!short_form">
              <div class="mobile-left-row-side">
                Пільга
              </div>
              <div class="mobile-right-row-side">
                {{ item.privilege | formatToFixed(2) }}
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Оплачено
              </div>
              <div class="mobile-right-row-side">
                {{ item.pay | formatToFixed(2) }}
              </div>
            </div>

            <div class="mobile-table-col" v-if="!short_form">
              <div class="mobile-left-row-side">
                Монетизація
              </div>
              <div class="mobile-right-row-side">
                {{ item.monetary | formatToFixed(2) }}
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Сальдо кінцеве
              </div>
              <div class="mobile-right-row-side">
                <span
                    class="font-weight-medium"
                    :class="[
                      {'error--text': item.end_balance > 0},
                      {'success--text': item.end_balance < 0}]"
                >
                  {{ item.end_balance | formatToFixed(2) }}
                </span>
              </div>
            </div>


          </div>
          <div class="mobile-table-row">
            <div class="mobile-table-title-row elevation-2">
              <v-icon color="teal" size="26" class="mr-2">
                mdi-function-variant
              </v-icon>
              <span>
                {{ `Всього до оплати: ${getTotal('end_balance')} грн.` }}
              </span>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Сальдо початкове
              </div>
              <div class="mobile-right-row-side">
                <span
                    class="font-weight-medium"
                    :class="[
                      {'error--text': getTotal('start_balance', true)  > 0},
                      {'success--text': getTotal('start_balance', true) < 0}]"
                >
                  {{ getTotal('start_balance') | formatNumber }}
                </span>
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Нараховано
              </div>
              <div class="mobile-right-row-side">
                {{ getTotal('charge') | formatNumber }}
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Перерахунок
              </div>
              <div class="mobile-right-row-side">
                {{ getTotal('recalculation') | formatNumber }}
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Пільга
              </div>
              <div class="mobile-right-row-side">
                {{ getTotal('privilege') | formatNumber }}
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Оплачено
              </div>
              <div class="mobile-right-row-side">
                {{ getTotal('pay') | formatNumber }}
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Монетизація
              </div>
              <div class="mobile-right-row-side">
                {{ getTotal('monetary') | formatNumber }}
              </div>
            </div>

            <div class="mobile-table-col">
              <div class="mobile-left-row-side">
                Сальдо кінцеве
              </div>
              <div class="mobile-right-row-side">
                <span
                    class="font-weight-medium"
                    :class="[
                      {'error--text': getTotal('end_balance', true) > 0},
                      {'success--text': getTotal('end_balance', true) < 0}]"
                >
                  {{ getTotal('end_balance') | formatNumber }}
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </template>
  </v-card>
</template>

<script>
import {accountIcons} from "@/utils/icons"
import MainModal from "@/components/modal/MainModal";
import {mapGetters, mapActions} from 'vuex'
import {GET_ALL_WITH_BALANCE} from "@/store/actions/flat_service";

export default {
  name: "HWP_Accounts",
  props: {
    nonClickable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Поточний стан розрахунку по абоненту'
    },
    person_hash: {
      type: String,
      default: ''
    },
    bigger: {
      type: Boolean,
      default: false
    },
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    },
    short_form: {
      type: Boolean,
      default: false
    },
    volume_column_name: {
      type: String,
      default: 'Об’єм'
    },
  },
  components: {
    MainModal,
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  computed: {
    ...mapGetters({
      getFlatServices: 'getFlatServiceWithBalance',
      current_month: 'getCurrentMonth'
    })
  },
  data() {
    return {
      accountingHeaders: [
        {text: '', value: 'icon', width: 26},
        {text: 'Послуга', value: 'service_name'},
        {text: this.volume_column_name, value: 'volume', align: 'end'},
        {text: 'Тариф', value: 'tariff', align: 'end'},
        {text: `Сальдо поч.`, value: 'start_balance', align: 'end'},
        {text: 'Нараховано', value: 'charge', align: 'end'},
        {text: 'Перерах.', value: 'recalculation', align: 'end'},
        {text: 'Пільга', value: 'privilege', align: 'end'},
        {text: 'Оплата', value: 'pay', align: 'end'},
        {text: 'Монетизація', value: 'monetary', align: 'end'},
        {text: 'Сальдо кін.', value: 'end_balance', align: 'end'},
      ],
      accountingHeadersShort: [
        {text: '', value: 'icon', width: 26},
        {text: 'Послуга', value: 'service_name'},
        {text: this.volume_column_name, value: 'volume', align: 'end'},
        {text: 'Тариф', value: 'tariff', align: 'end'},
        {text: `Сальдо поч.`, value: 'start_balance', align: 'end'},
        {text: 'Нараховано', value: 'charge', align: 'end'},
        {text: 'Перерах.', value: 'recalculation', align: 'end'},
        {text: 'Оплата', value: 'pay', align: 'end'},
        {text: 'Сальдо кін.', value: 'end_balance', align: 'end'},
      ],
      selected_flat_service: {},
      show_flat_service_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetchFlatServices: GET_ALL_WITH_BALANCE
    }),
    getIcon(name, type) {
      const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
      const value = accountIcons[name] || defaultIcon
      return value[type]
    },
    getTotal(column, return_number=false) {
      let value = this.getFlatServices.reduce((total, current) => {
        return total += current[column]
      }, 0)
      if (!return_number) {
        return value.toFixed(2)
      } else {
        return value
      }
    },
    flatServiceUpdateItemModal(payload) {
      this.show_flat_service_dialog = false
      this.selected_flat_service = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openFlatServiceCreateDialog() {
      this.selected_flat_service = {
        person_hash: this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash,
        date_start: this.current_month
      }
      this.show_flat_service_dialog = true
    },
    onFlatServiceItemClick(payload) {
      if (this.nonClickable || !payload.id) {
        this.show_flat_service_dialog = false
        this.selected_flat_service = {}
        return
      }

      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
      local_payload.update = true
      this.selected_flat_service = local_payload
      this.show_flat_service_dialog = true
    },
  },
  mounted() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetchFlatServices(person_hash)
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetchFlatServices(payload)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media only screen and (min-width: 600px) and (max-width: 1152px) {
  #card-table {
    border-left: none !important;
  }

  #account-table {
    :deep(.v-data-table__wrapper) {
      tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        //padding: 10px 0;
        align-items: center;
        vertical-align: center;
        border: 1px solid #5bb55f;
        border-left: 3px solid #fafafa;
        background: rgba(250, 250, 250, 0.83);


        td, th {
          vertical-align: middle;
          flex-basis: 25%;
          display: flex;
          align-items: center;
          position: relative;
          font-size: 15px;
          background: #ffffff;
          text-align: left;

          &:before {
            display: block;
            font-weight: 500;
            font-size: 13px;
            line-height: 29px;
            color: #272727;
            width: 50%;
            text-align: left;
          }

          span {
            display: block;
            width: 50%;
            text-align: left;
            justify-content: flex-start;
          }
        }

        td:nth-child(1), th:nth-child(1) {
          //Іконка
          flex: 0 0 60px;
          padding: 5px;

          i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        td:nth-child(2), th:nth-child(2) {
          //Послуга
          flex: 1 1 calc(98% - 60px);
          padding: 5px;
        }

        td:nth-child(3), th:nth-child(3) {
          flex: 1 1 48%;
          border-right: .5px solid lightgray;

          &:before {
            content: 'Об’єм';
          }
        }

        td:nth-child(4), th:nth-child(4) {
          flex: 1 1 48%;

          &:before {
            content: 'Тариф';
          }
        }

        td:nth-child(5), th:nth-child(5) {
          flex: 1 1 50%;
          border-right: .5px solid lightgray;

          &:before {
            content: 'Сальдо на поч.';
          }
        }

        td:nth-child(6), th:nth-child(6) {
          flex: 1 1 50%;

          &:before {
            content: 'Нараховано';
          }
        }

        td:nth-child(7), th:nth-child(7) {
          flex: 1 1 50%;
          border-right: .5px solid lightgray;

          &:before {
            content: 'Перерахунок';
          }
        }

        td:nth-child(8), th:nth-child(8) {
          flex: 1 1 50%;

          &:before {
            content: 'Пільга';
          }
        }

        td:nth-child(9), th:nth-child(9) {
          flex: 1 1 50%;
          border-right: .5px solid lightgray;

          &:before {
            content: 'Оплата';
          }
        }

        td:nth-child(10), th:nth-child(10) {
          flex: 1 1 50%;

          &:before {
            content: 'Субсидія';
          }
        }

        td:nth-last-child(1) {
          flex: 0 0 50%;
          border-right: .5px solid lightgray;

          &:before {
            content: 'Сальдо на кін.';
          }
        }

        &:last-child {
          display: block !important;
          position: relative;
          padding: 0;
          border-right: none;
          border-top: none;
          border-bottom: none;

          td {
            display: flex;
            padding: 0 !important;
            padding-left: 12px !important;
            margin: 4px 0;
            border: none;
            background-color: #f5f5f5;
            padding-right: 14px !important;
            box-shadow: none !important;

            &:first-child {
              background-color: #929292;
              color: #fafafa;
              text-align: left !important;
              margin: 0;
              line-height: 38px;
              font-size: 1.2rem;
              padding-left: 10px !important;
            }

            &:before {
              display: inline-block !important;
              left: 0;
              top: 0;
              box-shadow: none !important;
              //position: relative !important;
              width: 75%;
              //height: 100%;
              border-radius: 0;
              line-height: 38px;
              text-align: left;
              margin-right: 20px;
              font-size: 1rem;
            }

            &:nth-child(2) {
              &:before {
                content: 'Сальдо на початок';
              }
            }

            &:nth-child(3) {
              &:before {
                content: 'Нараховано';
              }
            }

            &:nth-child(4) {
              &:before {
                content: 'Перерахунок';
              }
            }

            &:nth-child(5) {
              &:before {
                content: 'Пільга';
              }
            }

            &:nth-child(6) {
              &:before {
                content: 'Оплата';
              }
            }

            &:nth-child(7) {
              &:before {
                content: 'Субсидія';
              }
            }

            &:nth-child(8) {
              margin-bottom: 0 !important;

              &:before {
                content: 'Сальдо на кінець';
              }
            }
          }
        }
      }

      thead {
        display: none;
      }
    }
  }
}

.custom-table-bigger {
  :deep(.v-data-table__wrapper) {
    tbody {
      tr {
        td {
          height: 40px !important;
        }
      }
    }

    thead {
      tr {
        th {
          height: 46px !important;
        }
      }
    }
  }
}

.mobile-table {
  .mobile-table-menu {
  }

  .mobile-table-body {
    .mobile-table-row {
      padding: 16px 10px;

      .mobile-table-title-row {
        margin-bottom: 10px;
        padding: 10px 7px 10px 10px;
        background: #f6f6f6;
        color: #606060;
        font-weight: 500;
      }

      .mobile-table-col {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 10px;
        margin-left: 10px;

        .mobile-left-row-side {
          flex: 1;
          margin-right: 10px;
          padding: 7px;
          background: #eeeeee;
          color: #606060;
          font-weight: 500;
        }

        .mobile-right-row-side {
          flex: 0 0 180px;
          width: 180px;
          padding: 7px 16px 7px 7px;
          background-color: #f5f5f5;
          text-align: right;
        }
      }
    }

  }

  .mobile-table-footer {
  }
}
</style>
