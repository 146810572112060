<template>
  <div style="height: 100vh; ">
    <div style="height: 100%" :class="personal_cab ? 'pt-4 px-1' : 'px-2'">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" md="7">
          <v-row class="fill-height" style="display: flex; flex-wrap: nowrap; flex-direction: column">
            <v-col cols="12" style="flex: 0">
              <v-toolbar :elevation="personal_cab ? 3 : 1" class="non-printable" style="position: relative;">
                <v-toolbar-title>Дані користувача</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon
                       class="grey lighten-3"
                       color="grey darken-1"
                       :disabled="!formValid"
                       @click="crudUser"
                >
                  <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
            <v-col cols="12" style="flex: 1">
              <v-card :elevation="personal_cab ? 3 : 1">
                <v-card-text>
                  <v-form
                      v-model="formValid" ref="form"
                      autocomplite="off"
                  >
                    <v-row>
                      <v-col cols="12" class="d-flex pb-0">
                        <v-text-field type="text"
                                      filled
                                      label="П.І.Б"
                                      v-model="user.name"
                                      :rules="[rules.required]"
                                      class="mr-2"
                                      color="grey"
                        />
                        <v-checkbox
                            hide-details
                            color="secondary"
                            disabled
                            class="mt-3"
                            v-model="user.is_active"
                            :label="user.is_active ? 'Активний' : 'Відключений'"
                        />
                      </v-col>
                      <v-col cols="12" class="pb-0 pt-1">
                        <v-text-field type="text"
                                      filled
                                      label="email"
                                      v-model="user.email"
                                      required
                                      :rules="[rules.required, rules.email]"
                                      color="grey"
                        />
                      </v-col>
                      <v-col cols="12" class="pb-3 pt-1" v-if="!personal_cab">
                        <AC_Position :value="user.position_id"
                                     @autocompleteChange="positionChange"
                                     color="grey"
                                     v-if="!personal"
                        />
                      </v-col>
                      <v-col cols="12" class="pb-3 pt-1" v-if="!personal_cab">
                        <Checker :value="user.checker_id"
                                     @autocompleteChange="checkerChange"
                                     color="grey"
                                  v-if="!personal"
                        />
                      </v-col>
                      <v-col cols="12" class="pb-3 pt-1" v-if="!personal_cab">
                        <AppealReceiverGroup :value="user.appeal_receiver_group_id"
                                 @autocompleteChange="appealReceiverGroupChange"
                                 color="grey"
                                 v-if="!personal"
                        />
                      </v-col>
                      <v-card-text class="px-3 pb-0" v-if="!user.new">
                        <v-checkbox
                            class="mt-0"
                            hide-details
                            color="secondary"
                            v-model="user.change_password"
                            :label="user.change_password ? 'Змінити пароль (зніміть позначку аби не змінювати пароль)' : 'Не змінювати пароль (встановіть позначку аби змінити пароль)'"
                        />
                      </v-card-text>
                      <template v-if="user.change_password || user.new">
                        <v-col cols="12" class="pb-0 pt-3">
                          <v-text-field role="presentation"
                                        filled
                                        label="Новий пароль"
                                        autocomplete="none"
                                        :type="show1 ? 'text' : 'password'"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        v-model="user.password_new"
                                        required
                                        :rules="[rules.required, rules.min_counter]"
                                        color="grey"
                                        @click:append="show1 = !show1"
                          />
                        </v-col>
                        <v-col cols="12" class="pb-0 pt-1">
                          <v-text-field filled
                                        :type="show2 ? 'text' : 'password'"
                                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                        autocomplete="none"
                                        label="Новий пароль (повторно)"
                                        v-model="user.password_new_again"
                                        required
                                        :rules="[rules.required, rules.min_counter, passwordConfirmationRule]"
                                        color="grey"
                                        @click:append="show2 = !show2"
                          />
                        </v-col>
                      </template>
                      <v-col cols="12">
                        <v-chip-group>
                          <v-chip label color="grey lighten-2" v-for="(item, idx) in user.roles" :key="idx">
                            {{ item.name }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {USER_PROFILE_UPDATE} from "@/store/actions/user";
import {ALERT_SHOW} from "@/store/actions/alert";
import userAPI from "@/utils/axios/user"

export default {
  name: "Profile",
  props: ['personal_cab'],
  components: {
    AC_Position: () => import("@/components/autocomplite/AC_Position"),
    Checker: () => import("@/components/autocomplite/Checker"),
    AppealReceiverGroup: () => import("@/components/autocomplite/AppealReceiverGroup"),
  },
  computed: {
    ...mapGetters(
        {
          profile: 'getProfile',
          personal: 'getPersonalCabinetHome'
        }
    ),
    passwordConfirmationRule() {
      return this.user.password_new === this.user.password_new_again || 'Паролі не співвпадають'
    }
  },
  data() {
    return {
      show1: false,
      show2: false,
      user: {
        new: false,
        id: null,
        name: '',
        email: '',
        position: null,
        password_new: '',
        password_new_again: '',
        change_password: false,
        checker_id: null,
        appeal_receiver_group_id: null,
        is_active: false,
        roles: [],
        accepted: false
      },
      roles: [],
      selectedUser: null,
      formValid: false,
      rules: {
        required: value => !!value || 'Це поле обов\'язкове',
        min_counter: value => value.length >= 4 || 'Мінімум 4 символи',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неправильний email'
        },
      },
    }
  },
  methods: {
    fillUserData(payload) {
      this.user.new = false
      this.user.name = payload.name
      this.user.email = payload.email
      this.user.position = payload.position
      this.user.position_id = payload.position_id
      this.user.change_password = false
      this.user.roles = payload.roles
      this.user.password_new = ''
      this.user.password_new_again = ''
      this.user.id = payload.id
      this.user.is_active = payload.is_active
      this.user.accepted = payload.accepted
      this.user.checker_id = payload.checker_id
      this.user.appeal_receiver_group_id = payload.appeal_receiver_group_id
    },
    getBasePath() {
      return window.location.origin
    },
    positionChange(payload) {
      this.user.position_id = payload.value || null
    },
    checkerChange(payload) {
      this.user.checker_id = payload.value || null
    },
    appealReceiverGroupChange(payload) {
      this.user.appeal_receiver_group_id = payload.value || null
    },
    crudUser() {
      let OPERATION = null
      let ALERT_TEXT = null

      const payload = {
        name: this.user.name,
        email: this.user.email,
        position_id: this.user.position_id,
        roles: null,
        is_active: false,
        user_url: this.getBasePath(),
        accepted: this.user.accepted,
        checker_id: this.user.checker_id,
        appeal_receiver_group_id: this.user.appeal_receiver_group_id
      }


      payload.id = this.user.id
      if (this.user.change_password) {
        payload.password = this.user.password_new
      }
      OPERATION = USER_PROFILE_UPDATE
      ALERT_TEXT = 'Дані користувача було змінено успішно'


      this.$store.dispatch(OPERATION, payload)
        .then(response => response.data)
        .then(() => {
          this.$store.dispatch(ALERT_SHOW,{ text: ALERT_TEXT, color: 'success' })
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    }
  },
  created() {
    userAPI.get_profile()
      .then(response => response.data)
      .then(data => {
        this.fillUserData(data)
      })
      .catch(err => {
        const error = err.response.data.detail;
        this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
      })
  }
}
</script>

<style scoped lang="scss">
  :deep(.v-text-field__details) {
    margin-bottom: 0 !important;
  }
</style>