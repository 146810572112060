<template>
  <v-card
      :tile="textCard"
      elevation="1"
      :min-height="personal ? 180 : 280"
      :height="$vuetify.breakpoint.mdAndUp ? height : 'auto'"
      :style="textCard ? '' : 'border-left: 3px solid #5bb55f'"
      class="pay-table-wrapper"
      :disabled="disabled"
  >
    <MainModal
        :main="{ component: 'FlatCounter', title: 'Прилади обліку' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_flat_counter)"
        :modal="show_flat_counter_dialog"
        @updateItemModal="flatCounterUpdateItemModal"
    />
    <v-toolbar elevation="0" class="pa-0" v-if="!personal && $vuetify.breakpoint.mdAndUp">
      <v-toolbar-title class="toolbar-work-place-title">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch class="mr-3 pt-5" color="success"
                v-model="show_actual"
                :label="show_actual ? 'Прилади обліку (актуальні)': 'Прилади обліку (усі)'"
                @change="onActualChange"
      />
      <YouTubeButton
          button-class="grey lighten-4 mr-1"
          icon-color="error lighten-1"
          :icon-size="26"
          href="https://youtu.be/gXil2Rt-6GE"
      />
      <v-tooltip bottom color="success" v-if="!readOnly && !personal && !organization.cabinet_only">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="openFlatCounterCreateDialog"
                 v-bind="attrs"
                 v-on="on"
                 class="grey lighten-4 mr-1">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Створити новий прилад обліку/лічильник</span>
      </v-tooltip>
    </v-toolbar>
    <v-toolbar elevation="0" class="pa-0" v-if="!personal && $vuetify.breakpoint.smAndDown">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <YouTubeButton
          button-class="grey lighten-4 mr-1"
          icon-color="error lighten-1"
          :icon-size="26"
          href="https://youtu.be/gXil2Rt-6GE"
      />
      <v-tooltip bottom color="success" v-if="!readOnly && !personal && !organization.cabinet_only">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="openFlatCounterCreateDialog"
                 v-bind="attrs"
                 v-on="on"
                 class="grey lighten-4 mr-1">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Створити новий прилад обліку/лічильник</span>
      </v-tooltip>
    </v-toolbar>
    <v-switch class="mx-2 mt-1 mb-2 "
              hide-details
              color="success" v-model="show_actual"
              v-if="!personal && $vuetify.breakpoint.smAndDown"
              :label="show_actual ? 'Прилади обліку (актуальні)': 'Прилади обліку (усі)'"/>
    <div class="pay-table"
         :class="{
                  'sm-table': $vuetify.breakpoint.sm,
                  'xs-table': $vuetify.breakpoint.xs
         }"
    >
      <div class="pay-table-header">
        <div class="pay-table-row">
          <div class="pay-table-col"></div>
          <div class="pay-table-col"></div>
          <div class="pay-table-col">
            Серійний номер
          </div>
          <div class="pay-table-col">
            Модель
          </div>
          <div class="pay-table-col">
            Встановлено
          </div>
          <div class="pay-table-col">
            Знято
          </div>
          <div class="pay-table-col">
            Поп.повірка
          </div>
          <div class="pay-table-col">
            Наст.повірка
          </div>
          <div class="pay-table-col">
            Зона
          </div>
          <div class="pay-table-col">
            Початковий
          </div>
          <div class="pay-table-col">
            Поточний
          </div>
          <div class="pay-table-col">
            Статус
          </div>
          <div class="pay-table-col"></div>
        </div>
      </div>
      <div class="pay-table-body">
        <div
            class="pay-table-row"
            :class="{'expandable': item.zone > 0,
                     'expanded': item.row_num === expand_row_num}"
            @click.stop="onFlatCounterItemClick(item)"
            :style="!personal ? 'cursor: pointer' : ''"
            v-for="(item, index) in items" :key="`item-${item.row_num}-${index}`">
          <template v-if="!item.zone">
            <div class="pay-table-col" style="padding-left: 10px !important;">
              <v-checkbox
                  class="select-checkbox"
                  color="success"
                  hide-details :ripple="false"
                  @click.stop
                  :value="selected_row === item.id"
                  @change="changeSelect(item.id)"
              />
            </div>
            <div class="pay-table-col">
              <v-icon :color="getAccountIcon(item.icon, 'color')" size="24" class="mr-2">
                {{ getAccountIcon(item.icon, 'icon') }}
              </v-icon>
            </div>
            <div class="pay-table-col" v-if="$vuetify.breakpoint.smAndDown">
              {{ item.service_name }}
            </div>
            <div class="pay-table-col"
                 :class="{'font-weight-bold': item.counter_minus_id || item.counter_minus_from_id}"
            >
              <template v-if="item.counter_minus_id">
                <v-icon v-if="item.counter_minus_id" size="16" color="teal" class="mr-1">
                  mdi-circle-double
                </v-icon>
              </template>

              <template v-if="item.counter_minus_from_id">
                <v-icon v-if="item.counter_minus_from_id" size="16" color="error" class="mr-1">
                  mdi-circle-double
                </v-icon>
              </template>

              {{ item.serial_number }}
            </div>
            <div class="pay-table-col">
              {{ (item.model_name || '').substring(0, 15) }}
            </div>
            <div class="pay-table-col">
              {{ item.date_start | formatDate }}
            </div>
            <div class="pay-table-col">
              {{ item.date_end | formatDate }}
            </div>
            <div class="pay-table-col">
              {{ item.prev_verification | formatDate }}
            </div>
            <div class="pay-table-col">
              {{ item.next_verification | formatDate }}
            </div>
            <div class="pay-table-col" style="display: flex">
              <v-icon style="width: 28px" size="24" class="mr-3">
                mdi-checkbox-blank-circle-outline
              </v-icon>
              <div style="line-height: 25px; max-width: 220px">
                <v-chip small class="cs-chip" style="display: flex !important;">
                  Без зони
                </v-chip>
              </div>
            </div>
            <div class="pay-table-col">
              {{ item.start_meter_reading_first }}
            </div>
            <div class="pay-table-col">
              {{ item.current_meter_reading_first }}
            </div>
            <div class="pay-table-col">
              {{ getVerificationType(item.last_operation_type) }}
            </div>
            <div class="pay-table-col"></div>
          </template>
          <template v-else>
            <div class="pay-table-row-expand-header" :class="{'expanded': item.row_num === expand_row_num}">
              <div class="pay-table-col" style="padding-left: 10px !important;">
                <v-checkbox class="select-checkbox"
                            color="success"
                            hide-details
                            :ripple="false"
                            :value="selected_row === item.id"
                            @click.stop
                            @change="changeSelect(item.id)"/>
              </div>
              <div class="pay-table-col">
                <v-icon :color="getAccountIcon(item.icon, 'color')" size="24" class="mr-2">
                  {{ getAccountIcon(item.icon, 'icon') }}
                </v-icon>
              </div>
              <div class="pay-table-col">
                {{ item.serial_number }}
              </div>
              <div class="pay-table-col">
                {{ (item.model_name || '').substr(0, 30) }}
              </div>
              <div class="pay-table-col">
                {{ item.date_start | formatDate }}
              </div>
              <div class="pay-table-col">
                {{ item.date_end | formatDate }}
              </div>
              <div class="pay-table-col">
                {{ item.prev_verification | formatDate }}
              </div>
              <div class="pay-table-col">
                {{ item.next_verification | formatDate }}
              </div>
              <div class="pay-table-col" style="display: flex">
                <v-btn icon small class="mr-3" @click.stop="expandRow(item.row_num)">
                  <v-icon color="grey darken-1">
                    {{
                      item.row_num === expand_row_num ? 'mdi-arrow-up-bold-circle-outline' : 'mdi-arrow-down-bold-circle-outline'
                    }}
                  </v-icon>
                </v-btn>
                <div style="line-height: 25px; max-width: 220px">
                  <v-chip small class="cs-chip" style="display: flex !important; margin-top: 2px" color="success">
                    День
                  </v-chip>
                </div>
              </div>
              <div class="pay-table-col">
                {{ item.start_meter_reading_first }}
              </div>
              <div class="pay-table-col">
                {{ item.current_meter_reading_first }}
              </div>
              <div class="pay-table-col" style="min-height: 33px !important; padding-top: 6px !important;">
                {{ getVerificationType(item.last_operation_type) }}
              </div>
              <div class="pay-table-col" style="min-height: 33px !important;"></div>
            </div>
            <div class="pay-table-row-expand-body" :class="{'expanded': item.row_num === expand_row_num}">
              <template v-if="item.zone === 2">
                <div class="pay-table-expand-body-row">
                  <div class="pay-table-col"></div>
                  <div class="pay-table-col" style="display: flex">
                    <v-icon style="width: 28px" color="grey darken-1" size="20"
                            class="mr-3">
                      mdi-speedometer
                    </v-icon>
                    <div style="line-height: 25px; max-width: 220px">
                      <v-chip small class="cs-chip" style="display: flex !important;" color="secondary">
                        Ніч
                      </v-chip>
                    </div>
                  </div>
                  <div class="pay-table-col">
                    {{ item.start_meter_reading_second }}
                  </div>
                  <div class="pay-table-col">
                    {{ item.current_meter_reading_second }}
                  </div>
                  <div class="pay-table-col" style="min-height: 33px !important;"></div>
                  <div class="pay-table-col" style="min-height: 33px !important;"></div>
                </div>
              </template>
              <template v-if="item.zone === 3">
                <div class="pay-table-expand-body-row">
                  <div class="pay-table-col"></div>
                  <div class="pay-table-col" style="display: flex">
                    <v-icon style="width: 28px" color="grey darken-1" size="20"
                            class="mr-3">
                      mdi-speedometer
                    </v-icon>
                    <div style="line-height: 25px; max-width: 220px">
                      <v-chip small class="cs-chip" style="display: flex !important;" color="secondary">
                        Ніч
                      </v-chip>
                    </div>
                  </div>
                  <div class="pay-table-col">
                    {{ item.start_meter_reading_second }}
                  </div>
                  <div class="pay-table-col">
                    {{ item.current_meter_reading_second }}
                  </div>
                  <div class="pay-table-col" style="min-height: 33px !important;"></div>
                  <div class="pay-table-col" style="min-height: 33px !important;"></div>
                </div>
                <div class="pay-table-expand-body-row">
                  <div class="pay-table-col"></div>
                  <div class="pay-table-col" style="display: flex">
                    <v-icon style="width: 28px" color="grey darken-1" size="20"
                            class="mr-3">
                      mdi-speedometer
                    </v-icon>
                    <div style="line-height: 25px; max-width: 220px">
                      <v-chip small class="cs-chip" style="display: flex !important;" color="error lighten-1">
                        Пік
                      </v-chip>
                    </div>
                  </div>
                  <div class="pay-table-col">
                    {{ item.start_meter_reading_third }}
                  </div>
                  <div class="pay-table-col">
                    {{ item.current_meter_reading_third }}
                  </div>
                  <div class="pay-table-col" style="min-height: 33px !important;"></div>
                  <div class="pay-table-col" style="min-height: 33px !important;"></div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import {getAccountIcon, getVerificationType} from "@/utils/icons";
import {formatDate} from "@/filters";
import {mapActions, mapGetters} from "vuex";
import {
  COUNTER_SELECT,
  GET_ALL_FLAT_COUNTER_OPERATION,
  GET_FLAT_COUNTER,
  GET_FLAT_COUNTER_READING
} from "@/store/actions/counter";

export default {
  name: "FlatCounter",
  props: {
    nonClickable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Прилади обліку'
    },
    modal: {
      type: Boolean,
      default: false
    },
    date_start: {
      type: String,
      default: '2020-01-01'
    },
    person_hash: {
      type: String,
      default: ''
    },
    updatePayArchive: {
      type: Boolean,
      default: false
    },
    personal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters({
      items_all: 'getFlatCounter_',
      flat_: 'getFlat',
      flat_current: 'getCurrentUserFlat',
      organization: 'getCurrentOrganization'
    }),
    flat() {
      return this.personal ? this.flat_current : this.flat_
    },
  },
  components: {
    MainModal: () => import("@/components/modal/MainModal"),
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  data() {
    return {
      selected_row: 0,
      expand_row_num: 0,
      show_actual: true,
      items: [],
      selected_flat_counter: {},
      show_flat_counter_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetch: GET_FLAT_COUNTER,
      fetchCounterReading: GET_FLAT_COUNTER_READING,
      fetchCounterOperation: GET_ALL_FLAT_COUNTER_OPERATION,
    }),
    getAccountIcon,
    getVerificationType,
    formatDate,
    getActualItems() {
        if (this.show_actual) {
          this.items = this.items_all.filter(i => i.active && !i.date_end)
        } else {
          this.items = this.items_all
        }
    },
    expandRow(row_num) {
      this.expand_row_num = this.expand_row_num === row_num ? 0 : row_num
    },
    changeSelect(row_num) {
      this.selected_flat_counter = {}
      this.show_flat_counter_dialog = false

      if (this.selected_row === row_num) {
        this.selected_row = 0

        this.$store.dispatch(COUNTER_SELECT, {})
      } else {
        this.selected_row = row_num
        const row_obj = this.items.find(item => item.id === this.selected_row)
        this.$store.dispatch(COUNTER_SELECT, row_obj)
      }
    },
    onActualChange() {
      this.selected_flat_counter = {}
      this.show_flat_counter_dialog = false
      this.$nextTick(() => {
        if (this.selected_row) {
          const actual_items = this.items_all.filter(i => i.active && !i.date_end)
          const row_obj = actual_items.find(item => item.id === this.selected_row)
          if (!row_obj || !actual_items.length) {
            this.selected_row = 0

            this.$store.dispatch(COUNTER_SELECT, {})
          }
        }
        this.getActualItems()
      })
    },
    flatCounterUpdateItemModal(payload) {
      this.show_flat_counter_dialog = false
      this.selected_flat_counter = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
          if (this.selected_row) {
            const actual_items = this.items_all.filter(i => i.active && !i.date_end)
            const row_obj = actual_items.find(item => item.id === this.selected_row)
            if (!row_obj || !actual_items.length) {
              this.selected_row = 0

              this.$store.dispatch(COUNTER_SELECT, {})
            }
          }
        }
      }
      this.getActualItems()
    },
    openFlatCounterCreateDialog() {
      this.selected_flat_counter = {flat_id: this.flat.id}
      this.show_flat_counter_dialog = true
    },
    onFlatCounterItemClick(payload) {
      if (this.personal) {
        this.selected_flat_counter = {}
        this.show_flat_counter_dialog = true
        return
      }
      this.selected_flat_counter = JSON.parse(JSON.stringify(payload))
      this.show_flat_counter_dialog = true
    },
  },
  created() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetch(this.flat.id)
          .then(res => {
            if (res) {
              this.getActualItems()
              this.fetchCounterOperation(this.flat.id)
                  .then(() => {
                    this.fetchCounterReading(this.flat.id)
                        .then(() => {
                          if (this.items.length >= 1) {
                            this.changeSelect(this.items[0].id)
                          }
                        })
                  })
            }
          })
    }
  },
  beforeDestroy() {
    this.$store.dispatch(COUNTER_SELECT, {})
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload && this.flat.id) {
          this.fetch(this.flat.id)
              .then(res => {
                if (res) {
                  this.getActualItems()
                  this.fetchCounterOperation(this.flat.id)
                      .then(() => {
                        this.fetchCounterReading(this.flat.id)
                            .then(() => {
                              if (this.items.length >= 1) {
                                this.changeSelect(this.items[0].id)
                              }
                            })
                      })
                }
              })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cs-chip {
  width: 72px !important;

  &:deep(.v-chip__content) {
    justify-content: center;
    width: 100%;
  }
}

.pay-table-col {
  padding: 2px 2px !important;

  &:nth-child(1) {
    flex: 0 1 60px;
    padding-left: 16px !important;
    min-width: 60px;
  }

  &:nth-child(2) {
    flex: 0 1 50px;
    min-width: 50px;
  }

  &:nth-child(3) {
    flex: 0 1 140px;
    min-width: 140px;
  }

  &:nth-child(4) {
    flex: 0 1 160px;
    min-width: 160px;
  }

  &:nth-child(5) {
    flex: 0 1 110px;
    min-width: 110px;
  }

  &:nth-child(6) {
    flex: 0 1 110px;
    min-width: 110px;
  }

  &:nth-child(7) {
    flex: 0 1 110px;
    min-width: 110px;
  }

  &:nth-child(8) {
    flex: 0 1 110px;
    min-width: 110px;
  }

  &:nth-child(9) {
    flex: 0 1 140px;
    min-width: 140px;
  }

  &:nth-child(10) {
    flex: 0 1 120px;
    min-width: 120px;
    text-align: right;
  }

  &:nth-child(11) {
    flex: 0 1 120px;
    min-width: 120px;
    text-align: right;
  }

  &:nth-child(12) {
    flex: 0 1 120px;
    min-width: 120px;
    text-align: right;
  }

  &:nth-child(13) {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
}


.pay-table-row-expand-body {
  display: none;
  max-height: 0;

  .pay-table-expand-body-row {
    .pay-table-col {
      &:nth-child(1) {
        flex: 0 1 850px;
        min-width: 850px;
      }

      &:nth-child(2) {
        flex: 0 1 140px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        //background-color: $expand-background-col;
        min-width: 140px;
      }

      &:nth-child(3) {
        flex: 0 1 120px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        //background-color: $expand-background-col;
        min-width: 120px;
        text-align: right;
      }

      &:nth-child(4) {
        flex: 0 1 120px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        //background-color: $expand-background-col;
        min-width: 120px;
        text-align: right;
      }

      &:nth-child(5) {
        flex: 0 1 120px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        //background-color: $expand-background-col;
        min-width: 120px;
        text-align: right;
      }

      &:nth-child(6) {
        flex: 1;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        //background-color: $expand-background-col;
      }
    }
  }
}


.sm-table {
  .pay-table-header {
    display: none;
  }
  .pay-table-body {

    .pay-table-row {
      flex-wrap: wrap !important;

      &:nth-child(1) {
        .pay-table-col {
          &:nth-child(1) {
            border-top: .5px solid lightgray;
          }
          &:nth-child(2) {
            border-top: .5px solid lightgray;
          }
          &:nth-child(3) {
            border-top: .5px solid lightgray;
          }
        }
      }

      .pay-table-col {
        height: 30px;
        text-align: right;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        padding-right: 4px;

        &::before {
          display: block;
          font-size: .84rem;
          width: 38%;
          margin-right: 20px;
          padding-left: 11px;
          color: #2d2d2d;
          font-weight: 500;
          text-align: left;
          justify-content: flex-start;
        }

        &:nth-child(1) {
          flex: 0 0 60px;
          border-bottom: .5px solid lightgray;
        }
        &:nth-child(2) {
          flex: 0 0 60px;
          justify-content: center;
          display: flex;
          border-bottom: .5px solid lightgray;
        }
        &:nth-child(3) {
          flex: 1 1 calc(100% - 120px);
          text-align: left;
          border-bottom: .5px solid lightgray;
        }
        &:nth-child(4) {
          flex: 1 1 50%;
          border-right: .5px solid lightgray;
          &::before {
            content: 'Серійний номер: ';
            line-height: 1;
          }
        }
        &:nth-child(5) {
          flex: 1 1 50%;
          &::before {
            content: 'Модель:';
          }
        }
        &:nth-child(6) {
          flex: 1 1 50%;
          border-right: .5px solid lightgray;
          &::before {
            content: 'Встановлено:';
          }
        }
        &:nth-child(7) {
          flex: 1 1 50%;
          &::before {
            content: 'Знято:';
          }
        }
        &:nth-child(8) {
          flex: 1 1 50%;
          border-right: .5px solid lightgray;
          &::before {
            content: 'Поп. повірка:';
          }
        }
        &:nth-child(9) {
          flex: 1 1 50%;
          &::before {
            content: 'Наступ. повірка:';
          }
        }
        &:nth-child(10) {
          flex: 1 1 50%;
          border-right: .5px solid lightgray;
          &::before {
            content: 'Зона:';
          }
        }
        &:nth-child(11) {
          flex: 1 1 50%;
          &::before {
            content: 'Початковий:';
          }
        }
        &:nth-child(12) {
          flex: 1 1 50%;
          border-right: .5px solid lightgray;
          &::before {
            content: 'Поточний:';
          }
        }
        &:nth-child(13) {
          flex: 1 1 50%;
          &::before {
            content: 'Статус:';
          }
        }
        &:nth-child(14) {
          display: none;
        }
      }
    }
  }
}
.xs-table {
  .pay-table-header {
    display: none;
  }
  .pay-table-body {

    .pay-table-row {
      flex-wrap: wrap !important;

      &:nth-child(1) {
        .pay-table-col {
          &:nth-child(1) {
            border-top: .5px solid lightgray;
          }
          &:nth-child(2) {
            border-top: .5px solid lightgray;
          }
          &:nth-child(3) {
            border-top: .5px solid lightgray;
          }
        }
      }

      .pay-table-col {
        height: 30px;
        text-align: right;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        padding-right: 4px;

        &::before {
          display: block;
          font-size: .84rem;
          width: 50%;
          margin-right: 20px;
          padding-left: 11px;
          color: #2d2d2d;
          font-weight: 500;
          text-align: left;
          justify-content: flex-start;
        }

        &:nth-child(1) {
          flex: 0 0 60px;
          border-bottom: .5px solid lightgray;
        }
        &:nth-child(2) {
          flex: 0 0 60px;
          justify-content: center;
          display: flex;
          border-bottom: .5px solid lightgray;
        }
        &:nth-child(3) {
          flex: 1 1 calc(100% - 120px);
          text-align: left;
          border-bottom: .5px solid lightgray;
        }
        &:nth-child(4) {
          flex: 1 1 100%;
          &::before {
            content: 'Серійний номер: ';
            line-height: 1;
          }
        }
        &:nth-child(5) {
          flex: 1 1 100%;
          &::before {
            content: 'Модель:';
          }
        }
        &:nth-child(6) {
          flex: 1 1 100%;
          &::before {
            content: 'Встановлено:';
          }
        }
        &:nth-child(7) {
          flex: 1 1 100%;
          &::before {
            content: 'Знято:';
          }
        }
        &:nth-child(8) {
          flex: 1 1 100%;
          &::before {
            content: 'Поп. повірка:';
          }
        }
        &:nth-child(9) {
          flex: 1 1 100%;
          &::before {
            content: 'Наст. повірка:';
          }
        }
        &:nth-child(10) {
          flex: 1 1 100%;
          &::before {
            content: 'Зона:';
          }
        }
        &:nth-child(11) {
          flex: 1 1 100%;
          &::before {
            content: 'Початковий:';
          }
        }
        &:nth-child(12) {
          flex: 1 1 100%;
          &::before {
            content: 'Поточний:';
          }
        }
        &:nth-child(13) {
          flex: 1 1 50%;
          &::before {
            content: 'Статус:';
          }
        }
        &:nth-child(14) {
          display: none;
        }
      }
    }
  }
}
</style>
