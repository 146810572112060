var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay-table",class:{
      'sm-table': _vm.$vuetify.breakpoint.sm,
      'xs-table': _vm.$vuetify.breakpoint.xs,
  }},[_c('div',{staticClass:"form-table-custom with-padding mt-2"},[_c('table',{staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"40px","max-width":"40px"}}),_c('th',{staticStyle:{"width":"40px"}},[_vm._v("№")]),_c('th',{staticStyle:{"width":"120px"}},[_vm._v("Місяць")]),_c('th',{staticStyle:{"width":"120px"}},[_vm._v("Дата")]),(_vm.is_legacy)?_c('th',{staticStyle:{"width":"200px"}},[_vm._v("Договір")]):_vm._e(),_c('th',{staticStyle:{"width":"120px"}},[_vm._v("Сума")]),(_vm.is_legacy)?_c('th',{staticStyle:{"width":"120px"}},[_vm._v("ПДВ")]):_vm._e(),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("Послуга")]),_c('th',{staticStyle:{"width":"40px","max-width":"40px"}},[_vm._v("Авто.")]),_c('th',[_vm._v("Коментар")])])]),_c('tbody',[_vm._l((_vm.items),function(item,index){return _c('tr',{key:`item-${item.row_num}-${index}`},[_c('td',{staticStyle:{"width":"50px"}},[_c('v-checkbox',{staticClass:"mt-1 pt-0 ml-3",attrs:{"color":"success","hide-details":"","ripple":false,"value":_vm.selected_row === item.row_num,"disabled":item.month !== (_vm.is_legacy ? _vm.current_month_legacy : _vm.current_month) || item.id === 0,"indeterminate":item.month !== (_vm.is_legacy ? _vm.current_month_legacy : _vm.current_month) || item.id === 0 || item.r_type === 1,"indeterminate-icon":'mdi-lock-outline'},on:{"change":function($event){return _vm.changeSelect(item.row_num)}}})],1),_c('td',{staticStyle:{"width":"40px","max-width":"40px"}},[_vm._v(_vm._s(item.row_num))]),_c('td',{staticStyle:{"width":"120px","font-weight":"500"}},[_vm._v(_vm._s(_vm._f("formatDate")(item.month,'MMMM. YY')))]),_c('td',{staticStyle:{"width":"120px"}},[_vm._v(_vm._s(_vm._f("formatDate")(item.date)))]),(_vm.is_legacy)?_c('td',{staticStyle:{"width":"200px"}},[_vm._v(_vm._s(item.contract_name))]):_vm._e(),_c('td',{staticStyle:{"width":"120px","font-weight":"500"}},[_c('div',{class:[
                            {'error--text': item.sum < 0},
                            {'success--text': item.sum > 0}
                        ]},[_vm._v(" "+_vm._s(item.r_type === 0 ? _vm.formatNumber(item.sum) : item.r_type === 1 ? `${_vm.formatNumber(item.sum)} / ${item.tariff}` : `${_vm.formatNumber(item.sum)}`)+" ")]),(item.r_type === 3)?_c('div',{class:[
                            {'error--text': item.sum_to_swap < 0},
                            {'success--text': item.sum_to_swap > 0}
                        ]},[_vm._v(" "+_vm._s(_vm.formatNumber(item.sum_to_swap))+" ")]):_vm._e()]),(_vm.is_legacy)?_c('td',{staticStyle:{"width":"120px"}},[_vm._v(_vm._s(_vm._f("formatNumber")(item.tax)))]):_vm._e(),_c('td',{staticStyle:{"width":"200px"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-icon',{staticClass:"mr-3 ml-1",staticStyle:{"width":"28px"},attrs:{"color":_vm.getAccountIcon(item.icon, 'color'),"size":"24"}},[_vm._v(" "+_vm._s(_vm.getAccountIcon(item.icon, 'icon'))+" ")]),_c('div',{staticStyle:{"line-height":"15px","text-align":"left"}},[_vm._v(" "+_vm._s(item.service_name)+" ")])],1),(item.r_type === 3)?_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-top":"2px","margin-bottom":"4px"}},[_c('v-icon',{staticClass:"mr-3 ml-1",staticStyle:{"width":"28px"},attrs:{"color":_vm.getAccountIcon(item.service_to_swap_icon, 'color'),"size":"24"}},[_vm._v(" "+_vm._s(_vm.getAccountIcon(item.service_to_swap_icon, 'icon'))+" ")]),_c('div',{staticStyle:{"line-height":"15px","text-align":"left"}},[_vm._v(" "+_vm._s(item.service_to_swap_name)+" ")])],1):_vm._e()]),_c('td',{staticStyle:{"width":"40px","text-align":"left","margin-left":"4px","max-width":"40px"}},[_c('v-simple-checkbox',{staticClass:"ml-2",attrs:{"value":item.semi_auto,"aria-readonly":"true","color":"success","ripple":false}})],1),_c('td',{staticStyle:{"font-style":"oblique","text-align":"left","margin-left":"4px"}},[_vm._v(" "+_vm._s(item.comment)+" ")])])}),(!_vm.items.length)?_c('tr',[_c('td',{staticClass:"success--text",staticStyle:{"text-align":"center","padding":"34px 4px !important","font-size":".94rem","font-weight":"500"},attrs:{"colspan":_vm.is_legacy ? 10 : 8}},[_vm._v(" Архів перерахунків порожній. Після створення - дані відображатимуться автоматично ")])]):_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }