var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay-table",class:{
    'sm-table': _vm.$vuetify.breakpoint.sm,
    'xs-table': _vm.$vuetify.breakpoint.xs,
}},[_c('div',{staticClass:"form-table-custom with-padding mt-2"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('table',{staticStyle:{"width":"100%"}},[_vm._m(0),_c('tbody',[_vm._l((_vm.items),function(item,index){return [_c('tr',{key:`item-${item.row_num}-${index}`},[_c('td',{staticStyle:{"width":"50px","text-align":"center"}},[_vm._v(" "+_vm._s(item.row_num)+" ")]),_c('td',{class:{'font-weight-bold': item.month === _vm.current_month},staticStyle:{"width":"100px","text-align":"left","padding-left":"6px !important"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.month,'MMMM. YY'))+" ")]),_c('td',{class:{'font-weight-bold': item.month === _vm.current_month},staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date))+" ")]),_c('td',{class:{'font-weight-bold': item.month === _vm.current_month},staticStyle:{"width":"110px","text-align":"left","padding-left":"10px !important"}},[_vm._v(" "+_vm._s(item.type)+" ")]),_c('td',{staticStyle:{"width":"200px"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-start","text-align":"left"}},[_c('v-icon',{staticClass:"ml-2 mr-3",staticStyle:{"width":"28px"},attrs:{"color":_vm.getAccountIcon(item.service_icon, 'color'),"size":"24"}},[_vm._v(" "+_vm._s(_vm.getAccountIcon(item.service_icon, 'icon'))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.service_name)+" ")])],1)]),_c('td',{staticStyle:{"width":"90px","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.prev)+" ")]),_c('td',{staticStyle:{"width":"90px","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.next)+" ")]),_c('td',{class:[
                                        {'error--text': item.result < 0},
                                        {'success--text': item.result > 0}
                                    ],staticStyle:{"width":"100px","font-weight":"500"}},[_vm._v(" "+_vm._s(item.result)+" ")]),_c('td',{staticStyle:{"width":"calc(100% - 840px)","padding-left":"8px !important","text-align":"left"},domProps:{"innerHTML":_vm._s(item.comment)}})])]}),(!_vm.items.length)?_c('tr',[_c('td',{staticClass:"success--text",staticStyle:{"text-align":"center","padding":"34px 4px !important","font-size":".94rem","font-weight":"500"},attrs:{"colspan":9}},[_vm._v(" Архів об'ємів порожній. Після розрахунку нарахувань - вони відображатимуться автоматично ")])]):_vm._e()],2)]):[_c('div',{class:{
          'sm-table': _vm.$vuetify.breakpoint.sm,
          'xs-table': _vm.$vuetify.breakpoint.xs,
        }},_vm._l((_vm.items),function(item,index){return _c('div',{key:`item-${item.row_num}-${index}`,staticClass:"table-archive-row"},[_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"col-header"},[_vm._v("№")]),_c('div',{staticClass:"col-value"},[_vm._v(" "+_vm._s(item.row_num)+" ")])]),_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"col-header"},[_vm._v("Місяць")]),_c('div',{staticClass:"col-value"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.month,'MMMM. YY'))+" ")])]),_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"col-header"},[_vm._v("Дата")]),_c('div',{staticClass:"col-value"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date))+" ")])]),_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"col-header"},[_vm._v("Вид")]),_c('div',{staticClass:"col-value"},[_vm._v(" "+_vm._s(item.type)+" ")])]),_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"col-header"},[_vm._v("Послуга")]),_c('div',{staticClass:"col-value"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-icon',{staticClass:"ml-0 mr-3",staticStyle:{"width":"28px"},attrs:{"color":_vm.getAccountIcon(item.service_icon, 'color'),"size":"24"}},[_vm._v(" "+_vm._s(_vm.getAccountIcon(item.service_icon, 'icon'))+" ")]),_c('div',[_vm._v(" "+_vm._s(item.service_name)+" ")])],1)])]),_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"col-header"},[_vm._v("Попередній")]),_c('div',{staticClass:"col-value"},[_vm._v(" "+_vm._s(item.prev)+" ")])]),_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"col-header"},[_vm._v("Поточний")]),_c('div',{staticClass:"col-value"},[_vm._v(" "+_vm._s(item.prev)+" ")])]),_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"col-header"},[_vm._v("Об'єм")]),_c('div',{staticClass:"col-value",class:[
                          {'error--text': item.result < 0},
                          {'success--text': item.result > 0}
                      ]},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.result))+" ")])]),_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"col-header"},[_vm._v("Коментар")]),_c('div',{staticClass:"col-value",domProps:{"innerHTML":_vm._s(item.comment)}})])])}),0)]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"50px"}},[_vm._v("№")]),_c('th',{staticStyle:{"width":"100px"}},[_vm._v("Місяць")]),_c('th',{staticStyle:{"width":"100px"}},[_vm._v("Дата")]),_c('th',{staticStyle:{"width":"110px"}},[_vm._v("Вид")]),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("Послуга")]),_c('th',{staticStyle:{"width":"90px"}},[_vm._v("Попередній")]),_c('th',{staticStyle:{"width":"90px"}},[_vm._v("Поточний")]),_c('th',{staticStyle:{"width":"100px"}},[_vm._v("Об'єм")]),_c('th',{staticStyle:{"width":"calc(100% - 820px)"}},[_vm._v("Коментар")])])])
}]

export { render, staticRenderFns }