<template>
  <v-card
      min-height="280"
      :height="height"
      :style="textCard ? '' : 'border-left: 3px solid #5bb55f'"
      :tile="textCard"
      id="card-table">
    <v-bottom-sheet
        right
        app
        v-model="navigation"
        disable-route-watcher
        persistent
        bottom
        :width="`${bottom_width}%`"
    >
      <v-card flat height="90vh" style="position: relative; display: flex; flex-direction: column;">
        <div class="close">
          <v-btn fab depressed small @click.stop="closePayArchive" color="secondary" dark>
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="grey darken-3 white--text">{{ `Архів оплат з ${dateStartFormatted}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-toolbar elevation="0" class="py-0 px-0 buttons" v-if="!personal && isPaysUser">
            <MainModal
                v-if="!organization.cabinet_only && isPaysUser"
                :main="{ component: 'AccountPaymentView', title: 'Створити оплату' }"
                :button="{ icon: false,
                               buttonIcon: 'mdi-plus',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4 mr-2',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Створити'
                              }"
                :item="{
                         month: current_month,
                         person_id: flat.person_id,
                         flat_id: flat.id,
                         address: flat.address,
                         payer: (flat.flat_owner || {}).short_name
                         }"
                @updateItemModal="updateItemModal"
            />
            <MainModal v-if="payRow.address !== undefined && !organization.cabinet_only && isPaysUser"
                       :main="{ component: 'AccountPaymentView', title: 'Змінити оплату' }"
                       :button="{ icon: false,
                           buttonIcon: 'mdi-pencil',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Змінити'
                          }"
                       :item="Object.assign({month: current_month}, payRow)"
                       @updateItemModal="updateItemModal"
            />
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain class="ml-2 grey lighten-4" v-on="on" v-bind="attrs">
                  <v-icon color="grey darken-2">mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Друк</span>
            </v-tooltip>
          </v-toolbar>
        </v-card-text>
        <v-card-text style="overflow: auto;" :class="personal || !isPaysUser ? 'mt-3' : ''">
          <PayArchive
              :date_start="dateStart || '2020-01-01'"
              :modal="navigation"
              :person_hash="current_flat_hash"
              :updatePayArchive="updatePayArchive"
              @afterPayArchiveUpdate="afterPayArchiveUpdate"
              @selectRow="selectRow"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
        right
        app
        v-model="navigation_recalculation"
        disable-route-watcher
        persistent
        bottom
        :width="`${bottom_width}%`"
    >
      <v-card flat min-height="80vh" style="position: relative">
        <div class="close">
          <v-btn fab depressed small @click.stop="closeRecalculationArchive" color="secondary" dark>
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="grey darken-3 white--text">{{ `Перелік перерахунків з ${dateStartRecalculationFormatted}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-toolbar elevation="0" class="py-0 px-0 buttons" v-if="!personal">
            <v-menu offset-y close-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="grey lighten-4 mr-2"
                    plain depressed
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon color="success" left>mdi-plus</v-icon>
                  Створити
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <MainModal
                      v-if="!organization.cabinet_only"
                      :main="{ component: 'Recalculation', title: 'Створити перерахунок' }"
                      :button="{ icon: false,
                               buttonIcon: 'mdi-plus',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4 mr-2',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Створити ручний перерахунок',
                               buttonBlock: true
                              }"
                      :item="{
                         month: current_month,
                         flat_id: flat.id,
                         date: getCurrentDate(),
                         semi_auto: false
                         }"
                      :max-width="showCounters ? 1060 : 800"
                      @updateItemModal="updateRecalculationItemModal"
                  />
                </v-list-item>
                <v-list-item>
                  <MainModal
                      v-if="!organization.cabinet_only"
                      :main="{ component: 'CloseSwapDebtByFlat', title: 'Створити закриття/перенесення боргу' }"
                      :button="{ icon: false,
                               buttonIcon: 'mdi-plus',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4 mr-2',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Створити закриття/перенесення боргу',
                               buttonBlock: true
                              }"
                      :item="{
                         month: current_month,
                         person_hash: this.current_flat_hash
                         }"
                      :max-width="showCounters ? 1060 : 800"
                      @updateItemModal="updateRecalculationItemModal"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
            <MainModal v-if="recalculationRow.service_id !== undefined && !organization.cabinet_only && recalculationRow.r_type === 0"
                       :main="{ component: 'Recalculation', title: 'Змінити перерахунок' }"
                       :button="{ icon: false,
                           buttonIcon: 'mdi-pencil',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Змінити'
                          }"
                       :item="Object.assign({month: current_month}, recalculationRow)"
                       :max-width="showCounters ? 1060 : 800"
                       @updateItemModal="updateRecalculationItemModal"
            />
            <MainModal v-if="recalculationRow.service_id !== undefined && !organization.cabinet_only && recalculationRow.r_type === 3"
                       :main="{ component: 'CloseSwapDebtByFlat', title: 'Змінити закриття/перерахунок боргу' }"
                       :button="{ icon: false,
                           buttonIcon: 'mdi-pencil',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Змінити'
                          }"
                       :item="Object.assign({month: current_month}, recalculationRow)"
                       :max-width="showCounters ? 1060 : 800"
                       @updateItemModal="updateRecalculationItemModal"
            />
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain class="ml-2 grey lighten-4" v-on="on" v-bind="attrs">
                  <v-icon color="grey darken-2">mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Друк</span>
              {{ recalculationRow.r_type }}
            </v-tooltip>
          </v-toolbar>
        </v-card-text>
        <v-card-text style="overflow: auto" :class="personal ? 'mt-3' : ''">
          <RecalculationArchive
              :date_start="dateStartRecalculation || '2020-01-01'"
              :modal="navigation_recalculation"
              :person_hash="current_flat_hash"
              :updateRecalculationArchive="updateRecalculationArchive"
              @afterRecalculationArchiveUpdate="afterRecalculationArchiveUpdate"
              @selectRow="selectRecalculationRow"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
        right
        app
        v-model="navigation_privilege_recalculation"
        disable-route-watcher
        persistent
        bottom
        :width="`${bottom_width}%`"
    >
      <v-card flat height="90vh" style="position: relative; display: flex; flex-direction: column;">
        <div class="close">
          <v-btn fab depressed small @click.stop="closePrivilegeRecalculationArchive" color="secondary" dark>
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="grey darken-3 white--text">{{ `Перелік перерахунку пільги з ${dateStartPrivilegeRecalculationFormatted}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-toolbar elevation="0" class="py-0 px-0 buttons" v-if="!personal">
            <MainModal
                v-if="!organization.cabinet_only"
                :main="{ component: 'PrivilegeRecalculation', title: 'Створити перерахунок пільги' }"
                :button="{ icon: false,
                               buttonIcon: 'mdi-plus',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4 mr-2',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Створити'
                              }"
                :item="{
                         month: current_month,
                         flat_id: flat.id,
                         date: getCurrentDate()
                         }"
                @updateItemModal="updatePrivilegeRecalculationItemModal"
            />
            <MainModal v-if="privilegeRecalculationRow.service_id !== undefined && !organization.cabinet_only"
                       :main="{ component: 'PrivilegeRecalculation', title: 'Змінити перерахунок' }"
                       :button="{ icon: false,
                           buttonIcon: 'mdi-pencil',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Змінити'
                          }"
                       :item="Object.assign({month: current_month}, privilegeRecalculationRow)"
                       @updateItemModal="updatePrivilegeRecalculationItemModal"
            />
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain class="ml-2 grey lighten-4" v-on="on" v-bind="attrs">
                  <v-icon color="grey darken-2">mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Друк</span>
            </v-tooltip>
          </v-toolbar>
        </v-card-text>
        <v-card-text style="overflow: auto" :class="personal ? 'mt-3' : ''">
          <PrivilegeRecalculation
              :date_start="dateStartRecalculation || '2020-01-01'"
              :modal="navigation_privilege_recalculation"
              :person_hash="current_flat_hash"
              :updatePrivilegeRecalculationArchive="updatePrivilegeRecalculationArchive"
              @afterPrivilegeRecalculationArchiveUpdate="afterPrivilegeRecalculationArchiveUpdate"
              @selectRow="selectPrivilegeRecalculationRow"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
        right
        app
        v-model="navigation_one_time"
        disable-route-watcher
        persistent
        bottom
        :width="`${bottom_width}%`"
    >
      <v-card flat height="90vh" style="position: relative; display: flex; flex-direction: column;">
        <div class="close">
          <v-btn fab depressed small @click.stop="closeOneTimeArchive" color="secondary" dark>
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="grey darken-3 white--text">{{ `Перелік додаткових нарахувань з ${dateStartOneTimeFormatted}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-toolbar elevation="0" class="py-0 px-0 buttons" v-if="!personal">
            <MainModal
                :main="{ component: 'OneTimeChargeByFlat', title: 'Створити додаткове нарахування' }"
                :button="{ icon: false,
                               buttonIcon: 'mdi-plus',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4 mr-2',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Створити'
                              }"
                :item="{
                         month: current_month,
                         person_hash: current_flat_hash
                         }"
                @updateItemModal="updateOneTimeItemModal"
            />
            <MainModal v-if="oneTimeRow.service_id !== undefined"
                       :main="{ component: 'OneTimeChargeByFlat', title: 'Змінити додаткове нарахування' }"
                       :button="{ icon: false,
                           buttonIcon: 'mdi-pencil',
                               buttonIconSmall: false,
                               buttonIconClass: 'mr-2',
                               buttonIconColor: 'success',
                               buttonClass: 'grey lighten-4',
                               buttonColor: 'mr-2',
                               buttonPlain: true,
                               buttonSmall: false,
                               buttonDepressed: true,
                               buttonName: 'Змінити'
                          }"
                       :item="Object.assign({month: current_month}, oneTimeRow,
                       {person_hash: current_flat_hash})"
                       @updateItemModal="updateOneTimeItemModal"
            />
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain class="ml-2 grey lighten-4" v-on="on" v-bind="attrs">
                  <v-icon color="grey darken-2">mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Друк</span>
            </v-tooltip>
          </v-toolbar>
        </v-card-text>
        <v-card-text style="overflow: auto" :class="personal ? 'mt-3' : ''">
          <OneTimeArchive
              :date_start="dateStartOneTime || '2020-01-01'"
              :modal="navigation_one_time"
              :person_hash="current_flat_hash"
              :updateOneTimeArchive="updateOneTimeArchive"
              @afterOneTimeArchiveUpdate="afterOneTimeUpdate"
              @selectRow="selectOneTimeRow"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
            right
            app
            v-model="navigation_volume"
            disable-route-watcher
            persistent
            bottom
            :width="`${bottom_width}%`"
    >
      <v-card flat height="90vh" style="position: relative; display: flex; flex-direction: column;">
          <div class="close">
              <v-btn fab depressed small @click.stop="closeVolumeArchive" color="secondary" dark>
                  <v-icon dark>mdi-close</v-icon>
              </v-btn>
          </div>
          <v-card-title class="grey darken-3 white--text">
              {{ `Перелік об'ємів нарахувань з ${dateStartVolumeFormatted}` }}
          </v-card-title>
          <v-card-text style="overflow: auto" :class="personal ? 'mt-3' : ''">
              <VolumeArchive
                      :date_start="dateStartVolume || '2020-01-01'"
                      :service_id="serviceVolume"
                      :modal="navigation_volume"
                      :person_hash="current_flat_hash"
                      :updateVolumeArchive="updateVolumeArchive"
                      @afterVolumeArchiveUpdate="afterVolumeUpdate"
              />
          </v-card-text>
      </v-card>
  </v-bottom-sheet>
    <v-card-title class="py-1 px-1">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="pa-0" style="overflow: hidden">
      <div class="table" style="min-height: 60vh"
           :class="{
              'sm-table': $vuetify.breakpoint.sm,
              'xs-table': $vuetify.breakpoint.xs,
            }"
      >
        <div class="table-header">
          <div class="table-row">
            <div class="table-col">Послуга</div>
            <div class="table-col">{{ volume_column_name }}</div>
            <div class="table-col">Тариф</div>
            <div class="table-col" style="line-height: 14px">Сальдо <br> на початок</div>
            <div class="table-col">Нараховано</div>
            <div class="table-col">Перерахунок</div>
            <div class="table-col">Пільга</div>
            <div class="table-col">Оплата</div>
            <div class="table-col">Субсидія</div>
            <div class="table-col" style="line-height: 14px">Сальдо <br> на кінець</div>
            <div class="table-col hidden"></div>
          </div>
        </div>
        <div class="wrapper" style="max-height: calc(100vh - 300px); overflow-y: overlay" id="wrapper-table-archive">
            <div v-for="(month_row, index) in archive_items" :key="`mr-${index}`"
                 class="exp-row" :id="`exp-row-${index}`"
                 :class="{'expanded-active': open.includes(`exp-row-${index}`)}"
            >
              <div class="table-row expand-row" :class="{
                'active': open.includes(`exp-row-${index}`),
                'current-month': month_row.month === current_month,
              }">
                <div class="table-col">
                  <div>
                    <v-icon size="22" color="grey darken-3" class="mr-3 expand-icon" @click="expand_row(`exp-row-${index}`)">
                      {{ open.includes(`exp-row-${index}`) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </div>
                  <div class="text-capitalize">
                    {{ month_row.month | formatDate('MMMM YYYY') }}
                  </div>
                </div>
                <div class="table-col" style="cursor: pointer" @click="openVolumeArchive(month_row.month)"></div>
                <div class="table-col"></div>
                <div class="table-col"
                     :class="[
                          {'error--text': month_row.start_balance > 0},
                          {'success--text': month_row.start_balance < 0}
                      ]"
                >
                  {{ month_row.start_balance | formatToFixed | formatNumber }}
                </div>
                <div class="table-col" style="cursor: pointer" @click="openOneTimeArchive(month_row.month)">
                  {{ month_row.charge | formatToFixed | formatNumber }}
                </div>
                <div class="table-col" style="cursor: pointer" @click="openRecalculationArchive(month_row.month)">
                  {{ month_row.recalculation | formatToFixed | formatNumber }}
                </div>
                <div class="table-col" style="cursor: pointer" @click="openPrivilegeRecalculationArchive(month_row.month)">
                  {{ month_row.privilege | formatToFixed | formatNumber }}
                </div>
                <div class="table-col" style="cursor: pointer" @click="openPayArchive(month_row.month)">
                  {{ month_row.pay | formatToFixed | formatNumber }}
                </div>
                <div class="table-col" style="cursor: pointer">
                  {{ month_row.subsidy | formatToFixed | formatNumber }}
                </div>
                <div class="table-col"
                     :class="[
                          {'error--text': month_row.end_balance > 0},
                          {'success--text': month_row.end_balance < 0}
                      ]"
                >
                  {{ month_row.end_balance | formatToFixed | formatNumber }}
                </div>
                <div class="table-col hidden"></div>
              </div>
              <div class="table-body">
                <div v-for="(detail_row, index) in month_row.details" :key="`detail-${index}`" class="table-row">
                  <div class="table-col">
                    <div class="icon" style="display: flex; justify-content: center; align-items: center">
                      <v-icon class="mr-3" size="22" :color="getAccountIcon(detail_row.icon, 'color')">
                        {{ getAccountIcon(detail_row.icon, 'icon') }}
                      </v-icon>
                    </div>
                    <div>{{ detail_row.service_name }}</div>
                  </div>
                  <div class="table-col" style="cursor: pointer" @click="openVolumeArchive(month_row.month, detail_row.service_id)">
                      {{ detail_row.square }}
                  </div>
                  <div class="table-col">{{ detail_row.tariff | formatNumber('0.0000') }}</div>
                  <div class="table-col"
                       :class="[
                          {'error--text': detail_row.start_balance > 0},
                          {'success--text': detail_row.start_balance < 0}
                      ]"
                  >
                    {{ detail_row.start_balance | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col" style="cursor: pointer" @click="openOneTimeArchive(month_row.month)">
                    {{ detail_row.charge | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col" style="cursor: pointer" @click="openRecalculationArchive(month_row.month)">
                    {{ detail_row.recalculation | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col">
                    {{ detail_row.privilege | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col" style="cursor: pointer" @click="openPayArchive(month_row.month)">
                    {{ detail_row.pay | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col" style="cursor: pointer">
                    {{ detail_row.subsidy | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col"
                       :class="[
                          {'error--text': detail_row.end_balance > 0},
                          {'success--text': detail_row.end_balance < 0}
                      ]"
                  >
                    {{ detail_row.end_balance | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col hidden"></div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import PayArchive from "@/components/PayArchive";
import RecalculationArchive from "@/components/RecalculationArchive";
import OneTimeArchive from "@/components/OneTimeArchive";
import VolumeArchive from "@/components/VolumeArchive.vue";
import PrivilegeRecalculation from "@/components/PrivilegeRecalculationArchive";
import { mapActions, mapGetters } from 'vuex'
import {GET_ARCHIVE} from "@/store/actions/flat_service";
import {getAccountIcon, getCurrentDate} from "@/utils/icons";
import {formatDate} from "@/filters"

export default {
  name: "HWP_Archive",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Архів розрахунків по абоненту'
    },
    person_hash: {
      type: String,
      default: ''
    },
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  components: {
    PayArchive,
    RecalculationArchive,
    OneTimeArchive,
    PrivilegeRecalculation,
    VolumeArchive,
    MainModal: () => import('@/components/modal/MainModal')
  },
  computed: {
    ...mapGetters({
      archive_items: 'getFlatArchive',
      current_month: 'getCurrentMonth',
      flat_: 'getFlat',
      flat_current: 'getCurrentUserFlat',
      organization: 'getCurrentOrganization',
      bottom_width: 'getBottomSheetWidth',
      settings: 'getGeneralSettings',
      isPaysUser: 'isPaysUser'
    }),
    flat() {
      return this.personal ? this.flat_current : this.flat_
    },
    dateStartFormatted() {
      return formatDate(this.dateStart)
    },
    dateStartPrivilegeRecalculationFormatted() {
      return formatDate(this.dateStartPrivilegeRecalculation)
    },
    dateStartRecalculationFormatted() {
      return formatDate(this.dateStartRecalculation)
    },
    dateStartOneTimeFormatted() {
      return formatDate(this.dateStartOneTime)
    },
    dateStartVolumeFormatted() {
          return formatDate(this.dateStartVolume)
      },
    current_flat_hash() {
      let person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
      if (person_hash) {
        return person_hash
      } else {
        return this.person_hash
      }
    },
    showCounters() {
      return this.settings?.work_place_counter?.value
    },
    volume_column_name() {
      return this.settings?.work_place_volume_column_name?.value || 'Об’єм'
    }
  },
  data() {
    return {
      navigation: false,
      navigation_recalculation: false,
      navigation_privilege_recalculation: false,
      navigation_one_time: false,
      navigation_volume: false,
      open: [],
      service: null,
      dateStart: null,
      dateStartRecalculation: null,
      dateStartPrivilegeRecalculation: null,
      dateStartOneTime: null,
      dateStartVolume: null,
      serviceVolume: null,
      dateEnd: null,
      updatePayArchive: false,
      updateRecalculationArchive: false,
      updatePrivilegeRecalculationArchive: false,
      updateOneTimeArchive: false,
      updateVolumeArchive: false,
      payRow: {},
      recalculationRow: {},
      privilegeRecalculationRow: {},
      oneTimeRow: {}
    }
  },
  methods: {
    ...mapActions({
      fetchArchive: GET_ARCHIVE,
    }),
    getCurrentDate,
    getAccountIcon,
    expand_row: function (row_id) {
      const row = document.getElementById(row_id)
      const prev_row_index = +row_id.substring(row_id.lastIndexOf('-')+1)
      const table_body = row.getElementsByClassName('table-body')[0]
      table_body.classList.toggle('show')
      if (this.open.includes(row_id)) {
        const open_id = this.open.indexOf(row_id)
        this.open.splice(open_id, 1)
        if (prev_row_index >= 0) {
          let prev_row = document.getElementById(`exp-row-${prev_row_index}`)
          prev_row.scrollIntoView({block: "start", behavior: "smooth"})
        }
      } else {
        this.open.push(row_id)
        row.scrollIntoView({block: "start", behavior: "smooth"})
        // window.scrollTop(row.offset().top - row.offset().top/5);
      }
    },
    openPayArchive(month) {
      this.dateStart = month
      this.navigation = true
    },
    openRecalculationArchive(month) {
      this.dateStartRecalculation = month
      this.navigation_recalculation = true
    },
    openPrivilegeRecalculationArchive(month) {
      this.dateStartPrivilegeRecalculation = month
      this.navigation_privilege_recalculation = true
    },
    openOneTimeArchive(month) {
      this.dateStartOneTime = month
      this.navigation_one_time = true
    },
    openVolumeArchive(month, service_id=null) {
        this.dateStartVolume = month
        this.serviceVolume = service_id
        this.navigation_volume = true
      },
    selectRow(payload) {
      this.payRow = payload
    },
    selectRecalculationRow(payload) {
      this.recalculationRow = payload
    },
    selectPrivilegeRecalculationRow(payload) {
      this.privilegeRecalculationRow = payload
    },
    selectOneTimeRow(payload) {
      this.oneTimeRow = payload
    },
    closePayArchive() {
      this.payRow = {}
      this.navigation = false
    },
    closeRecalculationArchive() {
      this.recalculationRow = {}
      this.navigation_recalculation = false
    },
    closePrivilegeRecalculationArchive() {
      this.privilegeRecalculationRow = {}
      this.navigation_privilege_recalculation = false
    },
    closeOneTimeArchive() {
      this.oneTimeRow = {}
      this.navigation_one_time = false
    },
    closeVolumeArchive() {
        this.serviceVolume = null
        this.navigation_volume = false
      },
    updateItemModal(payload) {
      if (payload.payload) {
        if (payload.payload.operation === 'fetch') {
          this.updatePayArchive = true
        }
      }
    },
    updateRecalculationItemModal(payload) {
      if (payload.payload) {
        if (payload.payload.operation === 'fetch') {
          this.updateRecalculationArchive = true
        }
      }
    },
    updatePrivilegeRecalculationItemModal(payload) {
      if (payload.payload) {
        if (payload.payload.operation === 'fetch') {
          this.updatePrivilegeRecalculationArchive = true
        }
      }
    },
    updateOneTimeItemModal(payload) {
      if (payload.payload) {
        if (payload.payload.operation === 'fetch') {
          this.updateOneTimeArchive = true
        }
      }
    },
    afterPayArchiveUpdate() {
      this.updatePayArchive = false
      this.payRow = {}
    },
    afterRecalculationArchiveUpdate() {
      this.updateRecalculationArchive = false
      this.recalculationRow = {}
    },
    afterPrivilegeRecalculationArchiveUpdate() {
      this.updatePrivilegeRecalculationArchive = false
      this.privilegeRecalculationRow = {}
    },
    afterOneTimeUpdate() {
      this.updateOneTimeArchive = false
      this.oneTimeRow = {}
    },
    afterVolumeUpdate() {
          this.updateVolumeArchive = false
      },
  },
  mounted() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetchArchive(person_hash)
          .then(() => {
            this.$nextTick(() => {
              if (this.archive_items.length) {
                const row = `exp-row-${this.archive_items.length-1}`
                let prev_row = document.getElementById(row)
                if (prev_row) {
                  setTimeout(() => {
                    prev_row.scrollIntoView({block: "start", behavior: "smooth"})
                  }, 600)
                }
              }
            })
          })
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetchArchive(payload)
              .then(() => {
                this.$nextTick(() => {
                  if (this.archive_items.length) {
                    const row = `exp-row-${this.archive_items.length-1}`
                    let prev_row = document.getElementById(row)
                    if (prev_row) {
                      setTimeout(() => {
                        prev_row.scrollIntoView({block: "start", behavior: "smooth"})
                      }, 600)
                    }
                  }
                })
              })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: #e6e6e6;
$border-color-mini: #d9d9d9;

  .close {
    position: absolute;
    right: -8px;
    top: -9px;
  }

  :deep(.v-input__control) {
    height: 30px !important;
  }

  :deep(.v-text-field.v-text-field--solo .v-input__control) {
    min-height: 34px;
  }

  .buttons {
    :deep(.v-toolbar__content) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .expand-icon:focus:after {
    opacity: 0 !important;
  }
  .expand-icon:hover {
    color: green !important;
  }

  .table {
    min-width: 1000px;
    .table-row {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      flex: 1 1;
      //border-bottom: 1px solid $border-color;

      .hidden {
        visibility: hidden;
      }

      .table-col {
        flex: 0 1 100px;
        flex-wrap: nowrap;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .table-col:nth-child(1) {
        flex: 0 1 300px;
        min-width: 300px;
        justify-content: flex-start !important;
      }

      .table-col {
        padding: 0 !important;
        .table-col-group {
          width: 100%;
          .table-col {
            border: none !important;
            justify-content: center !important;
          }
        }
      }
    }

    .table-header {
      .table-row {
        font-size: 12px;
        font-weight: bold;
        .table-col {
          text-align: center;
          border: none;
          background-color: #fafafa;
          border-bottom: 1px solid $border-color;

          &:nth-child(1) {
            padding: 7px 7px 7px 21px !important;
          }
        }
      }
    }

    .table-body.show {
      display: block;
      max-height: 100%;
      transition: all .3s linear;

      .table-row {
        &:hover .table-col {
          background-color: #f8f8f8;
        }

        .table-col {
          background-color: #f8f8f833;
          border-right: .5px solid $border-color;
          transition: 20ms background-color linear;
        }

        &:last-child {
          .table-col {
            border-bottom: .5px solid $border-color;
          }
        }
      }
    }

    .table-body {
      display: none;
      max-height: 0;
      .table-row {
        font-size: 0.85rem;


        .table-col:nth-child(1) {
          padding: 7px !important;
          line-height: 16px;
        }

        .table-col:nth-child(1) .icon {
          margin-left: 34px;
        }
      }
    }

    .expand-row {
      font-size: 13px;
      font-weight: normal;
      border-bottom: none !important;
      color: #5a5a5a;

      &:hover .table-col {
        background-color: #f8f8f8;
      }

      .table-col {
        border-right: .5px solid $border-color;
        border-bottom: .5px solid $border-color;
        //border-top: .5px solid $border-color;
        padding: 3px !important;

        &:nth-child(1) {
          padding: 3px 3px 3px 21px !important;
        }
      }

      &:nth-child(1) .table-col {

      }
      &:last-child .table-col {
        border-top: .5px solid $border-color;
      }
    }

    .expand-row.active {
      font-weight: 500;
    }

  }

  .sm-table {
    min-width: auto !important;

    .table-header {
      display: none;
    }
    .wrapper {
      transition: all ease-in-out .5s;
      .exp-row {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 2px solid lightgray;
        .expand-row {
          flex-wrap: wrap;
          .table-col {
            height: 30px;
            text-align: right;
            justify-content: flex-start;

            &::before {
              display: block;
              font-size: .75rem;
              width: 120px;
              margin-right: 20px;
              color: #2d2d2d;
              font-weight: 500;
            }

            &:nth-child(1) {
              flex: 1 1 100%;
            }
            &:nth-child(2) {
              display: none;
            }
            &:nth-child(3) {
              display: none;
            }
            &:nth-child(4) {
              flex: 1 1 100%;
              &::before {
                content: 'Сальдо на поч.: ';
              }
            }
            &:nth-child(5) {
              flex: 1 1 50%;
              &::before {
                content: 'Нараховано: ';
              }
            }
            &:nth-child(6) {
              flex: 1 1 50%;
              &::before {
                content: 'Перерахунок: ';
              }
            }
            &:nth-child(7) {
              flex: 1 1 50%;
              &::before {
                content: 'Пільга: ';
              }
            }
            &:nth-child(8) {
              flex: 1 1 50%;
              &::before {
                content: 'Оплачено: ';
              }
            }
            &:nth-child(9) {
              flex: 1 1 50%;
              &::before {
                content: 'Субсидія: ';
              }
            }
            &:nth-child(10) {
              flex: 1 1 50%;
              &::before {
                content: 'Сальдо на кін.: ';
              }
            }
            &:nth-child(11) {
              display: none;
            }
          }
        }
        .table-body {
          .table-row {
            flex-wrap: wrap !important;
            .table-col {
              height: 30px;
              text-align: right;

              &::before {
                display: block;
                font-size: .75rem;
                width: 120px;
                margin-right: 20px;
                color: #2d2d2d;
                font-weight: 500;
              }

              &:nth-child(1) {
                flex: 1 1 100%;
                border-bottom: .5px solid $border-color;
              }
              &:nth-child(2) {
                flex: 1 1 50%;
                justify-content: flex-start;

                &::before {
                  content: 'Об’єм: ';
                }
              }
              &:nth-child(3) {
                flex: 1 1 50%;
                justify-content: flex-start;

                &::before {
                  content: 'Тариф: ';
                }
              }
              &:nth-child(4) {
                flex: 1 1 50%;
                justify-content: flex-start;

                &::before {
                  content: 'Сальдо поч.: ';
                }
              }
              &:nth-child(5) {
                flex: 1 1 50%;
                justify-content: flex-start;

                &::before {
                  content: 'Нараховано: ';
                }
              }
              &:nth-child(6) {
                flex: 1 1 50%;
                justify-content: flex-start;

                &::before {
                  content: 'Перерахунок: ';
                }
              }
              &:nth-child(7) {
                flex: 1 1 50%;
                justify-content: flex-start;

                &::before {
                  content: 'Пільга: ';
                }
              }
              &:nth-child(8) {
                flex: 1 1 50%;
                justify-content: flex-start;

                &::before {
                  content: 'Оплачено: ';
                }
              }
              &:nth-child(9) {
                flex: 1 1 50%;
                justify-content: flex-start;

                &::before {
                  content: 'Субсидія: ';
                }
              }
              &:nth-child(10) {
                flex: 1 1 100%;
                border-bottom: .5px solid $border-color;
                border-top: .5px solid $border-color;
                justify-content: flex-start;

                &::before {
                  content: 'Сальдо кін.: ';
                }
              }
              &:nth-child(11) {
                display: none;
              }
            }
            &:last-child .table-col {
              border-bottom: none !important;
            }
          }
        }
      }
    }
    .expanded-active {
      margin: 10px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    }
  }

  .xs-table {
    min-width: auto !important;

    .table-header {
      display: none;
    }
    .wrapper {
      transition: all ease-in-out .5s;
      .exp-row {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 2px solid lightgray;
        .expand-row {
          flex-wrap: wrap;
          font-size: .92rem !important;
          .table-col {
            height: 36px;
            text-align: right;
            justify-content: flex-start;
            flex: 1 1 100%;

            &::before {
              display: block;
              font-size: .91rem;
              width: 50%;
              margin-right: 20px;
              text-align: left;
              padding-left: 20px;
              color: #2d2d2d;
              font-weight: 500;
            }
            &:nth-child(1) {
              padding-left: 6px !important;
              .icon {
                margin-left: 2px !important;
              }
            }
            &:nth-child(2) {
              display: none;
            }
            &:nth-child(3) {
              display: none;
            }
            &:nth-child(4) {
              &::before {
                content: 'Сальдо на поч.: ';
              }
            }
            &:nth-child(5) {
              &::before {
                content: 'Нараховано: ';
              }
            }
            &:nth-child(6) {
              &::before {
                content: 'Перерахунок: ';
              }
            }
            &:nth-child(7) {
              &::before {
                content: 'Пільга: ';
              }
            }
            &:nth-child(8) {
              &::before {
                content: 'Оплачено: ';
              }
            }
            &:nth-child(9) {
              &::before {
                content: 'Субсидія: ';
              }
            }
            &:nth-child(10) {
              &::before {
                content: 'Сальдо на кін.: ';
              }
            }
            &:nth-child(11) {
              display: none;
            }
          }
        }
        .table-body {
          .table-row {
            flex-wrap: wrap !important;
            .table-col {
              height: 33px;
              text-align: right;
              flex: 1 1 100%;
              justify-content: flex-start;

              &::before {
                display: block;
                font-size: .84rem;
                width: 70%;
                margin-right: 20px;
                color: #2d2d2d;
                font-weight: 500;
                background-color: #f5f5f5;
                margin-left: 8px;
                padding-right: 4px;
                padding-top: 4px;
                padding-bottom: 4px;
              }

              &:nth-child(1) {
                border-bottom: .5px solid $border-color;
                padding-right: 10px !important;
                height: 40px;
                .icon {
                  margin-left: 6px !important;

                  i {
                    margin-right: 20px !important;
                  }
                }
              }
              &:nth-child(2) {
                &::before {
                  content: 'Об’єм: ';
                }
              }
              &:nth-child(3) {
                &::before {
                  content: 'Тариф: ';
                }
              }
              &:nth-child(4) {
                &::before {
                  content: 'Сальдо поч.: ';
                }
              }
              &:nth-child(5) {
                &::before {
                  content: 'Нараховано: ';
                }
              }
              &:nth-child(6) {
                &::before {
                  content: 'Перерахунок: ';
                }
              }
              &:nth-child(7) {
                &::before {
                  content: 'Пільга: ';
                }
              }
              &:nth-child(8) {
                &::before {
                  content: 'Оплачено: ';
                }
              }
              &:nth-child(9) {
                &::before {
                  content: 'Субсидія: ';
                }
              }
              &:nth-child(10) {
                border-bottom: .5px solid $border-color;
                &::before {
                  content: 'Сальдо кін.: ';
                }
              }
              &:nth-child(11) {
                display: none;
              }
            }
            &:last-child .table-col {
              border-bottom: none !important;
            }
          }
        }
      }
    }
    .expanded-active {
      margin: 10px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    }
  }

</style>
