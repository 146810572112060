<template>
  <div class="pay-table-wrapper"
       :class="{'pay-table-wrapper-full': full_height}"
  >
    <div class="pay-table"
         :class="{
        [sm_table || 'sm-table']: $vuetify.breakpoint.sm,
        [xs_table || 'xs-table']: $vuetify.breakpoint.xs,
    }"
    >
      <div class="pay-table-header">
        <div class="pay-table-row">
          <div
              class="pay-table-col"
              v-for="(item, index) in header" :key="`header-${index}`"
              :style="colStyle(index)"
              :class="{'small':small}"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="pay-table-body"
           :style="body_style"
      >
        <div
            class="pay-table-row"
            :class="{
                      'expandable': expandable(item),
                      'expanded': item.row_num === expand_row_num
                    }"
            v-for="(item, index) in items" :key="`item-${item.row_num}-${index}`">
          <template v-if="!expandable(item)">
            <template v-for="(col, index) in header">
              <div
                  class="pay-table-col"
                  :class="{'small': small}"
                  :key="`item-col-${index}`"
                  :style="colStyle(index, item)"
                  @click="clickEvent(col, item)"
              >
                <template v-if="col.type === 'icon'">
                  <v-icon
                      class="mr-2"
                      size="24"
                      :color="col.iconFunc ? col.iconFunc(item[col.value], 'color') : col.bool ? 'grey darken-1' : 'primary'"
                  >
                    <template v-if="col.bool">
                      {{ item[col.value] ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline' }}
                    </template>
                    <template v-else>
                      {{ col.iconFunc ? col.iconFunc(item[col.value], 'icon') : item[col.value] }}
                    </template>
                  </v-icon>
                </template>
                <template v-else-if="col.type === 'date'">
                  <template v-if="col.chip">
                    <v-chip :small="col.chip.small || false" :color="col.chip.color || 'primary'">
                      {{ item[col.value] | formatDate(col.format) }}
                    </v-chip>
                  </template>
                  <template v-else>
                    {{ item[col.value] | formatDate(col.format) }}
                  </template>
                </template>
                <template v-else-if="col.type === 'get'">
                  <template>
                    {{ col.func(item[col.value]) }}
                  </template>
                </template>
                <template v-else>
                  <template v-if="col.chip">
                    <v-chip
                        @click.prevent
                        label
                        v-if="col.if_filled ? item[col.value] : true"
                        :small="col.chip.small || false"
                        :color="col.chip.get_color ? item.color : col.chip.color || 'primary'">
                      {{ item[col.value] }}
                    </v-chip>
                  </template>
                  <template v-else>
                    {{ item[col.value] }}
                  </template>
                </template>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="pay-table-row-expand-header" :class="{'expanded': item.row_num === expand_row_num}">
              <template v-for="(col, col_index) in header">
                <div class="pay-table-col" :class="{'small': small}" :key="`item-col-${col_index}`"
                     :style="colStyle(col_index, col)">
                  <template v-if="col_index === expandableIndex()">
                    <v-btn icon small class="mr-3" @click="expandRow(item.row_num)">
                      <v-icon :color="item.row_num === expand_row_num ? 'primary' : 'grey darken-1'">
                        {{item.row_num === expand_row_num ? 'mdi-arrow-up-bold-circle-outline' : 'mdi-arrow-down-bold-circle-outline' }}
                      </v-icon>
                    </v-btn>
                    <div style="line-height: 25px; max-width: 220px">
                      {{ item[col.value] }}
                    </div>
                  </template>
                  <template v-else>
                    {{ item[col.value] }}
                  </template>
                </div>
              </template>
            </div>
            <div class="pay-table-row-expand-body" :class="{'expanded': item.row_num === expand_row_num}">
              <div class="pay-table-col" :class="{'small': small}"></div>
              <template v-for="(col, index) in header">
                <div class="pay-table-col"
                     :class="{'small': small}"
                     :key="`item-col-${index}`"
                     :style="colStyle(index)"
                     v-if="index >= expandableIndex()">
                  {{ item[col.value] }}
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="pay-table-footer" v-if="show_footer">
        <div class="pay-table-row">
          <div
              class="pay-table-col"
              v-for="(item, index) in header" :key="`header-${index}`"
              :style="colStyle(index)"
              :class="{'small':small}"
          >
            <template v-if="index < 2">
            </template>
            <template v-else>
              <template v-if="item.type === 'icon'"></template>
              <template v-else-if="item.type === 'date'"></template>
              <template v-else-if="item.type === 'get'"></template>
              <template v-else>
                <template v-if="item.chip"></template>
                <template v-else>
                  {{ getTotal('items', item.value) | formatNumber }}
                </template>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableComponent",
  props: ['header', 'items', 'small', 'sm_table', 'xs_table', 'scrollable', 'full_height', 'body_height', 'show_footer'],
  data() {
    return {
      expand_row_num: 0,
    }
  },
  methods: {
    colStyle(index, item = {}) {
      const header_obj = this.header[index]
      const last_col = index === (this.header.length - 1)
      const first_col = index === 0
      const col_width = (header_obj.width || 100)
      const col_min_width = col_width > 150 ? -10 : col_width
      let addFuncStyle = ''
      const funcStyle = header_obj.style

      if (funcStyle && Object.keys(item).length) {
        const obj_key = Object.keys(funcStyle)[0]
        const obj_value_func = funcStyle[obj_key](item)
        if (obj_value_func) {
          addFuncStyle = '' + obj_key
        }
      }



      if (first_col && last_col) {
        return `
          flex: 0 1 ${col_width}px;
          padding-left: ${this.small ? 12 : 16}px !important;
          min-width: ${col_min_width}px;
          ${addFuncStyle}
        `
      } else if (first_col && !last_col) {
        return `
          flex: 0 1 ${col_width}px;
          padding-left: ${this.small ? 12 : 16}px !important;
          min-width: ${col_min_width}px;
          ${addFuncStyle}
        `
      } else if (!first_col && last_col) {
        return `
          lex: 1;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          ${addFuncStyle}
        `
      } else {
        return `
          flex: 0 1 ${col_width}px;
          min-width: ${col_min_width}px;
          ${addFuncStyle}
          `
      }

    },
    getTotal(table='items', col) {
      return this[table].reduce((acc, total) => {
        return acc + total[col]
      }, 0)
    },
    expandable(item) {
      const expandable_col_name = (this.header.find(item => item.expandable === true) || {}).name || ''
      if (!expandable_col_name) {
        return false
      }

      const exp_value = item[expandable_col_name]
      if (typeof exp_value === 'object') {
        if (Array.isArray(exp_value)) {
          return !!exp_value.length
        }
      } else {
        return +exp_value > 0
      }
    },
    expandableIndex() {
      const exp_obj = this.header.find(item => item.expandable === true)
      if (exp_obj) {
        return this.header.indexOf(exp_obj)
      }
      return null
    },
    expandRow(row_num) {
      this.expand_row_num = this.expand_row_num === row_num ? 0 : row_num
    },
    clickEvent(col, item) {
      if (col.emit) {
        this.$emit(col.emit, item)
      }
    }
  },
  computed: {
    body_style() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        if (this.scrollable) {
          if (this.full_height) {
            return `height: calc(100vh - 308px - ${this.body_height}); overflow: auto; padding-bottom: 20px;`
          } else {
            return 'height: 70vh; overflow: auto; padding-bottom: 20px;'
          }
        } else {
          return `height: calc(100vh - 308px - ${this.body_height});`
        }
      } else {
        return ''
      }
    },
  }
  // props: ['header']
}
</script>

<style scoped lang="scss">
.cs-chip {
  width: 72px !important;

  &:deep(.v-chip__content) {
    justify-content: center;
    width: 100%;
  }
}

.pay-table-row-expand-body {
  display: none;
  max-height: 0;

  .pay-table-expand-body-row {
    //border-top: thin solid $border-color;
    .pay-table-col {
      &:nth-child(1) {
        flex: 0 1 520px;
        min-width: 500px;
      }

      &:nth-child(2) {
        flex: 0 1 140px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        //background-color: $expand-background-col;
        min-width: 140px;
      }

      &:nth-child(3) {
        flex: 0 1 120px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        //background-color: $expand-background-col;
        min-width: 120px;
        text-align: right;
      }

      &:nth-child(4) {
        flex: 0 1 120px;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        //background-color: $expand-background-col;
        min-width: 120px;
        text-align: right;
      }

      &:nth-child(5) {
        flex: 1;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        border-top: var(--pt-border-width) solid var(--pt-expand-border-color);
        //background-color: $expand-background-col;
      }
    }
  }
}

.sm-flat-reading-table {
  .pay-table-header {
    display: none;
  }
  .pay-table-body {

    .pay-table-row {
      flex-wrap: wrap !important;

      &:nth-child(1) {
        .pay-table-col {
          &:nth-child(1) {
            border-top: .5px solid lightgray;
          }
          &:nth-child(2) {
            border-top: .5px solid lightgray;
          }
          &:nth-child(3) {
            border-top: .5px solid lightgray;
          }
        }
      }

      .pay-table-col {
        height: 30px;
        text-align: right;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        padding-right: 4px;

        &::before {
          display: block;
          font-size: .84rem;
          width: 38%;
          margin-right: 20px;
          padding-left: 11px;
          color: #2d2d2d;
          font-weight: 500;
          text-align: left;
          justify-content: flex-start;
        }

        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          flex: 1 1 50% !important;
          border-right: .5px solid lightgray;
          &::before {
            content: 'Місяць: ';
          }
        }
        &:nth-child(3) {
          flex: 1 1 50% !important;
          &::before {
            content: 'Дата подання: ';
            line-height: 1;
          }
        }
        &:nth-child(4) {
          flex: 1 1 50% !important;
          border-right: .5px solid lightgray;
          &::before {
            content: 'Зона: ';
          }
        }
        &:nth-child(5) {
          flex: 1 1 50% !important;
          &::before {
            content: 'Попередній:';
          }
        }
        &:nth-child(6) {
          flex: 1 1 50% !important;
          border-right: .5px solid lightgray;
          &::before {
            content: 'Поточний:';
          }
        }
        &:nth-child(7) {
          flex: 1 1 50% !important;
          &::before {
            content: 'Різниця:';
          }
        }
        &:nth-child(8) {
          flex: 0 0 50% !important;
          border-right: .5px solid lightgray;
          &::before {
            content: 'Спосіб подання:';
            line-height: 1;
          }
        }
      }
    }
  }
}
.xs-flat-reading-table {
  .pay-table-header {
    display: none;
  }
  .pay-table-body {

    .pay-table-row {
      flex-wrap: wrap !important;

      &:nth-child(1) {
        .pay-table-col {
          &:nth-child(1) {
            border-top: .5px solid lightgray;
          }
          &:nth-child(2) {
            border-top: .5px solid lightgray;
          }
        }
      }

      .pay-table-col {
        height: 30px;
        text-align: right;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        padding-right: 4px;
        flex: 1 1 100% !important;

        &::before {
          display: block;
          font-size: .84rem;
          width: 50%;
          margin-right: 20px;
          padding-left: 11px;
          color: #2d2d2d;
          font-weight: 500;
          text-align: left;
          justify-content: flex-start;
        }

        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          border-bottom: .5px solid lightgray;
          &::before {
            content: 'Місяць: ';
          }
        }
        &:nth-child(3) {
          &::before {
            content: 'Дата подання: ';
          }
        }
        &:nth-child(4) {
          &::before {
            content: 'Зона: ';
          }
        }
        &:nth-child(5) {
          &::before {
            content: 'Попередній:';
          }
        }
        &:nth-child(6) {
          &::before {
            content: 'Поточний:';
          }
        }
        &:nth-child(7) {
          &::before {
            content: 'Різниця:';
          }
        }
        &:nth-child(8) {
          &::before {
            content: 'Спосіб подання:';
            line-height: 1;
          }
        }
      }
    }
  }
}
</style>